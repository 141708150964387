
<template>
    <div class="box">
        <div class="FurnitureDesignData">
            <div class="title">
                <el-button type="primary">选择家具</el-button>
                <div class="name">
                    家具名称<el-input v-model="furnitureName" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="FurnitureDesignImg">
                <div class="name">
                    家具数量<el-input v-model="quantity" placeholder="请输入内容"></el-input>
                </div>
            </div>

            <div class="unitPrice">
                家具规格<el-input v-model="specification" placeholder="请输入内容"></el-input>
            </div>
            <div class="introduce">

                <div class="introduceTitle">
                    家具描述：
                    <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="descriptionFurniture">
                    </el-input>
                </div>
            </div>
            <div class="verify">
                <el-button type="primary">确认</el-button>
            </div>

        </div>
        <div class="tree">

        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            furnitureName:"",
            quantity:"",
            specification:"",
            describe:"",
            descriptionFurniture:""

        }
    }
}
</script>

<style lang="less" scoped>
.FurnitureDesignData{
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    flex-direction:column;


}
</style>