<template>
  <!-- 关于我们 -->
  <div class="box">
    <!-- 上部轮播部分 -->
    <div class="slideshow">
      <el-carousel height="39.0625vw">
        <el-carousel-item v-for="item in bannerDate" :key="item.id">

          <div>


            <a href="#" >

              <!-- <h3> -->
              <img class="BanerImg" :src="item.image" alt />
              <!-- </h3> -->
            </a>
          </div>

        </el-carousel-item>

        <!-- <img
          class="slideshowBackground"
          src="../../assets/OfficialWebsite/slideshowBackground.png"
          alt
        /> -->
      </el-carousel>
    </div>
    <div class="companyIntroduction">

      <div class="introduce_title">
        <div class="introduce_title_text">公司介绍</div>
        <div class="introduce_title_box">COMPANY INTRODUCTION</div>
        <!-- Company introduction -->
      </div>

      <div class="expandOn">
        <!-- <div class="photograph"> -->
        <!-- <img src="../../assets/OfficialWebsite/company.png" alt=""> -->
        <img :src="company" alt="">



        <!-- </div> -->
        <div class="particular">
          北京艺搭科技有限责任公司坐落于北京市丰台区，是一家高科技软件研发和服务的互联网企业，公司立自研了【艺搭三维创作平台】和【眼艺】元宇宙App，艺搭三维创作平台能够建立将物理世界和数字世界相互连接的虚拟环境，其中人们可以通过沉浸式的体验进行交互、交易、创作和学习。艺搭三维创作平台的目标客户包括消费者和企业。消费者可以在元宇宙中享受娱乐、社交、购物、教育等多种服务，而企业可以利用数字孪生来优化决策、提高效率、创造新的价值。
        </div>

        <!-- <div class="len"></div> -->
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="product">

      <div class="introduce_title">
        <div class="introduce_title_text">产品介绍</div>
        <div class="introduce_title_box">PRODUCT INTRODUCTION</div>
        <!-- Product introduction -->
      </div>
      <div class="productContent">

        “艺搭”是一款基于WebGL研发的三维创作平台，艺搭平台包含三维编辑器和内容商店，用户可以利用编辑器快速搭建自己专属的三维可交互场景应用，并通过艺搭内容商店发布自己应用或三维数字资源，链接更多需求方，实现数字资产的流转变现。

      

      </div>
      <div class="productContent">



    眼艺App：利用艺搭三维创作平台开发的元宇宙概念应用，在这里用户可以畅游3D虚拟世界，并与其他用户进行无缝社交，通过子编辑器，可以创造道具、载具、服装，进行土地商铺的买卖，体验第二人生的全新娱乐

</div>

    </div>

  </div>
</template>

<script>
import company from "../../assets/OfficialWebsite/company.png"
export default {
  data() {
    return {
      company: company,
      bannerDate: [],

    }
  },
  mounted() {
    this.getbannerDate(4)
  },

  methods: {
    // 开始使用
    onBegin() {






      // this.showVideo=true;
      let token = localStorage.getItem("token")

      if (token) {
        this.$router.push({ path: '/index/user/User' })

      } else {
        //  this.$router.push({path:'/login'})

        this.$parent.changelogInShow()

      }


    },



    async clickBanner(id) {


      this.onBegin()


      let data = {
        id: id
      }
      const res = await this.$https.clickBanner(data)
      if (res.success == true) {

      }
    },



    //获取轮播数据
    async getbannerDate(plateId) {
      let data = {
        plateId: plateId
      }
      const res = await this.$https.bannerDate(data)
      if (res.success == true) {
        this.bannerDate = res.data
      }
    },
  }

}
</script>

<style scoped>
.box {
  width: 100%;
  height: 2100px;
  background-color: #fff;
  position: relative;
}

.slideshow {
  margin-top: 60px;
}

/* 上部轮播 */
.slideshow .slideshowBackground {
  display: inline-block;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  z-index: 9;
  /* background: #fff; */
}

.slideshow>>>.el-carousel__indicators--horizontal {
  bottom: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 999;

}

.slideshow a img {
  /* width: 100%; */
  height: auto;
  /* object-fit: cover; */
}

.slideshow>>>.el-carousel__indicator--horizontal {
  padding-left: 15px;
  padding-right: 15px;
}

.slideshow>>>.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border: 0px solid #ffffff !important;
  border-radius: 50% !important;
  /* opacity: 0.5; */
  background-color: rgba(214, 214, 214, 1) !important;
}

.slideshow>>>.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: RGBA(17, 104, 253, 1) !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

/*   
  .quexing{
    width: 400px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  }
  .quexing
  img
  {
    width: 500px; 

  }

  .text{
    width: 450px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 88px;
    text-align: center;
  } */

.companyIntroduction {
  width: 80%;
  height: 600px;
  /* background: cornflowerblue; */
  margin: 0 auto;



}

.companyIntroduction .introduce_title {
  width: 320px;
  /* height: 100px; */
  margin: 0 auto;
}

.companyIntroduction .introduce_title_text {
  width: 320px;
  /* height: 40px; */
  text-align: center;
  color: #333333;
  font-size: 36px;
  font-family: MicrosoftYaHei;
  font-weight: 600;
  color: #333333;
  line-height: 36px;
}

.companyIntroduction .introduce_title_box {
  width: 317px;
  /* height: 19px; */
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b0cbe0;
  line-height: 30px;
}

.companyIntroduction .expandOn {
  width: 100%;
  height: 400px;
  /* background-color: blueviolet; */
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items :center; */
  /* margin-top: 38px; */
  position: relative;
  padding-top: 50px;

}

.companyIntroduction .expandOn .photograph {
  width: 35%;

  height: 100%;
  /* background: bisque; */
  line-height: 100%;


}

.expandOn .len {
  width: 120px;
  height: 1px;
  background-color: rgb(21, 104, 251);
  position: absolute;
  bottom: 170px;
  right: 0px;
  border: 1px solid rgb(21, 104, 251);
}

.companyIntroduction .expandOn

/* .photograph */
img {
  width: 40%;
  height: 65%;
  border-radius: 8px;
}




.companyIntroduction .expandOn .particular {
  width: 57%;

  /* height: 70%; */
  /* background:  bisque; */
  font-size: 18px;
  font-family: Microsoft YaHei;

  font-weight: 400;
  color: #333333;
  line-height: 40px;
  letter-spacing: 2px;
  margin-top: 20px
}


.product {
  margin-top: 30px;
  width: 100%;
  height: 600px;
  /* background: cornflowerblue; */
  margin: 0 auto;
  background: url('../../assets/OfficialWebsite/product.png');
  background-repeat: no-repeat;
  /* border-radius: 8px; */
  margin-bottom: 100px;
  padding-top: 50px;
  background-size: 100%;
}

.product .introduce_title {

  width: 320px;
  /* height: 100px; */
  margin: 0 auto;

}


.product .introduce_title_text {
  width: 320px;
  /* height: 40px; */
  text-align: center;
  color: #fff;
  font-size: 36px;
  font-family: MicrosoftYaHei;
  font-weight: 600;

  line-height: 40px;
}

.product .introduce_title_box {
  width: 317px;
  /* height: 19px; */
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b0cbe0;
  line-height: 50px;
}

.product .productContent {

  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 40px;
  letter-spacing: 2px
}

.BanerImg {
  width: 100%;

}

.slideshow /deep/ .el-carousel__arrow--left {
  display: none;
}

.slideshow /deep/ .el-carousel__arrow--right {
  display: none;
}


.slideshow /deep/ .el-carousel__button {
  display: none;
}
</style>