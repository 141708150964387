<template>
  <div class="inventory">
    <!-- 物品分类 -->


    <div class="reightContent">


      <div class="content" >
        <!-- @contextmenu.prevent.stop="rightClick(item, 1) -->
        <div :class="{ 'inventoryItem': true, }" v-for="item in fileList" :key="item.id" @click="selectItem(item)"
          @contextmenu.prevent.stop="rightClick2(item, 1)">

          <div :class="{ 'itemContent': true, 'inventoryItemSelect': selectItemData.id == item.id }">

            <div class="itemTop">
              <div class="show">
                <img :src="item.fileImage" alt="">
              </div>
              <div class="name">
                {{ item.fileName }}
              </div>
            </div>
            <!-- <div class="itemBottom">
            应用
          </div> -->
          </div>

        </div>
      </div>
      <div class="contentTop" :class="{ sticky: isSticky }">
        <div class="topBtn" v-show="fileList.length>0">
          应 用
        </div>
      </div>


    </div>

    <div class="leftTree">

      <div class="treeTitle">
        <div class="titleIcon">
          <img src="../../../assets/PlugIn/cad/folder.png" alt="">
        </div>
        <div class="titleText">
          文件夹
        </div>
      </div>
      <el-tree :data="treeData" :props="defaultProps" @node-contextmenu="rightClick" @current-change="setNowData"
        highlight-current>
      </el-tree>

      <div class="addFolder" @click="addCADFolder('')">
        +
      </div>
    </div>
    <div class="myMenu">
      <div v-show="menu">
        <el-menu id="menu" @select="handleSelect" text-color="#000" class="menu" ref="menu">
          <el-menu-item v-if="rigthClickType == 0" index="1" class="menuItem">
            <span slot="title">
              <!-- <img src="../../assets/Resource/created_folder.png" alt class="menuIcon" /> -->
              新建文件夹
            </span>
          </el-menu-item>

          <el-menu-item v-if="rigthClickType == 0" index="2" class="menuItem">
            <!-- <img src="../../assets/Resource/newName.png" alt class="menuIcon" /> -->
            <span slot="title">新建文件</span>
          </el-menu-item>


          <el-menu-item v-if="rigthClickType == 0" index="3" class="menuItem">
            <!-- <img src="../../assets/Resource/newName.png" alt class="menuIcon" /> -->
            <span slot="title">重命名</span>
          </el-menu-item>


          <el-menu-item index="4" class="menuItem">
            <!-- <img src="../../assets/Resource/icon-67.png" alt class="menuIcon" /> -->
            <span slot="title">删除</span>
          </el-menu-item>

          <el-menu-item v-if="rigthClickType == 1" index="5" class="menuItem">
            <!-- <img src="../../assets/Resource/icon-67.png" alt class="menuIcon" /> -->
            <span slot="title">编辑</span>
          </el-menu-item>


        </el-menu>
      </div>
    </div>
    <el-dialog title="请输入物品参数" top="5vh" @close="cancel()" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules">
        <el-form-item label="物品名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="图层" :label-width="formLabelWidth">
          <el-input v-model="form.layer"></el-input>
        </el-form-item>
        <el-form-item label="json" :label-width="formLabelWidth">
          <el-input v-model="form.json"></el-input>
        </el-form-item>

        <el-form-item label="模型url" :label-width="formLabelWidth">
          <el-input v-model="form.modelUrl"></el-input>
        </el-form-item>

        <el-form-item label="文件显示图片" :label-width="formLabelWidth" prop="img">
          <input type="file" value="选择图片" ref="showImg" multiple @change="showImgChange($event)" accept="image/*"
            style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
          <div class="showImg">
            <div class="imgClose" v-if="up.imgShow" @click="deleteImg(showImg)">
              <i class="el-icon-close"></i>
            </div>
            <div class="replace " v-if="showImg.imgShow" @click="showImgContentClick()">
              替换
            </div>

            <img v-if="showImg.imgShow" class="showImgImg" :src="showImg.url" alt="">
            <div v-else class="showImgContent" @click="showImgContentClick()">
              +
            </div>
          </div>

        </el-form-item>

        <el-form-item label="现在设置方位图片:" :label-width="formLabelWidth">
          <el-switch v-model="form.delivery"></el-switch>
        </el-form-item>

        <div class="setImg" v-show="form.delivery">
          <div class="setImgItem">
            <div class="setImgTitle">
              <span class="essential">*</span> 下
            </div>
            <div class="setImgContent">
              <div class="imgClose" v-if="down.imgShow" @click="deleteImg(down)">
                <i class="el-icon-close"></i>
              </div>
              <div class="replace " v-if="down.imgShow" @click="downClick()">
                替换
              </div>
              <input type="file" value="选择图片" ref="down" multiple @change="downChange($event)" accept="image/*"
                style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
              <img v-if="down.imgShow" class="setImgImg" :src="down.url" alt="">

              <div v-else class="orientation" @click="downClick()">

                +


              </div>


            </div>
          </div>
          <div class="setImgItem">
            <div class="setImgTitle">
              上
            </div>
            <div class="setImgContent">

              <div class="imgClose" v-if="up.imgShow" @click="deleteImg(up)">
                <i class="el-icon-close"></i>
              </div>

              <div class="replace" v-if="up.imgShow" @click="upClick()">
                替换
              </div>
              <input type="file" value="选择图片" ref="up" multiple @change="upChange($event)" accept="image/*"
                style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
              <img v-if="up.imgShow" class="setImgImg" :src="up.url" alt="">

              <div v-else class="orientation" @click="upClick()">

                +


              </div>


            </div>
          </div>
          <div class="setImgItem">
            <div class="setImgTitle">
              左
            </div>
            <div class="setImgContent">
              <div class="imgClose" v-if="left.imgShow" @click="deleteImg(left)">
                <i class="el-icon-close"></i>
              </div>
              <div class="replace" v-if="left.imgShow" @click="leftClick()">
                替换
              </div>
              <input type="file" value="选择图片" ref="left" multiple @change="leftChange($event)" accept="image/*"
                style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
              <img v-if="left.imgShow" class="setImgImg" :src="left.url" alt="">

              <div v-else class="orientation" @click="leftClick()">

                +


              </div>


            </div>
          </div>
          <div class="setImgItem">
            <div class="setImgTitle">
              右
            </div>
            <div class="setImgContent">
              <div class="imgClose" v-if="right.imgShow" @click="deleteImg(right)">
                <i class="el-icon-close"></i>
              </div>

              <div class="replace" v-if="right.imgShow" @click="rightImgClick()">
                替换
              </div>
              <input type="file" value="选择图片" ref="right" multiple @change="rightChange($event)" accept="image/*"
                style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
              <img v-if="right.imgShow" class="setImgImg" :src="right.url" alt="">

              <div v-else class="orientation" @click="rightImgClick()">

                +


              </div>


            </div>
          </div>
          <div class="setImgItem">
            <div class="setImgTitle">
              前
            </div>
            <div class="setImgContent">
              <div class="imgClose" v-if="front.imgShow" @click="deleteImg(front)">
                <i class="el-icon-close"></i>
              </div>
              <div v-if="front.imgShow" class="replace" @click="frontClick()">
                替换
              </div>
              <input type="file" value="选择图片" ref="front" multiple @change="frontChange($event)" accept="image/*"
                style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
              <img v-if="front.imgShow" class="setImgImg" :src="front.url" alt="">

              <div v-else class="orientation" @click="frontClick()">

                +


              </div>


            </div>
          </div>
          <div class="setImgItem">
            <div class="setImgTitle">
              后
            </div>
            <div class="setImgContent">

              <div class="imgClose" v-if="back.imgShow" @click="deleteImg(back)">
                <i class="el-icon-close"></i>
              </div>

              <div v-if="back.imgShow" class="replace" @click="backClick()">
                替换
              </div>
              <input type="file" value="选择图片" ref="back" multiple @change="backChange($event)" accept="image/*"
                style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
              <img v-if="back.imgShow" class="setImgImg" :src="back.url" alt="">

              <div v-else class="orientation" @click="backClick()">

                +


              </div>


            </div>
          </div>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="setGoods()">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { MainObjects } from "../../../threejs/common/MainObjects";
import { OssManager } from "../../../threejs/network/OssManager";


export default {

  data() {
    return {
      treeData: [],
      defaultProps: {
        children: "subCatalog",
        label: "name",
        // imageUrl: "imageUrl",
        // local: "local"
      },
      menu: false,
      dialogFormVisible: false,
      form: {
        name: "",
        layer: "",
        json: "",
        delivery: false,
        modelUrl: ""

      },
      formLabelWidth: '150px',
      rightNode: {},
      showImg: {
        url: "",
        imgShow: false
      },
      down: {
        url: "",
        imgShow: false
      },
      up: {
        url: "",
        imgShow: false
      },

      left: {
        url: "",
        imgShow: false
      },
      right: {
        url: "",
        imgShow: false
      },
      front: {
        url: "",
        imgShow: false
      },
      back: {
        url: "",
        imgShow: false
      },

      nowFolderData: {},
      fileList: [],

      fileId: "",
      rigthClickType: 0,
      fileData: {},
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        img: [
          { required: true, message: '请上传图片', trigger: 'change' },
        ]


      },
      timeout: null,
      selectItemData: {},
      isSticky: false,
    }


  },

  props: ["projectId"],
  mounted() {
    window.addEventListener("click", e => {
      if (this.menu == true) {
        this.menu = false
      }
    });
    this.getTreeData(this.projectId)


    const container = document.querySelector('.reightContent');
    container.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    const container = document.querySelector('.container');
    container.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const container = document.querySelector('.container');
      const header = document.querySelector('.header');
      const headerRect = header.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      this.isSticky = headerRect.top <= containerRect.top;
    },

    selectItem(item) {
      this.selectItemData = item
    },

    deleteImg(item) {
      item.url = "",
        item.imgShow = false
    },
    // 文件夹重命名
    updateFolderName(id) {
      var containSpecial = RegExp(
        /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
      );

      this.$prompt(``, `请输入新的名字`, {
        confirmButtonText: "确定",
        showClose: true,
        // center: true,
        closeOnPressEscape: false,
        closeOnClickModal: false,

        inputPlaceholder: `在此输入名字`,
        inputValue: this.rightNode.name,
        inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
        inputValidator: value => {
          // 点击按钮时，对文本框里面的值进行验证
          if (!value) {
            return "输入不能为空";
          } else if (containSpecial.test(value)) {
            return "输入不能为特殊字符";
          } else if (value.length > 20) {
            return "输入文字不能超过20个字符";
          }
        }
      })
        .then(({ value }) => {
          this.updateFolderNameSql(id, value)
        })
        .catch(() => {

          this.$message({
            // type: "info",
            message: "已取消"
          });
        });
    },





    // 新建文件夹
    addCADFolder(parent) {
      var containSpecial = RegExp(
        /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
      );

      this.$prompt(``, `请输入文件夹名字`, {
        confirmButtonText: "确定",
        showClose: true,
        // center: true,
        closeOnPressEscape: false,
        closeOnClickModal: false,

        inputPlaceholder: `在此输入名字`,
        inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
        inputValidator: value => {
          // 点击按钮时，对文本框里面的值进行验证
          if (!value) {
            return "输入不能为空";
          } else if (containSpecial.test(value)) {
            return "输入不能为特殊字符";
          } else if (value.length > 20) {
            return "输入文字不能超过20个字符";
          }
        }
      })
        .then(({ value }) => {

          this.addCADFolderSql(value, parent)
          this.rightNode = {}
        })
        .catch(() => {


          this.$message({
            // type: "info",
            message: "已取消"
          });
        });
    },



    //创建文件夹
    async addCADFolderSql(name, parent) {
      let data = {
        name: name,
        parent: parent,
        projectId: this.projectId,
        type: 0
      }
      const res = await this.$https.addCADFolder(data)

      if (res.success == true) {
        this.getTreeData(this.projectId)
      } else {
        this.$message(res.errMessage)
      }
    },

    //获取文件夹树数据
    async getTreeData(projectId) {
      let data = {
        projectId: projectId,
        type: 0
      }

      const res = await this.$https.folderTreeCDA(data)

      if (res.success == true) {
        this.treeData = res.data
      } else {
        this.$message(res.errMessage)
      }


      // 
    },
    //通过id查找节点
    inquireNode(tree, id) {
      for (let index = 0; index < tree.length; index++) {
        if (tree[index].id == id) return tree[index];
        if (tree[index].subCatalog) {
          const res = this.inquireNode(tree[index].subCatalog, id);
          if (res) return res;
        }
      }

      return null;
    },




    // 删除文件文件夹
    async deleteFolderFileSql(id, type) {
      let data = {
        id: id,
        type: type
      }

      const res = await this.$https.CaDdeleteFolderFile(data)


      if (res.success == true) {
        if (this.rigthClickType == 0) {


          var parentNode = this.inquireNode(this.treeData, id);

          this.setNowData(parentNode, parentNode)


          this.getTreeData(this.projectId)

        } else if (this.rigthClickType == 1) {
          this.getSubdirectorySql()
        }


        this.$message("删除成功")
      } else {
        this.$message(res.errMessage)
      }
    },

    // 文件夹重命名
    async updateFolderNameSql(id, name) {
      let data = {
        id: id,
        name: name
      }

      const res = await this.$https.updateFolderName(data)

      if (res.success == true) {

        this.getTreeData(this.projectId)
        this.$message("重命名成功")
      } else {
        this.$message(res.errMessage)
      }
    },



    handleNodeClick(data) {
      console.log(data);
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: 'region1' }, { name: 'region2' }]);
      }
      if (node.level > 3) return resolve([]);

      var hasChild;
      if (node.data.name === 'region1') {
        hasChild = true;
      } else if (node.data.name === 'region2') {
        hasChild = false;
      } else {
        hasChild = Math.random() > 0.5;
      }

      setTimeout(() => {
        var data;
        if (hasChild) {
          data = [{
            name: 'zone' + this.count++
          }, {
            name: 'zone' + this.count++
          }];
        } else {
          data = [];
        }

        resolve(data);
      }, 500);
    },
    async handleSelect(key) {
      if (key == 1) {
        this.addCADFolder(this.rightNode.id)
      } else if (key == 2) {
        this.dialogFormVisible = true
      } else if (key == 3) {
        this.updateFolderName(this.rightNode.id)
      } else if (key == 4) {
        console.log(this.rigthClickType, "rigthClickType");
        if (this.rigthClickType == 0) {
          this.deleteFolderFileSql(this.rightNode.id, 0)

        } else if (this.rigthClickType == 1) {
          console.log(this.fileData, "this.fileData.id");
          this.deleteFolderFileSql(this.fileData.id, 1)
        }

      } else if (key == 5) {
        this.edit()

      }


    },

    edit() {
      this.dialogFormVisible = true
      this.form.name = this.fileData.fileName
      this.form.layer = this.fileData.fileLayer
      this.form.delivery = this.fileData.fileType == 1 ? true : false,
        this.form.json = this.fileData.json
      this.form.modelUrl = this.fileData.modelUrl


      this.showImg.url = this.fileData.fileImage

      this.showImg.imgShow = this.isShow(this.fileData.fileImage)

      this.down.url = this.fileData.fileBelow
      this.down.imgShow = this.isShow(this.fileData.fileBelow)
      this.up.url = this.fileData.fileUp
      this.up.imgShow = this.isShow(this.fileData.fileUp)

      this.left.url = this.fileData.fileLeft
      this.left.imgShow = this.isShow(this.fileData.fileLeft)

      this.right.url = this.fileData.fileRight
      this.right.imgShow = this.isShow(this.fileData.fileRight)

      this.front.url = this.fileData.fileFront
      this.front.imgShow = this.isShow(this.fileData.fileFront)

      this.back.url = this.fileData.fileLater
      this.back.imgShow = this.isShow(this.fileData.fileLater)

      this.fileId = this.fileData.id

    },

    isShow(url) {

      if (url == "") {
        return false
      } else {
        return true
      }
    },

    cancel() {
      this.dialogFormVisible = false;

      this.fileId = ""

      this.showImg = {
        url: "",
        imgShow: false
      }
      this.down = {
        url: "",
        imgShow: false
      }
      this.up = {
        url: "",
        imgShow: false
      }

      this.left = {
        url: "",
        imgShow: false
      }
      this.right = {
        url: "",
        imgShow: false
      }
      this.front = {
        url: "",
        imgShow: false
      }
      this.back = {
        url: "",
        imgShow: false
      }
      this.form = {
        name: "",
        layer: "",
        json: "",
        delivery: false,
        modelUrl: ""

      }



    },


    rightClick2(item, type) {
      console.log(item, "item");
      this.fileData = item
      this.rigthClickType = 1
      if (item == {}) {
        //获取到菜单
        let menu = this.$refs.menu;
        let x = event.clientX;
        let y = event.clientY;
        let h = menu.style.height;

        if (y > 740) {
          if (this.recycle) {
            menu.$el.style.top = y - 50 + "px";
          } else {
            menu.$el.style.top = y - 150 + "px";
          }
        } else {
          menu.$el.style.top = y + "px";
        }

        menu.$el.style.left = x + "px";



        this.menu = true; //让菜单显示
      } else {


        //获取到菜单
        let menu = this.$refs.menu;

        let x = event.clientX;
        let y = event.clientY;


        if (y > 740) {
          if (this.recycle) {
            menu.$el.style.top = y - 50 + "px";
          } else {
            menu.$el.style.top = y - 150 + "px";
          }
        } else {
          menu.$el.style.top = y + "px";
        }

        // if (x > 1280) {
        //   menu.$el.style.left = x - 100 + "px";
        // } else {
        menu.$el.style.left = x + "px";
        // }
        this.menu = true; //让菜单显示

      }
    },


    rightClick(object, Node) {
      console.log(object, Node, "object, Node");
      this.rightNode = Node
      this.rigthClickType = 0

      if (object == {}) {
        //获取到菜单
        let menu = this.$refs.menu;
        let x = event.clientX;
        let y = event.clientY;
        let h = menu.style.height;

        if (y > 740) {
          if (this.recycle) {
            menu.$el.style.top = y - 50 + "px";
          } else {
            menu.$el.style.top = y - 150 + "px";
          }
        } else {
          menu.$el.style.top = y + "px";
        }

        menu.$el.style.left = x + "px";



        this.menu = true; //让菜单显示
      } else {


        //获取到菜单
        let menu = this.$refs.menu;

        let x = event.clientX;
        let y = event.clientY;


        if (y > 740) {
          if (this.recycle) {
            menu.$el.style.top = y - 50 + "px";
          } else {
            menu.$el.style.top = y - 150 + "px";
          }
        } else {
          menu.$el.style.top = y + "px";
        }

        // if (x > 1280) {
        //   menu.$el.style.left = x - 100 + "px";
        // } else {
        menu.$el.style.left = x + "px";
        // }
        this.menu = true; //让菜单显示

      }
      // }


    },
    setNowData(object, node) {
      // console.log(object, node);
      this.nowFolderData = object
      this.getSubdirectorySql()

    },

    async getSubdirectorySql() {
      let data = {
        projectId: this.projectId,
        folderId: this.nowFolderData.id
      }

      const res = await this.$https.getSubdirectory(data)

      if (res.success == true) {
        this.fileList = res.data

      } else {
        this.$message(res.errMessage)
      }

    },

    setGoods() {
      if (this.form.delivery == true && this.down.url == "") {
        this.$message("请至少上传‘下’的位序图")
      } else {
        if (this.form.name == "") {
          this.$message("名字不能为空")
        } else {
          if (this.showImg.imgShow == false) {
            this.$message("回显图不能为空")
          } else {
            if (this.timeout) {
              clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => {
              this.addCADFileSql(this.fileId)

            }, 500)



          }
        }
      }
    },
    async addCADFileSql(id) {
      let data = {
        id: id,
        folderId: this.rightNode.id,
        projectId: this.projectId,
        fileName: this.form.name,
        fileType: this.form.delivery ? 1 : 0,
        fileLayer: this.form.layer,
        json: this.form.json,
        modelUrl: this.form.modelUrl,
        fileImage: this.showImg.url,
        fileBelow: this.down.url,
        fileUp: this.up.url,
        fileLeft: this.left.url,
        fileRight: this.right.url,
        fileFront: this.front.url,
        fileLater: this.back.url,
      }

      const res = await this.$https.addCADFile(data)

      if (res.success == true) {

        if (this.rigthClickType == 0) {
          this.setNowData(this.rightNode, this.rightNode)

        } else {
          this.getSubdirectorySql()

        }


        this.dialogFormVisible = false;

        this.showImg = {
          url: "",
          imgShow: false
        }
        this.down = {
          url: "",
          imgShow: false
        }
        this.up = {
          url: "",
          imgShow: false
        }

        this.left = {
          url: "",
          imgShow: false
        }
        this.right = {
          url: "",
          imgShow: false
        }
        this.front = {
          url: "",
          imgShow: false
        }
        this.back = {
          url: "",
          imgShow: false
        }
        this.form = {
          name: "",
          layer: "",
          json: "",
          delivery: false,

        }
      } else {
        this.$message(res.errMessage)
      }

    },





    showImgContentClick() {
      this.$refs.showImg.value=""

      this.$refs.showImg.click()
    },
    showImgChange(e) {
      const files = e.target.files[0];

      var _this = this;
      let https = MainObjects.Network.m_http;
      let data = new OssManager(MainObjects.Network.m_bucketName);
      let time = Date.now()
      let url = data.getCADFloder("materialsShowImg" + time, _this.projectId);
      function uploadFinish(list) {
        // console.log(list,"list");
        let DNS = "https://file.yicreator.com/"
        _this.showImg.url = DNS + list[0].name
        _this.showImg.imgShow = true
      }
      data.f_finishCallback = uploadFinish;
      console.log(url, "url");
      data.upload(files, url);

    },


    downClick() {
      this.$refs.down.value=""

      this.$refs.down.click()
    },
    downChange(e) {
      const files = e.target.files[0];

      var _this = this;
      let https = MainObjects.Network.m_http;
      let data = new OssManager(MainObjects.Network.m_bucketName);
      let time = Date.now()
      let url = data.getCADFloder("down" + time, _this.projectId);
      function uploadFinish(list) {
        // console.log(list,"list");
        let DNS = "https://file.yicreator.com/"
        _this.down.url = DNS + list[0].name
        _this.down.imgShow = true
      }
      data.f_finishCallback = uploadFinish;
      console.log(url, "url");
      data.upload(files, url);

    },

    upClick() {
      this.$refs.up.value=""

      this.$refs.up.click()
    },
    upChange(e) {
      const files = e.target.files[0];

      var _this = this;
      let https = MainObjects.Network.m_http;
      let data = new OssManager(MainObjects.Network.m_bucketName);
      let time = Date.now()
      let url = data.getCADFloder("up" + time, _this.projectId);
      function uploadFinish(list) {
        // console.log(list,"list");
        let DNS = "https://file.yicreator.com/"
        _this.up.url = DNS + list[0].name
        _this.up.imgShow = true
      }
      data.f_finishCallback = uploadFinish;
      console.log(url, "url");
      data.upload(files, url);

    },
    leftClick() {
      this.$refs.left.value=""

      this.$refs.left.click()
    },
    leftChange(e) {
      const files = e.target.files[0];

      var _this = this;
      let https = MainObjects.Network.m_http;
      let data = new OssManager(MainObjects.Network.m_bucketName);
      let time = Date.now()
      let url = data.getCADFloder("left" + time, _this.projectId);
      function uploadFinish(list) {
        // console.log(list,"list");
        let DNS = "https://file.yicreator.com/"
        _this.left.url = DNS + list[0].name
        _this.left.imgShow = true
      }
      data.f_finishCallback = uploadFinish;
      console.log(url, "url");
      data.upload(files, url);

    },
    rightImgClick() {
      this.$refs.right.value=""

      this.$refs.right.click()
    },
    rightChange(e) {
      const files = e.target.files[0];

      var _this = this;
      let https = MainObjects.Network.m_http;
      let data = new OssManager(MainObjects.Network.m_bucketName);
      let time = Date.now()
      let url = data.getCADFloder("right" + time, _this.projectId);
      function uploadFinish(list) {
        // console.log(list,"list");
        let DNS = "https://file.yicreator.com/"
        _this.right.url = DNS + list[0].name
        _this.right.imgShow = true
      }
      data.f_finishCallback = uploadFinish;
      console.log(url, "url");
      data.upload(files, url);

    },
    frontClick() {
      this.$refs.front.value=""

      this.$refs.front.click()
    },
    frontChange(e) {
      const files = e.target.files[0];

      var _this = this;
      let https = MainObjects.Network.m_http;
      let data = new OssManager(MainObjects.Network.m_bucketName);
      let time = Date.now()
      let url = data.getCADFloder("materialsShowImg" + time, _this.projectId);
      function uploadFinish(list) {
        // console.log(list,"list");
        let DNS = "https://file.yicreator.com/"
        _this.front.url = DNS + list[0].name
        _this.front.imgShow = true
      }
      data.f_finishCallback = uploadFinish;
      console.log(url, "url");
      data.upload(files, url);

    },
    backClick() {
      this.$refs.back.value=""

      this.$refs.back.click()
    },
    backChange(e) {
      const files = e.target.files[0];

      var _this = this;
      let https = MainObjects.Network.m_http;
      let data = new OssManager(MainObjects.Network.m_bucketName);
      let time = Date.now()
      let url = data.getCADFloder("materialsShowImg" + time, _this.projectId);
      function uploadFinish(list) {
        // console.log(list,"list");
        let DNS = "https://file.yicreator.com/"
        _this.back.url = DNS + list[0].name
        _this.back.imgShow = true
      }
      data.f_finishCallback = uploadFinish;
      console.log(url, "url");
      data.upload(files, url);

    },
  }


}
</script>
<style lang="less" scoped>
.inventory {

  display: flex;

  /* 滚动条样式 */
  .leftTree::-webkit-scrollbar {
    margin-top: 0px;

    width: 00px;

    height: 0px;
  }

  .leftTree::-webkit-scrollbar-thumb {

    border-radius: 00px;
    box-shadow: inset 0 0 5px #69a0fe;
    background: #69a0fe;
  }

  .leftTree::-webkit-scrollbar-track {

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #e9f1fe;
  }

  .leftTree {
    width: 15vw;
    height: 100vh;
    background-color: #fff;
    overflow: auto;
    padding: 10px;

    .treeTitle {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: #F7F7F7 solid 1px;

      .titleIcon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 7px;

        img {
          width: 100%;
        }
      }

      .titleText {
        line-height: 40px;
        font-size: 14px;
        // margin-left: 10px;
      }
    }

    .addFolder {
      width: 50px;
      height: 50px;
      background-color: #fff;
      margin: 0 auto;
      margin-top: 30px;
      cursor: pointer;
      border-radius: 50%;
      font-size: 50px;
      line-height: 50px;
      text-align: center;
      color: #000;
      box-shadow: 0 0 2px 0 rgba(33, 35, 49, 0.1);
    }

  }



  /* 滚动条样式 */
  .reightContent::-webkit-scrollbar {
    margin-top: 5px;

    width: 10px;

    height: 1px;
  }

  .reightContent::-webkit-scrollbar-thumb {

    border-radius: 10px;
    box-shadow: inset 0 0 5px #69a0fe;
    background: #69a0fe;
  }

  .reightContent::-webkit-scrollbar-track {

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #e9f1fe;
  }

  .reightContent {
    width: 75vw;
    height: 93vh;
    // background-color: #EFF3F4;
    overflow-y: auto;
    position: relative;
    // border: 1px solid #CFD2D3;
    background-color: #fff;
    margin: 10px;
    border-radius: 8px;


    .contentTop {
      position: sticky;
      width: 100%;
      height: 6vh;
      background-color: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      bottom: 0px;

      .topBtn {
        width: 120px;
        height: 30px;
        background: #0076F6;
        border-radius: 6px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        font-weight: 800;
        // float: right;
        margin: 0 auto;
        cursor: pointer;
        font-size: 14px;
      }
    }

    .content::after {
      display: block;
      clear: both;
      content: "";
    }


    .content {
      width: 95%;
      margin: 0 auto;
      margin-top: 2vh;




      .inventoryItem {
        box-sizing: border-box;
        width: 16.6%;
        height: 200px;
        padding: 10px; // background-color: #fff;
        // margin: 10px;
        float: left;
        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        border-radius: 6px;

        .inventoryItemSelect {
          border: 1px solid #0560FD;
          border-radius: 6px;
        }

        .itemContent {
          border-radius: 6px;
          height: 100%;
          overflow: hidden;
          box-sizing: border-box;
          padding: 2px;

          .itemTop {
            width: 100%;
            height: 95%;

            .show {
              width: 100%;
              height: 80%;
              text-align: center;
              border-radius: 6px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;

              }
            }

            .name {
              width: 100%;
              height: 30px;
              line-height: 15px;
              // text-align: center;
              font-size: 14px;
              line-height: 30px;
              color: #2A2A2A;
              font-weight: 400;

              overflow: hidden;
              -webkit-line-clamp: 1;
              text-overflow: ellipsis;

            }
          }

          .itemBottom {
            width: 100%;
            height: 20%;
            font-size: 18px;
            line-height: 50px;
            text-align: center;
            border: rgba(0, 0, 0, 0.2) solid 1px;
            //  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);

            cursor: pointer;
          }
        }

      }

    }
  }

  .showImg {
    width: 150px;
    height: 150px;
    position: relative;
    background-color: #e9f1fe;

    .showImgImg {
      width: 100%;
      height: 100%;

    }

    .showImgContent {
      width: 40px;
      height: 40px;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      line-height: 40px;
      font-size: 28px;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
    }

    .replace {
      width: 100%;
      text-align: center;
      line-height: 30px;
      height: 30px;
      background-color: rgba(5, 96, 253, 0.4);
      color: #fff;
      cursor: pointer;
      position: absolute;
      bottom: 0px;
    }

    .imgClose {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 5px;
      top: 5px;
    }

  }


  .setImg {
    width: 100%;
    height: 200px;
    // background-color: #69a0fe;
    display: flex;

    .setImgItem {
      // background-color: red;
      width: 16%;
      height: 180px;
      margin: 0 10px;


      .setImgTitle {
        height: 30px;
        width: 100%;
        font-size: 18px;
        text-align: center;
        line-height: 30px;

        .essential {
          height: 30px;
          line-height: 30px;
          color: red;
        }

      }

      .setImgContent {
        width: 100%;
        height: 150px;
        background-color: #e9f1fe;
        position: relative;

        .setImgImg {
          width: 100%;
          height: 100%;
        }

        .orientation {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          font-size: 50%;
          font-size: 40px;
          background-color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;

        }

        .replace {
          width: 100%;
          text-align: center;
          line-height: 30px;
          height: 30px;
          background-color: rgba(5, 96, 253, 0.4);
          color: #fff;
          cursor: pointer;
          position: absolute;
          bottom: 0px;
        }

        .imgClose {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 5px;
          top: 5px;
          cursor: pointer;
          font-size: 18px;
        }

      }


    }


  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999999;
  }

  .menuItem {
    /* text-align:center; */
    line-height: 60px;
  }

  .myMenu /deep/ .menu {
    border-radius: 10px;

    padding: 10px 0;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  }

  .myMenu /deep/.el-menu-item {
    height: 40px;
    line-height: 40px;
    position: relative;

    white-space: nowrap;
    list-style: none;
    text-align: left;
  }

  .myMent /deep/ .el-menu-item.is-active {
    color: #000;
  }

  /* 菜单图标 */
  .menuIcon {
    width: 19px;
    height: 17px;

    margin-right: 20px;
    margin-top: -4px;
  }


}


// .leftTree {
//   width: 100%;
//   height: 100%;
//   /* background-color: black; */
//   /* margin: 10px auto; */
//   /* overflow: hidden; */
// }

.leftTree /deep/ .el-tree-node:before {
  content: "";
  position: absolute;
  left: 30px;
  bottom: 0px;
  right: 30px;
  /* border-top: 1px solid #F7F7F7;; */
}

/* /deep/ .tree .el-tree-node:checked {
  
border: 2px solid #0560FD;
border-radius: 10px;
} */
.leftTree /deep/ .el-tree-node__content {
  height: 30px;
  margin-top: 8px;
}

.leftTree /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  /* background: rgba(75, 79, 82, 0); */
  background: #e6efff;

  /* border: 2px solid rgba(5, 96, 253, 0.7); */
  border-radius: 6px;
}

.leftTree /deep/.el-icon-caret-right:before {
  content: "";
  font-size: 25px;
  width: 10px;
  height: 10px;
  display: block;
  background: url("../../../assets/Resource/arrows.png") no-repeat center;
  background-size: 10px 10px;
}

.leftTree /deep/ .el-tree .el-tree-node__expand-icon.expanded .el-icon-caret-right:before {
  content: "";
  font-size: 25px;
  width: 10px;
  height: 10px;
  display: block;
  background: url("../../../assets/Resource/arrows.png") no-repeat center;
  background-size: 10px 10px;
}

.el-tree /deep/.el-tree-node__expand-icon.is-leaf::before {
  background: #ffffff;
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  font-size: 16px;
  background-size: 16px;
}

.custom-tree-node {
  width: 100%;
  height: 70px;
}

.custom-tree-node-content {
  width: 80%;
  float: left;
  height: 50px;
}

.operation {
  visibility: hidden;
  margin-right: 30%;
  float: right;
}

.custom-tree-node:hover>.operation {
  visibility: visible;
}


.sticky {
  position: absolute;
}
</style>