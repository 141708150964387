<template>
  <div class="box">
    <div class="quexing">
        <img  src="../../assets/OfficialWebsite/quexing.png" alt="">
        <div class="text">
          此版块正在开发ing
        </div>

    </div>
    <!-- <div class="left">
      <div class="myTree">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" :highlight-current="true"
          indent="15"
          
          class="custom-tree"
          ></el-tree>

      </div>
    </div>
    <div class="right">
      <p v-if="markdownData!=''" v-html="markdown"  > </p>

      <iframe v-else  :src="embeddedPageUrl" frameborder="0" width="100%" height="90%"></iframe>


    </div> -->

  </div>
</template>

<script>
// import embeddedPageUrl from "../../assets/OfficialWebsite/webpage/test.htm"

  import MarkdownIt from 'markdown-it'
export default {
  data() {
    return {
      data: [{
        label: '项目管理',
        children: [{
          label: '新建项目',

        }]
      }, {
        label: '团队',
        children: [{
          label: '移交团队',

        }, {
          label: '删除团队',

        }]
      }, {
        label: '场景',
        children: [{
          label: '新建场景',
          children: [{
            label: '编辑场景'
          }]
        }, {
          label: '修改场景',
          children: [{
            label: '三级 3-2-1'
          }]
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      embeddedPageUrl: "https://file.yicreator.com/dev/webpage/test.htm",

      markdownData:""

    }
  },
  computed:{
      markdown(){
        const md=new MarkdownIt();
        const result =md.render(this.markdownData)
        return result;
      }
  },
  methods: {
    handleNodeClick() {

    }
  }

}
</script>

<style scoped>
.box {
  width: 100%;
  min-height: 800px;
  background-color: #fff;
  position: relative;
}

.quexing {
  width: 400px;
  /* height: 300px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.quexing img {
  width: 500px;
  /* height: 400px; */
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */

}

.text {
  width: 450px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 88px;
  text-align: center;
}
</style>

<style lang="less" scoped>
.box {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;

  .left {
    width: 13%;
    height: 900px;

    overflow-y: auto;

    background: #F9F9F9;

    border-right: 1px solid #D7D7D7;

    .myTree {
      margin-top: 10px;

      // background: #F9F9F9;  
      /deep/ .el-tree {
        background: #F9F9F9;
        // #cfe1ff !important
      }
    }
  }

  .right::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: rgba(240, 240, 240, 1);
  }

  .right::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    border-radius: 10px;
    background-color: rgba(240, 240, 240, 0.5);
  }

  .right::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    background-color: rgba(240, 240, 240, 0.5);
  }

  .right {
    width: 85%;
    // height: 800px;
    overflow-y: auto;

  }
}

/deep/.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  color: rgb(21, 104, 251);
  // border-radius: 10px;
  // border: 2px solid rgba(5,96,253,0.83);
  box-sizing: border-box;

  background: RGBA(226, 234, 249, 1);
}


/deep/.el-tree--highlight-current .el-tree-node>.el-tree-node__content {
  margin: 3px 0;
}
 /deep/ .el-tree-node__expand-icon::before {
  color:RGBA(102, 102, 102, 0.7) /* 设置箭头的颜色 */
}

 /deep/ .el-tree-node__expand-icon.is-leaf::before{
  color: transparent;
    cursor: default;
}



</style>