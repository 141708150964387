<template>
  <div class="box">

    <!-- 
    <el-dialog :visible.sync="mapShow">



    </el-dialog> -->


    <div class="locationPlan" v-if="mapShow">
      <div class="map">
      <div class="close" @click="mapShow=!mapShow">
        <i class="el-icon-close">

        </i>
      </div>

      <IndexMap ref="Map"></IndexMap>
      </div>
    </div>
    <div class="BasicSettingTop">
      <!-- {{  }} -->
      <div class="title">项目信息：</div>

      <div class="topContent">
        <div class="contentItem">
          <div class="shopName">
            <div class="label">
              <span style="color: #f01d1d">*</span> 项目名称:
            </div>
            <input v-model="vestIn" placeholder="请输入店铺名称"   :disabled="true" maxlength="20" type="text" class="name" />
          </div>
          <div class="shopName">
            <div class="label">
              <span style="color: #f01d1d">*</span> 场景名称:
            </div>
            <input v-model="sceneName" placeholder="请输入店铺名称" :disabled="true" maxlength="20" type="text" class="name" />
          </div>
          <div class="shopType">
            <div class="label">
              <span style="color: #f01d1d">*</span> 类型:
            </div>
            <el-select v-model=" projectType" placeholder="请选择项目类型">
              <el-option v-for="item in projectTypeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="contentItemBottom">
          <!-- <div class="introduce">
            <div class="label">
              <span style="color: #f01d1d">*</span> 店铺介绍:
            </div>
            <el-input type="textarea" :rows="6" placeholder="店铺介绍" v-model="introduce" class="mult" resize='none'
              maxlength="100" show-word-limit>
            </el-input>
          </div> -->

          <div class="location2">
            <div class=" exhibition">
              <span style="color: #f01d1d"> *</span> 云展厅名称:
            </div>
            <input v-model="exhibitionName" maxlength="50" placeholder="请输入展厅名称" type="text" class="locationName" />

            <!-- <div class="MapPlugin" @click="AssociationMap">关联地图</div> -->

          </div>
        </div>
      </div>
    </div>
    
    <div class="BasicSettingTop" v-show="projectType==0">
      <!-- {{ vestIn }} -->
      <div class="title">店铺信息：</div>

      <div class="topContent">
        <div class="contentItem">
          <div class="shopName">
            <div class="label">
              <span style="color: #f01d1d">*</span> 店铺名称:
            </div>
            <input v-model="shopName" placeholder="请输入店铺名称" maxlength="20" type="text" class="name" />
          </div>
          <div class="shopType">
            <div class="label">
              <span style="color: #f01d1d">*</span> 店铺类型:
            </div>
            <el-select v-model="shopType" placeholder="请选择店铺类型">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="shopTime">
            <div class="label">
              <span style="color: #f01d1d">*</span> 营业时间:
            </div>
            <!-- <el-date-picker
              v-model="openingHours"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker> -->
            <div class="time">
              <div>
                <el-time-picker is-range value-format="timestamp" v-model="openingHours" range-separator="至"
                  start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
                </el-time-picker>
              </div>


              <!-- <el-time-select
                  placeholder="起始时间"
                  v-model="startTime"
                  value-format="timestamp"
                >
                </el-time-select>
    
             
                <el-time-select
                  placeholder="结束时间"
                  v-model="endTime"
                  value-format="timestamp"
                >
                </el-time-select> -->

            </div>
          </div>
        </div>
        <div class="contentItemBottom">
          <div class="introduce">
            <div class="label">
              <span style="color: #f01d1d">*</span> 店铺介绍:
            </div>
            <el-input type="textarea" :rows="6" placeholder="店铺介绍" v-model="introduce" class="mult" resize='none'
              maxlength="100" show-word-limit>
            </el-input>
          </div>

          <div class="location">
            <div class="label">
              <span style="color: #f01d1d"> *</span> 地址信息:
            </div>
            <!-- :disabled="true" -->
            <el-input type="textarea" :rows="6" placeholder="请设置地址信息" disabled v-model="address"  class="mult" resize='none'
              maxlength="100" show-word-limit>
            </el-input>

            <!-- <input v-model="address" maxlength="50" placeholder="请输入地址信息" type="text" class="locationName" /> -->

            <div class="MapPlugin" @click="AssociationMap">设置位置</div>

          </div>
        </div>
      </div>
    </div>
    <div class="BasicSettingBottom"  v-show="projectType==0">
      <div class="title">功能归属：</div>

      <div class="BasicSettingBottomTop">
        <div class="ichnography">
          <div class="label"><span></span> 店铺平面图:</div>

          <div>
            <el-switch v-model="ichnography" active-color="#0560FD" inactive-color="#C7C7C7" @change="change1()">
            </el-switch>
            <input type="file" value="选择图片" ref="file" @change="fileChange($event)"
              accept="image/png, image/jpeg, image/jpg"
              style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />



            <div style="marginTop: 1.30208vw">


              <!-- <div v-if="ichnography" class="btn" @click="ichnographyChange()">
                <span v-if="!ichnographyShow">
                  上传平面图
                </span>
                <span v-else>
                  替换
                </span>
              </div>
              <img v-if="(ichnographyShow && ichnography)" :src="ichnographyUrl"
                style="width: 6.51042vw; hight:6.51042vw; marginTop: 1.30208vw;" alt="" />

               -->

              <div class="slideshowItem" v-show="ichnography">
                <div :class="{ 'showImg': true, 'selectSlideshowItem': true }">

                  <img v-show="(ichnographyShow && ichnography)" class="slideshowImg" :src="ichnographyUrl" alt="">

                  <div class="displace" v-show="ichnographyShow" @click="ichnographyChange()">替换图片</div>
                </div>
                <div v-show="!ichnographyShow" class="uploading" @click="ichnographyChange()">
                  <i class="el-icon-plus"></i>
                </div>

              </div>



            </div>
          </div>
        </div>
        <div class="result">


          <div class="label"><span></span> 店铺效果图:</div>
          <el-switch v-model="effect" active-color="#0560FD" inactive-color="#C7C7C7" @change="change2()">

          </el-switch>

          <div v-if="effect" style="marginTop:-0.52083vw;margin-left: 0.32552vw;">
            <!-- <el-button  v-show="listShow"  @click="resultChinge()" class="uploadingBtn" type="primary" round
            >
            上传效果图
          
          
          </el-button> -->

            <div class="enlargement" @click="changeAmplification()" v-show="listShow">
              <img src="../../../assets/PlugIn/cloudStore/unfold.png" alt="">

            </div>
            <input type="file" value="选择图片" ref="file4" @change="fileChange4($event)"
              accept="image/png, image/jpeg, image/jpg" multiple
              style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />
          </div>



        </div>
      </div>
      <div class="BasicSettingBottomBottom">
        <div class="BasicSettingbackground">
          <div class="label"><span></span> 店铺背景音:</div>
          <div>


            <input type="file" value="选择图片" ref="file2" @change="fileChange2($event)" accept="audio/*"
              style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />
            <el-switch v-model="backgroundMusic" active-color="#0560FD" inactive-color="#C7C7C7" @change="change3()">
            </el-switch>

            <div style="marginTop: 1.30208vw">
              <div v-if="backgroundMusic" class="audiotest" @click="backgroundMusicChange()">


                <span v-if="!backgroundMusicShow">
                  【上传店铺背景音】
                </span>
                <span v-else>
                 【 替换】
                </span>

              </div>

              <!-- <div class="slideshowItem" v-show="backgroundMusic">
                <div :class="{ 'showImg': true, 'selectSlideshowItem': true }">

                  <img v-show="(backgroundMusicShow && backgroundMusic)" class="slideshowImg" :src="ichnographyUrl" alt="">

                  <div class="displace" v-show="backgroundMusicShow" @click="backgroundMusicChange()">替换</div>
                </div>
                <div v-show="!backgroundMusicShow" class="uploading" @click="backgroundMusicChange()">
                  <i class="el-icon-plus"></i>
                </div>

              </div> -->




              <div v-if="(backgroundMusicShow && backgroundMusic)" class="backgroundMusicItem">
                <span class="name">{{ backgroundMusicUrl.name }}</span>

                <!-- <i class="el-icon-circle-close" @click="backgroundMusicChange(2,item)"></i> -->
              </div>
            </div>
          </div>
        </div>
        <input type="file" value="选择图片" ref="file3" @change="fileChange3($event)" accept="audio/*"
          style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />
        <div class="guide">
          <div class="label"><span></span>店铺导购音:</div>

          <div>

            <el-switch v-model="shoppingMusic" active-color="#0560FD" inactive-color="#C7C7C7" @change="change4()">
            </el-switch>
            <div style="marginTop: 1.30208vw">
              <div v-if="shoppingMusic" class="audiotest" @click="shoppingMusicChange()">


                <span v-if="!shoppingMusicShow">
                  【上传店铺导购音】
                </span>
                <span v-else>
                  【替换】
                </span>

              </div>

              <!-- <div class="slideshowItem" v-show="shoppingMusic">


                <div :class="{ 'showImg': true, 'selectSlideshowItem': true }">

                  <img v-show="(shoppingMusic && shoppingMusicShow)" class="slideshowImg" :src="ichnographyUrl" alt="">

                  <div class="displace" v-show="shoppingMusicShow" @click="shoppingMusicChange()">替换</div>
                </div>
                <div v-show="!shoppingMusicShow" class="uploading" @click="shoppingMusicChange()">
                  <i class="el-icon-plus"></i>
                </div>

              </div> -->


              <div v-if="(shoppingMusic && shoppingMusicShow)" class="backgroundMusicItem">
                <span class="name">{{ shoppingMusicUrl.name }}</span>

                <!-- <i class="el-icon-circle-close" @click="backgroundMusicChange(2,item)"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <input type="file" value="选择图片" ref="file5" @change="fileChange5($event)" accept="image/png, image/jpeg, image/jpg"
        style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />
      <div class="uploadList" v-show="(!listShow && effect)">
        <div class="uploadListTitle">
          <div class="serial">序号</div>
          <div class="uploadImg">效果图</div>
          <div class="operate">操作


            <div class="lessen" @click="changeAmplification()">

              <img src="../../../assets/PlugIn/cloudStore/lessen.png" alt="">
            </div>
          </div>
        </div>
        <div class="content">
          <div class="uploadListItem" v-for="(item, index) in effectList" :key="item.url">
            <div class="serial">{{ (index + 1) }}</div>
            <div class="uploadImg"><img :src="item.url" alt="" /></div>
            <div class="operate">

              <span class="change" @click="changePicture(item)">更换</span>


              <span class="delete" @click="deletePicture(item)">删除</span>
            </div>
          </div>
        </div>
        <div class="goOn" @click="resultChinge()">上传效果图</div>
      </div>
    </div>
  </div>
</template>

<script>
import { OssManager } from "../../../threejs/network/OssManager";

import { MainObjects } from "../../../threejs/common/MainObjects";

import { ICloudSores } from "../CloudStores/cloudSoresThree/ICloudSores"

import IndexMap from "./map.vue"

export default {
  data() {
    return {
      vestIn: "",
      //店铺名称
      shopName: "",
      //店铺类型
      shopType: "",
      //营业时间
      openingHours: null,
      //店铺介绍
      introduce: "",
      Time: [],

      // 平面图
      ichnography: false,
      ichnographyUrl: "",
      ichnographyShow: false,
      ichnographySize: 0,


      //地址
      address: "",

      //效果
      effect: false,
      effectShow: false,

      effectList: [],

      //要替换的图片
      replaceIndex: -1,
      // 背景音乐
      backgroundMusic: false,
      backgroundMusicUrl: {},

      backgroundMusicShow: false,
      shoppingMusic: false,

      shoppingMusicShow: false,
      shoppingMusicUrl: {},
      options: [
        {
          value: 0,
          label: "中岛店铺",
        },
        {
          value: 1,
          label: "边厅店铺",
        },
        {
          value: 2,
          label: "店中店",
        },
        {
          value: 3,
          label: "街店",
        },
      ],
      projectType:0,



      projectTypeList :[
        {
          value: 0,
          label: "云店",
        },
 
      ],
      exhibitionName:"",
      value: "",
      value2: "",
      textarea: "",
      value3: true,
      num: "",

      listShow: false,
      mapShow: false,
      sceneName:"",
      addressPos:[],
        adcode:"",
        aDname:""

    };
  },
  components: { IndexMap },
  mounted() {
    this.reNum = this.getNum(20);

    if (this.$route.query.affiliation) {
      this.vestIn = this.$route.query.affiliation
      this.sceneName = this.$route.query.sceneName


    }
  },
  methods: {


    setLocation(searchHere,userInput){
      console.log(searchHere,userInput,"searchHere")

      // let locationText= searchHere.district +searchHere.address 
      this.address=userInput

      // console.log(searchHere.location.pos,"pos")

      this.mapShow=!this.mapShow


      console.log(searchHere.location,"searchHere.location");
    //   [
    // 12957255.437781611,
    // 4847013.862317051
    //     ]
      
    if (searchHere.location.getLng) {
      let longitude=searchHere.location.getLng()
    let latitude=searchHere.location.getLat()
    let longitudeAndLatitude= [longitude,latitude]

    this.addressPos=longitudeAndLatitude



    
      
      ICloudSores.m_cloudSores.pis= longitudeAndLatitude

    } else {
      this.addressPos=searchHere.location.pis



    
      
      ICloudSores.m_cloudSores.pis= searchHere.location.pis
    }

  
      
    },

    initMap() {
      this.$refs.Map.initMap()

    },


    AssociationMap() {

      let _this = this
      this.mapShow = true
      //  this.$refs.Map.initMap()

      setTimeout(() => {
        _this.$refs.Map.pis=ICloudSores.m_cloudSores.pis

        _this.$refs.userInput= this.addressPos

        

        console.log(ICloudSores.m_cloudSores.pis,"ICloudSores.m_cloudSores.pis");

        _this.$refs.Map.initMap()


          
      }, 0);




    },



    changeAmplification() {
      this.listShow = !this.listShow
    },
    change1() {


      if (!this.ichnography) {
        this.$confirm("您确定关闭次功能吗？一旦关闭，之前设置的数据将会自动清除，请谨慎操作", "温情提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {

            this.ichnographyUrl = ""
            this.ichnographyShow = false


          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
            this.ichnography = true
          });




      }
    },

    change2() {
      if (!this.effect) {


        this.$confirm("您确定关闭次功能吗？一旦关闭，之前设置的数据将会自动清除，请谨慎操作", "温情提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {

            this.effectList = []
            this.effectShow = false


          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
            this.effect = true

          });


      }
    },

    change3() {
      if (!this.backgroundMusic) {
        this.$confirm("您确定关闭次功能吗？一旦关闭，之前设置的数据将会自动清除，请谨慎操作", "温情提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {

            this.backgroundMusicUrl = {}

            this.backgroundMusicShow = false


          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
            this.backgroundMusic = true

          });

      }

    },
    change4() {
      if (!this.shoppingMusic) {
        this.$confirm("您确定关闭次功能吗？一旦关闭，之前设置的数据将会自动清除，请谨慎操作", "温情提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {

            this.shoppingMusicUrl = {}
            this.shoppingMusicShow = false


          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });

            this.shoppingMusic = true

          });

      }

    },

    deletePicture(item) {



      this.effectList.splice(this.effectList.indexOf(item), 1)
    },



    changePicture(item) {

      this.$refs.file5.click();

      this.replaceIndex = this.effectList.indexOf(item)


    },

    fileChange5(e,) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("planeGraph", this.reNum);


      function uploadFinish(list) {
        let index = _this.replaceIndex
        console.log(index + 0, "index");
        _this.effectList[index] = list[0]

        _this.$forceUpdate()
        // item = list[0];

        // _this.filestate = false;
        _this.$refs.file5.value = ""
      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url);
    },



    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
        Math.pow(10, num - 1)
      );
      return random;
    },
    fileChange(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("planeGraph", this.reNum);


      function uploadFinish(list) {
        let urls = https + url + files[0].name;
        _this.ichnographyUrl = list[0].url.replace(/(\?|#)[^'"]*/, '');

        // _this.filestate = false;
        console.log(list, "list")
        _this.ichnographySize = list[0].size
        _this.ichnographyShow = true


      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url);
    },

    ichnographyChange() {





      this.$refs.file.click();


    },
    fileChange2(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("backgroundMusic", this.reNum);

      function uploadFinish(list) {
        let urls = https + url + files[0].name;

        // _this.filestate = false;

        let data = {
          name: files[0].name,
          url: list[0].url.replace(/(\?|#)[^'"]*/, ''),
          size: list[0].size,
        };

        _this.backgroundMusicUrl = data;
      }
      data.f_finishCallback = uploadFinish;

      data.upload(files[0], url);
    },

    backgroundMusicChange() {
      this.backgroundMusicShow = true
      this.$refs.file2.click();
    },
    fileChange3(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("shoppingGuide", this.reNum);

      function uploadFinish(list) {
        let urls = https + url + files[0].name;

        // _this.filestate = false;

        let data = {
          name: files[0].name,
          url: list[0].url.replace(/(\?|#)[^'"]*/, ''),
          size: list[0].size,
        };
        console.log(data, "shoppingMusicUrl");
        _this.shoppingMusicUrl = data;
      }
      data.f_finishCallback = uploadFinish;

      data.upload(files[0], url);
    },

    shoppingMusicChange() {
      this.shoppingMusicShow = true
      this.$refs.file3.click();
    },
    resultChinge() {
      if (this.effect == false) {
        this.effectList = [];
        this.reNum = this.getNum(20);
      } else {
        this.$refs.file4.click();

      }
    },

    fileChange4(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");
      let list = []








      let data = new OssManager(MainObjects.Network.m_bucketName);
      let reNum = this.getNum(20)

      let url = data.getCloudShopFloder("shoppingGuide", reNum);

      function uploadFinish(list) {
        // let urls = https + url + files.name;

        // _this.filestate = false;
        // let effectItem = {
        //   id:index,
        //   name: files[index].name,
        //   url: urls + "?time=" + _this.reNum,
        // };

        console.log(list, "effectItem");

        // list.push(effectItem);
        _this.effectList = [..._this.effectList, ...list]

        _this.listShow = false

        _this.$refs.file4.value = ""




      }
      data.f_finishCallback = uploadFinish;

      data.uploadFileList(files, url);


    },

  },






};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  // height: 100%;
  // background-color: antiquewhite;
  // overflow: hidden;


  .locationPlan {
    width: 750px;
    height: 580px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    // background-color: red;
    z-index: 999;
    // position: relative;
    border-radius: 8px;
    box-shadow: 1px 1px 6px 1px rgba(33, 35, 49, 0.1);
    padding-top: 50px;

    .map{
      width: 720px;
    height: 500px;
    margin: 0 auto;
    position: relative;


    .close{
      width: 30px;
      height: 30px;
      position: absolute;

      right:-20px;
      top: -40px;
      // background-color: #fff;
      // border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-items: center;
    }
    }

  }

  .BasicSettingTop {
    background-color: #fff;
    border-radius: 8px;
    width: 98%;
    height: 380px;
    margin: 0 auto;
    margin-top: 20px;
    overflow: hidden;

    .title {
      width: 96%;
      border-left: 3px solid #0560fd;
      margin: 0 auto;
      margin-top: 20px;
      height: 20px;
      padding-left: 10px;
      line-height: 20px;
      font-size: 16px;
      color: #333;
      font-family: PingFang SC;
      font-weight: bold;
    }

    .topContent {
      width: 96%;
      height: 280px;

      margin: 0 auto;
      margin-top: 30px;
      // background-color: #0560fd;
      overflow: hidden;

      input {
        background: #fff;
        height: 35px;
        border-radius: 8px;
        border: none;
        outline: none;
        padding-left: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        // color: #929292;
        line-height: 56px;
        border: 1px solid #DCDFE6 ;
        font-weight: 100;
        font-size: 12px;
      }
      input:placeholder-shown {
          font-size: 12px;
          color: #c0c4cc;
          }

      .name {
        width: 254px;
        height: 40px;
      }

      .contentItem {
        margin-top: 10px;
        height: 80px;

        // display: flex;
        // justify-content: space-around;

        .shopName {
          width: 500px;
          display: flex;
          float: left;

          input{
            width: 290px;
            height: 35px;
          }


        }

        .shopTime {
          display: flex;
          width: 500px;

          float: left;

          .time {
          float: left;
            width: 400px;
            // height: 100px;
            display: flex;
            justify-content: space-between;


            /deep/ .el-date-editor{
              height: 30px;
              line-height: 30px;
              margin-top: 5px;



            }

            /deep/ .el-range-separator{
                line-height:25px ;

              }
              /deep/ .el-icon-time{
                line-height:20px ;
              }
            .start {
              width: 200px;
            }

            .end {
              width: 200px;
            }

          }

        }

        .shopType {
          float: left;
          width: 500px;

          display: flex;
          /deep/.el-select{
            // height: 35px;
            // line-height: 35px;

          }
          /deep/ .el-input--suffix{
            // height: 30px;
            line-height: 40px;
          }
          /deep/ .el-input__inner{
            height: 35px;
            line-height: 35px; 
          }
        }

        .label {
          width: 90px;
          // margin-right: 10px;
          line-height: 40px;
        }
      }

      .contentItemBottom {
        width: 100%;
        height: 140px;

        // display: flex;

        .introduce {
          width: 500px;
          float: left;
          display: flex;

          .mult {
            width: 300px;
          }

          .label {
            width: 90px;
            // margin-right: 10px;
            // margin-left: 60px;
            line-height: 30px;

          }
 
        }

        .location2 {
          width: 400px;
          display: flex;

          .locationName {
            width: 400px;
            height: 40px;
          }

          .label {

            width: 160px;
            // margin-right: 10px;
            // margin-left: 40px;
            line-height: 45px;
          }
          .exhibition{
            width: 150px;
            line-height: 45px;


          }
          }

        .location {
          width: 500px;
          display: flex;

          .locationName {
            width: 400px;
            height: 40px;
          }

          .label {

            width: 160px;
            // margin-right: 10px;
            // margin-left: 40px;
            line-height: 45px;
          }
          .exhibition{
            width: 90px;
            line-height: 45px;


          }

          .MapPlugin {
            width: 150px;
            height: 40px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 40px;
            text-align: center;
            background: #0560fd;
            border-radius: 10px;
            margin-left: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .BasicSettingBottom {
    background-color: #fff;
    border-radius: 8px;
    width: 98%;
    height: 480px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    overflow: hidden;

        .title {
      width: 96%;
      border-left: 3px solid #0560fd;
      margin: 0 auto;
      margin-top: 20px;
      height: 20px;
      padding-left: 10px;
      line-height: 20px;
      font-size: 16px;
      color: #333;
      font-family: PingFang SC;
      font-weight: bold;
    }
    .BasicSettingBottomTop {
      margin-top: 50px;

      width: 60%;
      height: 140px;
      display: flex;

      .ichnography {
        width: 450px;
        display: flex;

        .label {
          width: 120px;
          // margin-right: 10px;
          margin-left: 40px;
          line-height: 20px;
        }

        img {
          width: 100px;
          height: 100px;
        }
        .slideshowItem {
            width: 200px;
            height: 140px;
            border-radius: 8px;
            float: left;
            // margin: 16px;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;

            .showImg {
              width: 100%;
              height: 85%;
              background: #f0f5ff;
              position: relative;

              .slideshowImg {
                width: 100%;
                height: 100%;

              }

              .displace {
                width: 100%;
                height: 25px;
                line-height: 25px;
                // border-radius: 8px;
                position: absolute;
                // left: 8px;
                bottom: 0px;
                text-align: center;
                cursor: pointer;
                background-color: rgba(5, 96, 253, 0.4);
                color: #fff;

              }

            }

            .selectSlideshowItem {
              box-shadow: 0 0 2px 0 rgb(5 96 253 / 50%);
            }

            .uploading {
              height: 50px;
              width: 50px;
              background: #fff;
              border-radius: 50%;
              position: absolute;
              top: 40%;
              left: 50%;
              transform: translate(-50%, -50%);

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 30px;
              }

            }


          }


      }

      .result {
        width: 50%;
        display: flex;

        .label {
          width: 120px;
          margin-right: 10px;
          // margin-left: 40px;
          line-height: 20px;

        }

        .uploadingBtn {
          width: 150px;
          height: 40px;
          line-height: 15px;

        }

        .enlargement {
          width: 15px;
          height: 15px;
          // background-color: #ff7502;
          float: right;
          // margin-top: 15px;
          margin-left: 10px;
          cursor: pointer;
          line-height: 45px;

          img {

            width: 100%;
            height: 100%;

          }
        }
      }
    }

    .BasicSettingBottomBottom {
      margin-top: 50px;

      width: 50%;
      height: 200px;
      display: flex;

      .audiotest{
    color: rgb(5, 96, 253);
    cursor: pointer;
}
      .BasicSettingbackground {
        width: 500px;
        display: flex;

        .label {
          width: 120px;
          // margin-right: 10px;
          margin-left: 40px;
          line-height: 20px;

        }

        .slideshowItem {
            width: 200px;
            height: 140px;
            border-radius: 8px;
            float: left;
            // margin: 16px;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;

            .showImg {
              width: 100%;
              height: 85%;
              background: #f0f5ff;
              position: relative;

              .slideshowImg {
                width: 100%;
                height: 100%;

              }

              .displace {
                width: 100%;
                height: 25px;
                line-height: 25px;
                // border-radius: 8px;
                position: absolute;
                // left: 8px;
                bottom: 0px;
                text-align: center;
                cursor: pointer;
                background-color: rgba(5, 96, 253, 0.4);
                color: #fff;

              }

            }

            .selectSlideshowItem {
              box-shadow: 0 0 2px 0 rgb(5 96 253 / 50%);
            }

            .uploading {
              height: 50px;
              width: 50px;
              background: #fff;
              border-radius: 50%;
              position: absolute;
              top: 40%;
              left: 50%;
              transform: translate(-50%, -50%);

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 30px;
              }

            }


          }


      


        
      }

      .guide {
        width: 50%;
        display: flex;

        .label {
          width: 120px;
          margin-right: 10px;
          // margin-left: 40px;
          line-height: 20px;

        }
        .slideshowItem {
            width: 200px;
            height: 140px;
            border-radius: 8px;
            float: left;
            // margin: 16px;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;

            .showImg {
              width: 100%;
              height: 85%;
              background: #f0f5ff;
              position: relative;

              .slideshowImg {
                width: 100%;
                height: 100%;

              }

              .displace {
                width: 100%;
                height: 25px;
                line-height: 25px;
                // border-radius: 8px;
                position: absolute;
                // left: 8px;
                bottom: 0px;
                text-align: center;
                cursor: pointer;
                background-color: rgba(5, 96, 253, 0.4);
                color: #fff;

              }

            }

            .selectSlideshowItem {
              box-shadow: 0 0 2px 0 rgb(5 96 253 / 50%);
            }

            .uploading {
              height: 50px;
              width: 50px;
              background: #fff;
              border-radius: 50%;
              position: absolute;
              top: 40%;
              left: 50%;
              transform: translate(-50%, -50%);

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 30px;
              }

            }


          }


      }
    }

    .uploadList {
      width: 45%;
      height: 100%;

      position: absolute;
      top: 0;
      right: 0;
      background-color: #f7f7f7;

      box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);

      .uploadListTitle {
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
        border-radius: 10px 10px 0px 0px;
        background: #ffffff;

        .serial {
          width: 20%;
          text-align: center;
        }

        .uploadImg {
          width: 50%;
          text-align: center;
        }

        .operate {
          width: 20%;
          text-align: center;

          .lessen {
            width: 20px;
            height: 20px;
            float: right;
            // background-color: #ff7502;
            margin-top: 5px;
            margin-right: -60px;
            cursor: pointer;

            img {

              width: 100%;

            }
          }

        }
      }

      .content::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      /* 滚动条的滑块 */
      .content::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 5px #69a0fe;
        background: #69a0fe;
        border-radius: 3px;
      }

      .content {
        overflow-y: auto;
        width: 100%;
        height: 65%;

        .uploadListItem {
          margin-top: 10px;
          width: 100%;
          height: 100px;
          display: flex;
          line-height: 100px;

          .serial {
            width: 20%;
            text-align: center;
          }

          .uploadImg {
            width: 50%;
            text-align: center;

            img {
              width: 100px;
              height: 100px;
              border-radius: 8px;
            }
          }

          .operate {
            width: 20%;
            text-align: center;
            display: flex;

            .change {
              width: 50%;
              text-align: center;
              cursor: pointer;
              color: #0560fd;
            }

            .delete {
              width: 50%;
              text-align: center;
              color: #ff7502;
              cursor: pointer;
            }
          }
        }
      }

      .goOn {
        width: 150px;
        height: 40px;
        background: #0560fd;
        border-radius: 10px;
        color: #fff;
        margin: 0 auto;
        margin-top: 20px;

        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.backgroundMusicItem {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  line-height: 20px;

  .name {
    display: inline-block;
    overflow: hidden;
    width: 150px;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap;
    margin-right: 5px;
  }

  i {
    float: right;
  }
}

.btn {
  width: 150px;
  height: 40px;
  background: #0560fd;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
</style>