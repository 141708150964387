
<template>
    <div class="box">
        <div class="top">
            <div class="top_left">
                <img src="http://microservice-app-files-test.oss-cn-hangzhou.aliyuncs.com/scenespace/icon.png" alt="">
                <span class="top_title">艺搭云店中心</span>
            </div>
            <div class="top_right" @mouseover="isDropOutShow()">
                <div class="user">
                    <img :src="headPortrait" alt="" @click="isDropOutShow()">
                    <span class="user_name" @click="isDropOutShow()">{{ userName }} </span>
                <!-- <div class="dropOut" v-show="dropOutShow" @click="logoutArgument()"  @mouseout="isDropOutHiden()" >
                        退出登录
                                                                                                    </div> -->
                </div>
                <div class="inform">
                    <img @click.stop="messageChange" src="../../../assets/Project/message.png" alt />
                    <div class="topFlag" v-if="$store.state.unread"></div>
                </div>
                <div class="topMessage">
                    <Message v-if="message" :groupId="groupId" />
                </div>
            </div>
        </div>
        <div class="content">
            <div class="contentLeft">
                <div class="navigation">
                <!-- <el-menu default-active="2" class="el-menu-vertical-demo" 
                        background-color="#CECECE" text-color="#000" active-text-color="#212134">
                        <el-menu-item index="1">
                            <span slot="title">项目目录</span>
                        </el-menu-item>
                        <el-menu-item index="2">
                            <span slot="title">回收站</span>
                        </el-menu-item>
                                                                                                    </el-menu> -->
                <!-- <div class="navigationItem" :class="{ 'navigationItemChecked': item.id == navigationItemCheckedId }"
                        v-for=" item in navigationItemList" :key="item.id" @click="switchover(item)">{{ item.name }}
                                                                </div> -->


                    <div class="">

                    </div>
                    <!-- @close="handleClose" -->
                    <el-menu :unique-opened="true" @open="handleOpen" class="el-menu-vertical-demo">
                        <el-submenu v-for="item in navigationItemList" :key="item.id" :index="item.id">
                            <template slot="title">
                                <i class="el-icon-location"></i>
                                <span>{{ item.name }}</span>
                            </template>
                            <el-menu-item-group>

                                <el-menu-item @click="switchover(i)" :index="i.id" v-for=" i in item.projectlist"
                                    :key="i.id">{{ i.name
                                    }}</el-menu-item>



                            </el-menu-item-group>
                        </el-submenu>

                    </el-menu>

                </div>
            </div>
            <div class="contentRight">
                <div class="sectorization">
                    <div class="search" v-show="navigationItemCheckedId == 0 || navigationItemCheckedId == 1">
                        <i class="el-icon-search icon"></i>
                        <input v-model="searchName" type="text" class="searchBox">
                        <span class="searchTips" @click="cloudStoreList()">搜索</span>
                    </div>


                    <div class="orderingRule" v-show="navigationItemCheckedId == 0">
                        <div class="orderingRuleLabel">排序：</div>
                        <el-select v-model="sort" placeholder="请选择" @change="cloudStoreList(parentId)">
                            <el-option v-for="item in sortList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="orderingRule" v-show="navigationItemCheckedId == 0">
                        <div class="orderingRuleLabel">店铺类型：</div>
                        <el-select v-model="storeType" placeholder="请选择" @change="cloudStoreList(parentId)" clearable>
                            <el-option v-for="item in storeTypeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="orderingRule" v-show="navigationItemCheckedId == 0">
                        <div class="orderingRuleLabel">状态：</div>
                        <el-select v-model="filtrateValue" placeholder="请选择" @change="cloudStoreList(parentId)" clearable>
                            <el-option v-for="item in filtrateValueList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>


                    <div class="orderingRule" v-show="navigationItemCheckedId == 2">
                        <div class="orderingRuleLabel">商品类型：</div>
                        <el-select v-model="commodityType" placeholder="请选择" @change="commodityManage()" clearable>
                            <el-option v-for="item in commodityTypeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="search" v-show="navigationItemCheckedId == 2">
                        <i class="el-icon-search icon"></i>
                        <input v-model="commodityName" type="text" class="searchBox">
                        <span class="commoditySearchTips" @click="commodityManage()">搜索</span>
                    </div>

                    <div class="orderingRule" v-show="navigationItemCheckedId == 4">
                        <div class="orderingRuleLabel">链接状态：</div>
                        <el-select v-model="linkState" placeholder="请选择" @change="linkManage()" clearable>
                            <el-option v-for="item in linkStateList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="search" v-show="navigationItemCheckedId == 4">
                        <i class="el-icon-search icon"></i>
                        <input v-model="LinkName" type="text" class="searchBox">
                        <span class="commoditySearchTips" @click="linkManage()">搜索</span>
                    </div>


                    <div class="rangeOfScreening" v-show="navigationItemCheckedId == 1 && checkList.length > 0">
                        <div class="restore" @click="batchReduction()">
                            <el-button v-show="navigationItemCheckedId == 1" class="newFolder" type="primary">
                                批量还原
                            </el-button>
                        </div>
                    </div>
                    <div class="sectorization_right">
                        <span class="deleteBatches" v-show="checkList.length > 0 && navigationItemCheckedId == 0"
                            @click="deleteInBatches()">
                            <el-button type="primary">
                                批量删除
                            </el-button>
                        </span>
                        <el-button class="newFolder" v-show="navigationItemCheckedId == 0" type="primary"
                            @click="createFolder()">新建文件夹</el-button>
                        <el-button v-show="navigationItemCheckedId == 1" class="newFolder" type="primary"
                            @click="emptyRecycleBin()">清空回收站</el-button>
                    </div>
                </div>




                <div class="directoryPath">
                    <div class="path"
                        v-show="navigationItemCheckedId == 0 || navigationItemCheckedId == 1 || navigationItemCheckedId == 3">
                        <div class="pathItem" @click="JumpDirectory('')">
                            <span>
                                全部文件
                            </span>
                            <!-- <span>|</span> -->
                        </div>
                        <div class="pathItem" v-for=" item in catalogueList" :key="item.id" @click="JumpDirectory(item.id)">
                            <span>/</span>
                            <span>
                                {{ item.name }}
                            </span>
                        </div>
                    <!-- <div class="nowPath">
                                                                                                        </div> -->

                    </div>
                </div>

                <div class="listFunctionDivision">
                    <el-table :data="tableData"  height="620" style="width: 100%"  @selection-change="selsChange"
                        @row-contextmenu="operate">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column v-show="navigationItemCheckedId == 2 || navigationItemCheckedId" type="index"
                            label="序号" width="50">
                        </el-table-column>
                        <el-table-column
                            v-if="navigationItemCheckedId == 0 || navigationItemCheckedId == 1 || navigationItemCheckedId == 3"
                            prop="name" label="文件名称">
                            <template slot-scope="scope">
                                <div class="openFile" @dblclick="accessFile(scope.row)">

                                    <i v-if="scope.row.type == 0" class="el-icon-folder-opened"></i>
                                    <i v-else class="el-icon-document"></i>


                                    <span style="margin-left: 0.65104vw">{{ scope.row.name }}</span>


                                </div>

                            </template>


                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 2 || navigationItemCheckedId == 4" prop="name"
                            label="项目名称">
                            <template slot-scope="scope">
                                <div class="openFile">




                                    <span style="margin-left: 0.65104vw">{{ scope.row.projectName }}</span>


                                </div>

                            </template>


                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 4" prop="name" label="店铺名称">
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span style="margin-left: 0.65104vw">{{ scope.row.name }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 4" prop="name" label="店铺类型">
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span v-if="scope.row.storeProperty == 0">3D云店</span>
                                    <span v-if="scope.row.storeProperty == 1">实景云店</span>

                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="navigationItemCheckedId == 4" prop="name" label="链接封面">
                            <template slot-scope="scope">


                                <div v-if="scope.row.loadingImage" class="demo-image__preview">
                                    <el-image style="width: 5.20833vw; height: 5.20833vw" :src="scope.row.loadingImage"
                                        :preview-src-list="[scope.row.loadingImage]">
                                    </el-image>
                                </div>
                                <div v-else>
                                    无
                                </div>



                            </template>
                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 4" width="180"  prop="name" label="营业时间"  align="center" header-align="center" >
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span>{{ scope.row.start |transitionData2 }}</span>-
                                    <span>{{ scope.row.end | transitionData2 }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 4" prop="name" label="店铺介绍">
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span>{{ scope.row.introduce }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 4" prop="name" label="地址详情">
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span>{{ scope.row.storeSite }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 4" prop="name" label="店铺链接">
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span> <a :href="'https://www.yicreator.com/plugIn/index.html#/'+scope.row.link"  target="_blank" >  https://www.yicreator.com/plugIn/index.html#/{{scope.row.link }}  </a>    </span>
                                </div>
                            </template>
                        </el-table-column>


                        <el-table-column v-if="navigationItemCheckedId == 4" prop="name" label="链接密码">
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span>{{ scope.row.password }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 4" width="200" prop="name" label="最后生成时间" align="center" header-align="center" >
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span>{{ scope.row.createTime | TimeToTranslate }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 4" prop="name" label="链接状态">
                            <template slot-scope="scope">
                                <div class="openFile">
                                    <span v-if="scope.row.isClose == 0">运行中</span>
                                    <span v-else>已关闭</span>

                                </div>
                            </template>
                        </el-table-column>


                        <el-table-column v-if="navigationItemCheckedId == 2" prop="name" label="类型">
                            <template slot-scope="scope">
                                <div class="openFile">

                                    <span v-if="scope.row.type == 0">商品</span>
                                    <span v-else> 家具</span>



                                </div>

                            </template>


                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 2" prop="name" label="名称">
                            <template slot-scope="scope">
                                <div class="openFile">




                                    <span>{{ scope.row.name }}</span>


                                </div>

                            </template>


                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 2" prop="name" label="尺寸">
                            <template slot-scope="scope">
                                <div class="openFile">




                                    <span>{{ scope.row.size }}</span>


                                </div>

                            </template>


                        </el-table-column>

                        <el-table-column v-if="navigationItemCheckedId == 2" prop="name" label="示例">
                            <template slot-scope="scope">

                                <div class="demo-image__preview">
                                    <el-image style="width: 6.51042vw; height: 6.51042vw" :src="scope.row.image"
                                        :preview-src-list="[scope.row.image]">
                                    </el-image>
                                </div>



                            </template>


                        </el-table-column>


                        <el-table-column v-if="navigationItemCheckedId == 2" prop="name" label="描述">
                            <template slot-scope="scope">
                                <div class="openFile">



                                    <span style="margin-left: 0.65104vw">{{ scope.row.introduction }}</span>


                                </div>

                            </template>


                        </el-table-column>


                        <el-table-column v-if="navigationItemCheckedId == 2" prop="name" label="跳转链接">
                            <template slot-scope="scope">
                                <div class="openFile">




                                    <span style="margin-left: 0.65104vw">{{ scope.row.feedFlare }}</span>


                                </div>

                            </template>


                        </el-table-column>
                        <el-table-column v-if="navigationItemCheckedId == 2" prop="name" label="创建时间">
                            <template slot-scope="scope">
                                <div class="openFile">



                                    <span style="margin-left: 0.65104vw">{{ scope.row.createTime | TimeToTranslate }}</span>


                                </div>

                            </template>


                        </el-table-column>



                        <el-table-column v-if="navigationItemCheckedId == 1" prop="updateTime" label="删除日期" width="180">
                        </el-table-column>
                        <el-table-column v-if="navigationItemCheckedId == 0" prop="updateTime" label="修改日期" width="180">
                        </el-table-column>





                        <el-table-column
                            v-if="navigationItemCheckedId == 1 || navigationItemCheckedId == 0 || navigationItemCheckedId == 3 || navigationItemCheckedId == 4"
                            prop="name" label="操作" width="180">

                            <!--  @click="displayMenu()" @mouseover="operate(scope.row)"-->
                            <template slot-scope="scope">

                                <div class="CloseLink" @click="CloseLink(scope.row)" v-if="navigationItemCheckedId == 4">
                                    关闭
                                </div>
                                <div v-else class="operation" @click="operate(scope.row)">

                                    <i class="el-icon-more"></i>

                                </div>



                            </template>

                        </el-table-column>
                    </el-table>


                    <div class="pagingBg">
                        <el-pagination v-show="navigationItemCheckedId == 2 || navigationItemCheckedId == 4" class="paging"
                            small layout="prev, pager, next" :current-page="page" :page-size="count"
                            @current-change="handleCurrentChange" :total="total">
                        </el-pagination>
                    </div>

                </div>

            </div>
        </div>


        <div class="myMenu" @mouseleave="mouseleave()">
            <div v-show="menu">
                <el-menu id="menu" @select="handleSelect" text-color="#000" class="menu" ref="menu">
                    <div v-if="navigationItemCheckedId == 0">



                        <el-menu-item index="1" class="menuItem">
                            <img src="../../../assets/Resource/group.png" alt class="menuIcon" />
                            <span slot="title">打开</span>
                        </el-menu-item>
                        <el-menu-item index="2" class="menuItem">
                            <img src="../../../assets/Resource/newName.png" alt class="menuIcon" />
                            <span slot="title">重命名</span>
                        </el-menu-item>

                        <el-menu-item index="3" class="menuItem">
                            <img src="../../../assets/Resource/copy.png" alt class="menuIcon" />
                            <span slot="title">复制</span>
                        </el-menu-item>

                        <el-menu-item index="4" class="menuItem">
                            <img src="../../../assets/Resource/icon-67.png" alt class="menuIcon" />
                            <span slot="title">删除</span>
                        </el-menu-item>
                    <!-- <el-menu-item index="5" class="menuItem">
                        <img src="../../../assets/Resource/newName.png" alt class="menuIcon" />
                        <span slot="title">黏贴</span>
                                                                                                    </el-menu-item> -->

                        <el-menu-item index="6" class="menuItem">
                            <img src="../../../assets/Resource/stickup.png" alt class="menuIcon" />
                            <span slot="title">移动</span>
                        </el-menu-item>


                        <el-menu-item index="9" class="menuItem">
                            <img src="../../../assets/Resource/project_icon.png" alt class="menuIcon" />
                            <span slot="title">详情</span>
                        </el-menu-item>


                    </div>
                    <div v-if="navigationItemCheckedId == 1">


                        <!-- 回收站删除 -->
                        <el-menu-item index="7" class="menuItem">
                            <img src="../../../assets/Resource/newName.png" alt class="menuIcon" />
                            <span slot="title">删除</span>
                        </el-menu-item>

                        <!-- 回收站还原 -->
                        <el-menu-item index="8" class="menuItem">
                            <img src="../../../assets/Resource/newName.png" alt class="menuIcon" />
                            <span slot="title">还原</span>
                        </el-menu-item>


                    </div>

                    <div v-if="navigationItemCheckedId == 3">


                        <el-menu-item index="10" class="menuItem">
                            <img src="../../../assets/Resource/newName.png" alt class="menuIcon" />
                            <span slot="title">查看編輯模型</span>
                        </el-menu-item>
                    </div>

                    <div v-if="navigationItemCheckedId == 4">


                        <el-menu-item index="11" class="menuItem">
                            <img src="../../../assets/Resource/newName.png" alt class="menuIcon" />
                            <span slot="title">关闭</span>
                        </el-menu-item>
                    </div>

                </el-menu>
            </div>
        </div>

        <el-dialog :visible="setModelShow" width="25%" center :modal="false" title="修改模型" append-to-body
            :before-close="setModelClose" top="5vh">
            <el-form :model="form" label-width="6.51042vw" label-position="left">

                <el-form-item label-position="left"  label="类型：" >
                    <el-radio v-model="modelType" label="1">商品</el-radio>
                    <el-radio v-model="modelType" label="2">家具</el-radio>
                </el-form-item>
                <el-form-item  label-position="left" label="商品外显名称：">
                    <el-input v-model="selectPaper.explicitName"></el-input>
                </el-form-item>
                <el-form-item  label-position="left" v-show="modelType == 1" label="编辑官方指导价：">
                    <el-input v-model="selectPaper.guidingPrice"></el-input>
                </el-form-item>


                <el-form-item v-show="modelType == 1"  label-position="left" label="编辑商品描述">
                    <el-input v-model="selectPaper.describe"></el-input>
                </el-form-item>
                <el-form-item   label-position="left" label="上传商品示意图" label-width="6.51042vw">

                    <div class="templateBtn" @click="setCommodityDataImg()">添加示意图</div>

                    <div class="uploadImg">
                        <img class="furnitureImg" v-show="selectPaper.showImg" :src="selectPaper.showImg" alt="">
                        <div class="change" v-show="selectPaper.showImg" @click="setCommodityDataImg()">更换 </div>
                    </div>
                </el-form-item>

                <el-form-item  label-position="left" v-show="modelType == 1" label="电商跳转url">
                    <el-input v-model="selectPaper.url"></el-input>
                </el-form-item>

                <el-form-item v-show="modelType == 2"  label-position="left" label="*编辑家具参数：">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="grid-content bg-purple">
                                长：<el-input-number class="furnitureParameter" :controls="false" v-model="selectPaper.len"></el-input-number></div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content bg-purple">
                                宽：<el-input-number class="furnitureParameter" :controls="false" v-model="selectPaper.breadth"></el-input-number></div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content bg-purple">
                                高：<el-input-number class="furnitureParameter" :controls="false" v-model="selectPaper.altitude"></el-input-number></div>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item v-show="modelType == 2" label="编辑家具描述" label-width="6.51042vw" label-position="left">
                    <el-input type="textarea" placeholder="请输入内容" v-model="selectPaper.describe" show-word-limit>
                    </el-input>
                </el-form-item>

            </el-form>
            <div class="templateBtn" @click="setModelData()">确认</div>
        </el-dialog>




        <!-- " -->
        <el-dialog :visible="$store.state.cloudTree" width="25%" :modal="true" title="请选择要移动到的文件夹" append-to-body
            :before-close="downloadClose" top="23vh">

            <SelectFolderTree v-if="$store.state.cloudTree" :parentId="parentId" :groupId="groupId" :projectId="projectId">
            </SelectFolderTree>








        </el-dialog>


        <div class="particulars" v-show="particularsShow">
            <div class="title">
                <i v-if="(particularsData.type == 0)" class="el-icon-folder-opened"></i>
                <i v-else class="el-icon-document"></i>


                <span style="margin-left: 0.65104vw">{{ particularsData.name }}</span>

                <div class="close" @click="particularsShowClose()">
                    <i class="el-icon-close "></i>


                </div>


            </div>
            <div class="type">
                类型： <span style="margin-left: 0.65104vw">{{ (particularsData.type == 0 ? "文件夹" : "文件") }}</span>

            </div>
            <div class="location">
                位置： <span style="margin-left: 0.65104vw">{{ particularsData.site }}</span>

            </div>
            <div class="size">
                大小：{{ (particularsData.size) |formatbytes}}

            </div>
            <div class="creationTime">
                创建时间： <span style="margin-left: 0.65104vw">{{ particularsData.createTime }}</span>
            </div>

        </div>
        <input type="file" value="选择图片" ref="file2" @change="fileChange2($event)" accept="image/*"
            style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
    </div>
</div></template>

<script>
import SelectFolderTree from "./SelectFolderTree"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

// import Message from "../../manager/Message";
import Message from "./CloudStoreNews";

import commodity from "../../../assets/PlugIn/cloudStore/commodity.png";
import { OssManager } from "../../../threejs/network/OssManager";

import { MainObjects } from "../../../threejs/common/MainObjects";

import { INetwork } from "../../../threejs/interface/INetwork";


export default {
    data() {
        return {
            //排序
            sort: 0,
            //父文件夹id
            parentId: "",

            //状态
            filtrateValue: "",
            filtrateValueList: [
                {
                    value: 0,
                    label: "未发布"
                },
                {
                    value: 1,
                    label: "已发布"
                }
            ],
            options: [
                {

                    value: '',
                    label: '全部'
                },
                {

                    value: '0',
                    label: '私密作品'
                },

                {
                    value: '1',
                    label: '公开作品'
                }],
            tableData: [],
            navigationItemList: [
                {
                    id: "index0",
                    name: "云店目录",
                    projectlist: [
                        {
                            id: "0",
                            name: "云店目录",
                        },
                        {
                            id: "1",
                            name: "回收站"
                        },
                    ]
                },
                {
                    id: "index1",
                    name: " 商品管理",
                    projectlist: [
                        {
                            id: "2",
                            name: "商品管理数据",
                        },
                        {
                            id: "3",
                            name: "模型管理数据"
                        },
                    ]
                },

                {
                    id: "index2",
                    name: " 链接管理",
                    projectlist: [
                        {
                            id: "4",
                            name: "链接数据"
                        }
                    ]

                },
            ],
            sortList: [
                {
                    value: 0,
                    label: "最新修改"
                },
                {
                    value: 1,
                    label: "最新创建"
                },
            ],
            //选择的数据
            checkList: [],
            //选中的菜单
            navigationItemCheckedId: 0,
            menu: false,

            //选择的文件/文件夹
            selectPaper: {},

            newName: "",
            //搜索
            searchName: "",


            //文件列表树
            dataList: [],

            //目录列表
            catalogueList: [],
            //消息回显
            message: false,
            userName: "",

            headPortrait: "",
            dropOutShow: false,

            particularsShow: false,
            particularsData: {},

            GroupList: [],
            setModelShow: false,
            form: {

            },
            selectProjectData: {},
            modelType: "1",
            groupId: "",
            projectId: "",
            total: 0,
            commodityTypeList: [
                {
                    value: 0,
                    label: "家具"
                },
                {
                    value: 1,
                    label: "商品"
                }
            ],
            commodityType: 0,
            commodityName: "",

            linkStateList: [
                {
                    value: 0,
                    label: "运行中"
                },
                {
                    value: 1,
                    label: "已关闭"
                }
            ],

            linkState: "",
            LinkName: "",
            total: 0,
            count: 10,
            page: 1,
            storeType: "",
            storeTypeList: [
                {
                    value: 0,
                    label: "虚拟云店"
                },
                {
                    value: 1,
                    label: "实景云店"
                }

            ]

        }
    },
    filters: {
        formatbytes: function (bytes) {
            if (bytes) {
                
            if (bytes === 0) return "0 B";
            var k = 1024, // or 1024
                sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
                i = Math.round(Math.log(bytes) / Math.log(k));

            return (bytes / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
            }else{
                return "0 B";
            }

        },
        //过滤器时间
        TimeToTranslate(timestamp) {
            if (timestamp == 0) {
                return "时间信息错误!";
            } else {
                // 时间戳为10位需*1000，时间戳为13位不需乘1000
                var date = new Date(timestamp);
                var Y = date.getFullYear() + "-";
                var M =
                    (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-";
                var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
                var h = date.getHours() + ":";
                var m = date.getMinutes() + ":";
                var s = date.getSeconds();

                return Y + M + D + h + m + s;
            }
        },
        transitionData2(message) {
            // var date = new Date(parseInt(message)* 1000);

            // return  `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDay()}  ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;


            var times = new Date(parseInt(message));

            let Mont = parseInt(times.getMonth()) + 1

            let Year = times.getFullYear()
            let day = times.getDate()


            let Hours = ("0000000000" + times.getHours()).substr(-2, 2)
            let minutes = ("0000000000" + times.getMinutes()).substr(-2, 2)
            let Seconds = ("0000000000" + times.getSeconds()).substr(-2, 2)




            return ` ${Hours} : ${minutes} : ${Seconds}`;

        },

    },





    mounted() {
        // window.addEventListener("click", e => {
        //     if (this.menu == true) {
        //         this.menu = false;
        //     }
        // });
        document.title = '艺搭';

        this.groupId = this.$route.query.groupId



        this.headPortrait = localStorage.getItem("image");

        this.userName = localStorage.getItem("userName");

        this.projectId = this.$route.query.projectId

        this.cloudStoreList(this.parentId)



        this.isNotLook();

        this.getUserSig()
        //点击空白隐藏
        window.addEventListener("click", e => {
            this.message = false;
            this.transitionTeam = false;
            this.dropOutShow = false;
            // if (this.dropOutShow==true) {

            // this.dropOutShow=false
            // }
        });

        this.modelGroupList()





    },
    components: {
        SelectFolderTree,
        Message
    },
    methods: {

        fileChange2(e) {
            var _this = this;

            let https = MainObjects.Network.m_http;

            //   let getHtttps=MainObjects.Network.m_http;
            const files = e.target.files;

            let data = new OssManager(MainObjects.Network.m_bucketName);

            let url = data.getCloudShopFloder("commodity", this.selectPaper.id);
            function uploadFinish(list) {

                _this.selectPaper.showImg = list[0].url.replace(/(\?|#)[^'"]*/, '');
                // _this.$parent.ICloudSoresThree.setCommodityData(this.node.id, this.commodityData)
                // _this.$parent.getData()


            }
            data.f_finishCallback = uploadFinish;
            data.upload(files[0], url);
        },


        setCommodityDataImg() {
            this.$refs.file2.click();
        },


        async updateProjectMode() {
            let data = {
                id: this.selectPaper.id,
                modelName: this.selectPaper.modelName,
                modelImg: this.selectPaper.modelImg,
                modelNumber: this.selectPaper.modelNumber,
                showName: this.selectPaper.modelName,
                len: this.selectPaper.len,
                breadth: this.electPaper.breadth,
                altitude: this.selectPaper.altitude,
                guidingPrice: this.selectPaper.guidingPrice,
                url: this.selectPaper.url,
                showImg: this.selectPaper.showImg,
                describe: this.selectPaper.describe,
                modelType: this.modelType
            }

            const res = await this.$https.updateProjectMode(data)

            if (res.success == true) {
                this.projectModelList(this.projectId)

            }

        },

        setModelData() {
            this.updateProjectMode()
            this.setModelShow = false

        },
        setFurnitureImg() {

        },

        setModelClose() {
            this.setModelShow = false
        },



        async projectModelList(projectId) {
            let data = {
                projectId: projectId
            }
            const res = await this.$https.projectModelList(data)

            if (res.success == true) {

                let modelList = res.data
                for (const iterator of modelList) {
                    iterator.name = iterator.modelName
                }

                this.tableData = modelList
            }


        },

        handleOpen(index, indexPath) {
            // console.log(index,indexPath,"indexPath")
            this.modelProjectList(index)
        },

        async modelProjectList(id) {
            let data = {
                groupId: id
            }
            const res = await this.$https.modelProjectList(data)
            if (res.success === true) {
                for (const iterator of this.GroupList) {

                    if (iterator.groupId == id) {
                        iterator.projectlist = res.data
                        // iterator.projectlist = [{ id: 1, name: "niaho" }]

                    }

                }

            }
        },
        async modelGroupList() {
            let data = {
                type: 0
            }
            const res = await this.$https.modelGroupList(data)
            if (res.success == true) {





                let GroupList = []
                for (data of res.data) {
                    data.projectlist = []
                    GroupList.push(data)
                }

                this.GroupList = GroupList

            }
        },


        particularsShowClose() {

            this.particularsShow = false
        },

        particularsShowChange(item) {
            this.particularsShow = true
        },

        ...mapMutations(["changeCloudFileData", "changeCloudTree", "changeCloudTreeOperationType", "changeMoveList"]),
        async logoutArgument() {
            let phone = localStorage.getItem("phone");

            const res = await this.$https.logout({
                phone: phone
            });

            if (res.success == true) {
                // this.$message({
                //   showClose: true,
                //   message: res.errMessage,
                //   type: "success"
                // });

                let promise = this.$tim.logout();
                promise.then(function (imResponse) {
                    // 登出成功

                }).catch(function (imError) {
                    //   console.warn('logout error:', imError);
                });
                this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
                localStorage.clear(); //清楚缓存addMemberPage
            } else if (res.errCode == "411") {
                this.$message({
                    showClose: true,
                    message: res.errMessage
                    // type: "warning"
                });
                this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
            } else if (res.errCode == "412") {
                this.$message({
                    message: res.errMessage
                    // type: "warning"
                });
                this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
            } else if (res.errCode == "500") {
                this.$message({
                    showClose: true,
                    message: res.errMessage
                    // type: "warning"
                });
            }
        },


        isDropOutHiden() {
            this.dropOutShow = false

        },

        isDropOutShow() {

            this.dropOutShow = true
        },
        JumpDirectory(item) {

            this.parentId = item
            this.cloudStoreList(this.parentId)
            // let data= this.inquireNode(this.dataList, item)
            // this.
            // console.log("aa")


        },

        // getPathById(tree, id, path) {

        //     tree = Array.isArray(tree) ? tree : [tree]  
        //     if (!path) {
        //         path = []
        //     }

        //     console.log(tree.length,"tree.length");
        //     for (let i = 0, len = tree.length; i < len; i++) {
        //         console.log(i,"i")
        //         let tempPath = [...path]
        //         console.log(tempPath,"tempPath")
        //         tempPath.push(tree[i])
        //         if (tree[i].id === id) {
        //             return tempPath
        //         }

        //         if (tree[i].subCatalog) {
        //             return this.getPathById(tree[i].subCatalog, id, tempPath)
        //         }
        //     }
        // },


        //通过id查找节点
        inquireNode(tree, id) {

            for (let index = 0; index < tree.length; index++) {
                if (tree[index].id == id) return tree[index];
                if (tree[index].subCatalog) {
                    const res = this.inquireNode(tree[index].subCatalog, id);
                    if (res) return res;
                }
            }

            return null;
        },



        messageChange() {
            this.message = !this.message;
        },


        //是否存在未读
        async isNotLook() {
            let data = {};
            const res = await this.$https.haveNotLook(data);

            if (res.success == true) {
                // this.notLook = res.data;
                this.$store.commit('changeUnread', res.data)

            } else {
                this.$message(res.errMessage);
            }
        },

        //获取 userSing
        async getUserSig() {
            const res = await this.$https.getUserSig({

            })

            if (res.success == true) {

                localStorage.setItem('userSig', res.data)

                let userID = localStorage.getItem("phone")

                let test = localStorage.getItem("userSig")

                let _this = this
                // this.$tim.logout()
                let promise = this.$tim.logout();
                promise.then(function (imResponse) {
                    // 登出成功
                    _this.$tim.login({ userID: userID, userSig: res.data });

                }).catch(function (imError) {
                    // console.warn('logout error:', imError);
                });


            }
        },


        //获取文件列表
        async getFolderList(_type) {

            let data = {
                groupId: this.groupId,
                projectId: this.projectId
            }
            const res = await this.$https.moveFolderTree(data);

            // const loading = this.$loading({
            //   lock: true,
            //   text: "Loading",
            //   spinner: "el-icon-loading",
            //   background: "rgba(0, 0, 0, 0.7)"
            // });

            if (res.errCode == null) {
                let list = [


                ]

                list.push(res.data)

                this.dataList = list;

            } else {
                // loading.close();
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },

        //关闭树弹窗
        downloadClose() {
            this.changeCloudTree(false)
            this.cloudStoreList(this.parentId)

        },
        //清空回收站
        emptyRecycleBin() {
            this.$confirm("此操作会清空回收站, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {

                    this.cleanRecycleBin()


                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消恢复"
                    });
                });
        },


        async cleanRecycleBin() {

            let data = {
                groupId: this.groupId,
                projectId: this.projectId
            }

            const res = await this.$https.cleanRecycleBin(data)

            if (res.success == true) {
                this.$message({
                    message: "清空成功"

                });
                this.cloudStoreList(this.parentId)
            } else {
                this.$message({
                    message: res.errMessage

                });
            }
        },

        // 批量还原
        batchReduction() {
            this.$confirm("此操作会将选中文件恢复, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    //恢复
                    let list = []
                    this.checkList.forEach(element => {
                        let data = {
                            id: element.id,
                            type: element.type
                        }
                        list.push(data)
                    });
                    this.recoverFolderFile(list)


                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消恢复"
                    });
                });

        },
        //恢复
        async recoverFolderFile(alterCOS) {
            let data = {
                alterCOS: alterCOS
            }
            const res = await this.$https.recoverFolderFile(data)
            if (res.success == true) {
                this.$message({
                    message: "恢复成功"

                });

                this.cloudStoreList(this.parentId)

            } else {
                this.$message({
                    message: res.errMessage

                });
            }
        },
        //搜索
        async searchFolderFile() {
            let data = {
                isDelete: this.navigationItemCheckedId,
                groupId: this.groupId,
                projectId: this.projectId
            }
            const res = await this.$https.searchFolderFile(data)

            if (res.success == true) {
                this.tableData = res.data
            } else {

                this.$message({
                    message: res.errMessage

                });
            }



        },
        async updateName(id, type, name) {
            let data = {
                id: id,
                type: type,
                name: name
            }
            const res = await this.$https.updateName(data)
            if (res.success == true) {

                this.cloudStoreList(this.parentId)
                this.$message({
                    message: "更新成功"

                });
            } else {
                this.$message({
                    message: res.errMessage

                });
                this.rechristen()

            }


        },
        //重命名
        rechristen() {

            var containSpecial = RegExp(
                /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
            );

            this.$prompt(``, `重命名`, {
                confirmButtonText: "确定",
                showClose: true,
                // center: true,
                closeOnPressEscape: false,
                closeOnClickModal: false,

                inputPlaceholder: `在此输入名字`,
                inputValue: this.selectPaper.name,
                modal: false,

                inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
                inputValidator: value => {

                    // 点击按钮时，对文本框里面的值进行验证
                    if (!value) {
                        return "输入不能为空";
                    } else if (containSpecial.test(value)) {
                        return "输入不能为特殊字符";
                    } else if (value.length > 20) {
                        return "输入文字不能超过20个字符";
                    }
                }
            })
                .then(({ value }) => {
                    this.updateName(this.selectPaper.id, this.selectPaper.type, value)

                })
                .catch(() => {


                    this.$message({
                        // type: "info",
                        message: "已取消"
                    });
                });


        },

        //切换目录
        switchover(item) {
            this.page = 1


            this.navigationItemCheckedId = item.id
            if (item.id == 0 || item.id == 1) {
                this.parentId = ""
                this.cloudStoreList(this.parentId)
            } else if (item.id == 2) {
                this.commodityManage()
            } else if (item.id == 3) {
                this.projectModelList(this.projectId)
            } else if (item.id == 4) {
                this.linkManage()
            }
        },

        handleCurrentChange(val) {
            this.page = val;
            if (this.navigationItemCheckedId == 2) {
                this.commodityManage()
            } else if (this.navigationItemCheckedId == 3) {
                this.projectModelList(this.projectId)
            } else if (this.navigationItemCheckedId == 4) {
                this.linkManage()
            }

        },
        // 
        CloseLink(item) {
            if (!this.ichnography) {
                this.$confirm("此操作将关闭链接，是否继续？关闭后如果需要链接请重新生成新链接", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.sqlCloseLink(item)


                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消"
                        });

                    });




            }

        },
        async sqlCloseLink(item) {
            let data = {
                id: item.id
            }

            const res = await this.$https.closeLink(data)

            if (res.success) {
                this.linkManage()
            }
        },


        // 链接管理数据
        async linkManage() {
            let data = {
                page: this.page,
                count: this.count,
                projectId: this.projectId,
                state: this.linkState,
                name: this.LinkName,
            }

            const res = await this.$https.linkManage(data)

            if (res.success) {


                this.tableData = res.data.linkManageCOS
                this.total = res.data.total

            }
        },



        // 获取云店商品
        async commodityManage() {
            // this.projectId
            let data = {
                type: this.commodityType,
                projectId: this.projectId,
                page: this.page,
                count: this.count,
                name: this.commodityName,
            }
            const res = await this.$https.commodityManage(data)

            if (res.success) {

                this.tableData = res.data.commodityManageCOS
                this.total = res.data.total

                // console.log(res.data, "data")
            }

        },
        //批量删除
        deleteInBatches() {

            this.$confirm("是否删除所选全部文件？删除的文件可以在回收站还原？", "删除", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    //删除    
                    let list = []
                    this.checkList.forEach(element => {
                        let data = {
                            id: element.id,
                            type: element.type
                        }
                        list.push(data)
                    });
                    this.deleteFolderFile(list)


                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });


            // console.log(this.checkList)

        },
        //多选
        selsChange(sels) {
            var selsList = [];

            if (sels.length == 0) {
                this.checkList = [];
            } else {
                for (var i in sels) {
                    selsList.push(sels[i]); //放到空数组
                    this.checkList = selsList;
                }
            }
        },


        //点击新建文件夹
        createFolder() {
            var containSpecial = RegExp(
                /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
            );

            this.$prompt(``, `新建文件夹`, {
                cancelButtonText: "取消",
                confirmButtonText: "确定",
                inputPlaceholder: `请输入文件夹名称`,
                // center: false,
                inputErrorMessage: "请输入文件夹名称，且不能有特殊字符 最大20字符",
                customClass: "reset-message-box",
                closeOnClickModal: false,
                modal: false,
                inputValidator: value => {
                    // 点击按钮时，对文本框里面的值进行验证
                    if (!value) {
                        return "请输入文件夹名称";
                    } else if (containSpecial.test(value)) {
                        return "输入不能为特殊字符";
                    } else if (value.length > 20) {
                        return "输入文字不能超过20个字符";
                    }
                }
            })
                .then(({ value }) => {
                    this.addCloudStoreFolder(value, this.parentId)

                })
                .catch(() => {


                    this.$message({
                        // type: "info",
                        message: "取消输入"
                    });
                });

        },




        //新建文件夹
        async addCloudStoreFolder(name, parent) {
            let data = {
                name: name,
                parent: parent,
                groupId: this.groupId,
                projectId: this.projectId
            }
            const res = await this.$https.addCloudStoreFolder(data)
            if (res.success == true) {

                this.cloudStoreList(this.parentId)
                this.$message({
                    message: "创建成功"

                });
            } else {
                this.$message({
                    message: res.errMessage

                });
                this.createFolder()

            }
        },
        //获取数据列表

        async cloudStoreList(parentId) {
            let data = {
                sort: this.sort,
                parent: parentId,
                isDelete: this.navigationItemCheckedId,
                isShare: this.filtrateValue,
                storeType: this.storeType,
                groupId: this.groupId,
                projectId: this.projectId,
                name: this.searchName,
                // this.projectId
            }
            const res = await this.$https.cloudStoreList(data)
            if (res.success == true) {
                if (res.data.pathCOS) {
                    this.tableData = res.data.cloudStoreShowCOS

                    this.catalogueList = res.data.pathCOS
                } else {
                    this.tableData = res.data
                }

                this.getFolderList(this.$store.state.folderType)

            }

        },


        //点击文件夹名字
        openFile(parent) {


            if (parent.storeType == 0) {
                this.$router.push({ path: '/PlugIn/CloudStores/compileDirectory', query: { parentId: parent.id, teamId: this.groupId } })

            } else {
                this.$router.push({ path: '/PlugIn/CealisticCloudStores/compileDirectory', query: { parentId: parent.id, groupId: this.groupId } })

            }

        },




        async storeState(parent) {
            let data = {
                id: parent.id
            }
            const res = await this.$https.storeState(data)
            if (res.success == true) {
                this.openFile(parent)
            } else {

                this.$message({
                    message: res.errMessage

                });

            }
        },




        //菜单
        handleSelect(key) {
            this.menu = false

            if (key == 1) {
                // 打开
                this.accessFile(this.selectPaper)

            } else if (key == 2) {
                // 重命名
                this.rechristen()

            } else if (key == 3) {
                // 复制
                this.changeCloudTree(true)
                this.changeCloudTreeOperationType(2)
                this.changeCloudFileData(this.selectPaper)
            } else if (key == 4) {
                // 删除

                this.delete()
            } else if (key == 5) {
                // 黏贴

            } else if (key == 6) {
                // 移动
                this.changeCloudTree(true)
                this.changeCloudTreeOperationType(1)

                let moveList = []

                if (this.checkList.length > 0) {
                    moveList = this.checkList
                } else {
                    let data = {
                        id: this.selectPaper.id,
                        type: this.selectPaper.type
                    }
                    moveList.push(data)

                }

                this.changeMoveList(moveList)

                this.changeCloudFileData(this.selectPaper)




            } else if (key == 7) {
                // 回收站删除
                this.recycleBinDelete()
            } else if (key == 8) {
                this.revivification()
            } else if (key == 9) {

                let selectPaper = this.selectPaper
                this.particularsShow = true
                let particularsData = {

                    name: selectPaper.name,
                    site: "查询中",
                    size: "正在计算中",
                    createTime: "查询中",
                    type: selectPaper.type,

                }

                this.particularsData = particularsData
                this.folderStoreInformation()

                this.particularsShowChange()


            } else if (key == 10) {
                this.setModelShow = true
            } else if (key == 11) {

            }


        },

        async folderStoreInformation() {
            let data = {
                id: this.selectPaper.id,
                type: this.selectPaper.type,
            }

            const res = await this.$https.folderStoreInformation(data)

            if (res.success == true) {
                this.particularsData = res.data
            } else {

                let particularsData = {

                    name: selectPaper.name,
                    site: "未知",
                    size: "未知",
                    createTime: "未知",
                    type: selectPaper.type,

                }

                this.particularsData = particularsData
            }
        },




        // 回收站单独还原
        revivification() {
            this.$confirm("确认还原?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    //恢复 

                    let alterCOS = []

                    let data = {
                        id: this.selectPaper.id,
                        type: this.selectPaper.type
                    }
                    alterCOS.push(data)

                    this.recoverFolderFile(alterCOS)


                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消恢复"
                    });
                });



        },

        // 回收站删除
        recycleBinDelete() {
            this.$confirm("是否删除所选文件？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    //删除    

                    this.deleteDelFolderFile(this.selectPaper.id, this.selectPaper.type)


                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });

        },
        // 回收站删除
        async deleteDelFolderFile(id, type) {
            let data = {
                id: id,
                type: type,
            }
            const res = await this.$https.deleteDelFolderFile(data)

            if (res.success == true) {

                this.cloudStoreList(this.parentId)
                this.$message({
                    message: "删除成功"
                });
            } else {
                this.$message({
                    message: res.errMessage

                });


            }
        },



        //删除文件或文件夹
        delete() {
            this.$confirm("此操作会将文件移到回收站, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    //删除    

                    let alterCOS = []

                    let data = {
                        id: this.selectPaper.id,
                        type: this.selectPaper.type
                    }
                    alterCOS.push(data)

                    this.deleteFolderFile(alterCOS)


                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });




        },

        async deleteFolderFile(alterCOS) {
            let data = {
                alterCOS: alterCOS
            }
            const res = await this.$https.deleteFolderFile(data)
            if (res.success == true) {
                this.$message({
                    message: "删除成功"

                });

                this.cloudStoreList(this.parentId)

            } else {
                this.$message({
                    message: res.errMessage

                });
            }
        },



        //打开文件或文件夹
        accessFile(item) {
            if (this.navigationItemCheckedId != 2) {


                if (item.type == 0) {
                    this.parentId = item.id
                    this.cloudStoreList(this.parentId)


                } else {
                    if (this.navigationItemCheckedId == 0) {

                        this.storeState(item)

                        // this.openFile(item)
                    }
                }
            }

        },


        //拥有菜单功能
        operate(item) {

            event.preventDefault()
            this.selectPaper = item
            this.displayMenu()

        },


        displayMenu() {


            //获取到菜单
            let menu = this.$refs.menu;
            let x = event.clientX;
            let y = event.clientY;
            if (y > 700) {
                menu.$el.style.top = y - 150 + "px";
            } else {
                menu.$el.style.top = y + "px";
            }
            if (x > 1280) {
                menu.$el.style.left = x - 100 + "px";
            } else {
                menu.$el.style.left = x + "px";
            }



            this.menu = true; //让菜单显示


        },
        mouseleave() {
            this.menu = false;
        },


    },


}
</script>

<style  scoped>
.box {
    width: 100%;
    height: 100%;
}

.top {
    width: 100%;
    height: 60px;
    background-color: #fff;
    padding-top: 10px;

}

.top .top_left {
    float: left;
    width: 300px;
    margin-left: 20px;
    height: 100%;
    /* background-color: bisque; */
}

.top .top_left img {
    width: 50px;
    line-height: 50px;
    float: left;
    margin-right: 20px;

}

.top .top_left .top_title {
    float: left;
    font-size: 18px;
    line-height: 50px;
    font-weight: 600;

}

.top .top_right {
    float: right;
    width: 260px;
    position: relative;

}

.top .top_right .dropOut {
    width: 180px;
    height: 50px;
    line-height: 30px;
    position: absolute;
    bottom: -50px;
    background-color: #fff;
    text-align: center;
    border-radius: 8px;
    z-index: 999;
    cursor: pointer;
    line-height: 50px;
}

.top .top_right .user img {
    width: 35px;
    height: 35px;
    /* line-height: 80px; */
    margin-top: 8px;
    float: left;
    line-height: 50px;
    cursor: pointer;
    border-radius: 50%;

}

.top .top_right .user .user_name {
    float: right;
    font-size: 18px;
    line-height: 50px;
    cursor: pointer;
    text-align: center;
    width: 140px;
    margin-right: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.top_right .inform {
    width: 50px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
}

.top_right .inform .topFlag {
    position: relative;
    top: -6px;
    right: 8px;
    width: 6px;
    height: 6px;
    background: #ff7502;
    border-radius: 50%;
}

.top_right .inform img {
    width: 25px;
    height: 25px;
    cursor: pointer;

}

.content {
    width: 100%;
    /* height: 900px; */
    background-color: #ff7502;
    /* height: 90%; */

}

.content .contentLeft {
    width: 10%;
    height: 86vh;
    margin-top: 20px;

    float: left;
    background-color: #fff;
    
    overflow-y: auto;
    overflow: hidden;
}

.contentLeft::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

.contentLeft::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #69a0fe;
    background: #69a0fe;
}

.contentLeft::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #e9f1fe;
}

.content .navigation {
    margin-top: 20%;
    width: 100%;
    height: 80%;
    float: right;
}

.content .navigation .navigationItem {
    height: 40px;
    line-height: 40px;
    text-align: center;
    line-height: 40px;
    color: #000;
    /* font-size: 12px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #636d7e;
    cursor: pointer;
    border-radius: 8px;

}

.content .navigation .navigationItem:hover {
    height: 40px;
    line-height: 40px;
    text-align: center;
    line-height: 40px;

    /* font-size: 12px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #636d7e;
    background-color: rgba(85, 85, 85, .4);
    cursor: pointer;
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgb(0 0 0 0.2);
}

.content .navigation .navigationItemChecked {
    height: 40px;
    line-height: 40px;
    text-align: center;
    line-height: 40px;

    /* font-size: 12px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #06a7ff;
    background-color: rgba(6, 167, 255, .4);
    cursor: pointer;
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgb(0 0 0 0.2);
}

.contentRight {
    float: right;
    width: 90%;
    height: 100%;
    /* overflow: hidden; */


}


.contentRight .sectorization {
    width: 98%;
    height: 80px;
    margin: 0 auto;
    /* margin-top: 10px; */
    border-radius: 8px;
    /* background-color: aqua; */
    background-color: #fff;
    margin-top: 20px;



}

.contentRight .sectorization .search {
    width: 20%;
    float: left;
    background-color: #fff;
    margin: 0 30px 0 30px;
    height: 60px;
    margin-top: 10px;
    position: relative;

    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.contentRight .sectorization .search .icon {
    position: absolute;
    left: 15px;
    top: 20px;
}



.contentRight .sectorization .search .searchBox {
    width: 240px;
    border-radius: 8px;
    height: 40px;
    margin: 0 10px;

    margin-top: 6px;

    border: none;
    background-color: RGBA(247, 247, 247, 1);
    outline: none;
    text-indent: 25px;

}

.contentRight .sectorization .search .searchTips {
    color: #06a7ff;
    cursor: pointer;
}

.contentRight .sectorization .search .commoditySearchTips {
    color: #06a7ff;
    cursor: pointer;

    position: relative;
    left: 100px;
}

.contentRight .sectorization_right {
    width: 300px;
    float: right;
    margin-top: 15px;
    margin-right: 50px;

}

.contentRight .sectorization_right .deleteBatches {
    /* display: inline-block;
  
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 40px;
    cursor: pointer;
    width: 100px;
        height: 40px;
        background: #0560fd;
        border-radius: 10px;
        color: #fff;
    text-align: center; */
}

.contentRight .sectorization_right .newFolder {
    float: right;
}


.contentRight .directoryPath {
    height: 50px;

    width: 98%;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;


}

.pathItem {
    cursor: pointer;
}

.contentRight .directoryPath .path {
    width: 98%;
    height: 100%;
    /* background-color: #06a7ff; */
    margin: 0 auto;
    display: flex;
    line-height: 50px;
    color: #06a7ff;


}

.nowPath {
    color: #818999;
}

.contentRight .rangeOfScreening {
    width: 190px;
    margin-top: 15px;
    float: left;
    display: flex;
    margin-right: 30px;


}

.restore {
    color: #06a7ff;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 40px;
    cursor: pointer;
}

.contentRight .rangeOfScreening .rangeOfScreeningLabel {
    width: 90px;
    line-height: 40px;
}

.contentRight .orderingRule {
    width: 190px;
    margin-top: 15px;
    float: left;
    display: flex;
    margin-right: 10px;

}

.contentRight .orderingRule .orderingRuleLabel {
    width: 180px;
    line-height: 40px;
}

.contentRight .listFunctionDivision {
    width: 98%;
    height: 80px;

    margin: 0 auto;
    /* margin-top: 10px; */

    border-radius: 8px;
    background-color: aqua;
}

.pagingBg {
    background: #fff;
    width: 100%;
    height: 30px;
    overflow: hidden;
}

.paging {
    float: right;
}

.listFunctionDivision .openFile {
    cursor: pointer;
}


/* .operation {
    position: absolute;

} */
.CloseLink {
    cursor: pointer;
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999999;
}

.menuItem {
    /* text-align:center; */
    line-height: 60px;
}

.menuIcon {
    width: 19px;
    height: 17px;

    margin-right: 20px;
}

.myMenu /deep/ .menu {
    border-radius: 10px;

    padding: 10px 0;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
}

.myMenu /deep/.el-menu-item {
    height: 40px;
    line-height: 40px;
    position: relative;

    white-space: nowrap;
    list-style: none;
}

.myMent /deep/ .el-menu-item.is-active {
    color: #000;
}

/* 图标 */
.icon {
    width: 19px;
    height: 17px;
    margin: 0 2px;
}


.topMessage {

    /* width: 100px;
    height: 100px; */
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.2);
    position: absolute;
    right: 20px;
    top: 60px;
    z-index: 99;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #69a0fe;
    background: #69a0fe;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #e9f1fe;
}


/deep/ th .el-table-column--selection .cell:before {
    content: "全选";
    position: absolute;
    right: 0px;
}

.particulars {
    width: 300px;
    height: 300px;
    position: fixed;
    overflow-y: auto;
    top: 25%;
    left: 50%;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.2);
    background-color: #fff;

}


.particulars::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #69a0fe;
    background: #69a0fe;
}

.particulars::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #e9f1fe;
}

.particulars .title {
    height: 30px;
    line-height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.2);
    padding-left: 10px;
}

.particulars .type {
    width: 250px;
    margin: 0 auto;
    height: 30px;
    line-height: 30px;
    margin-top: 20px;

}

.location {

    width: 250px;
    margin: 0 auto;
    height: 30px;
    line-height: 30px;
    margin-top: 20px;

}

.size {

    width: 250px;
    margin: 0 auto;
    height: 30px;
    line-height: 30px;
    margin-top: 20px;

}

.creationTime {

    width: 250px;
    margin: 0 auto;
    height: 30px;
    line-height: 30px;
    margin-top: 20px;

}

.close {
    float: right;
    line-height: 30px;
    margin-right: 15px;
}

.templateBtn {
    width: 180px;
    height: 40px;
    background: #0560FD;
    border-radius: 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    cursor: pointer;
}

.uploadImg {

    width: 100px;
    height: 100px;
    margin: 0 auto;


}

.uploadImg img {
    width: 100%;
    height: 100%;
}

.uploadImg .change {
    width: 100%;
    text-align: right;
    color: #0560FD;
    cursor: pointer;
}
.furnitureParameter{
    width: 100%;
}
</style>