<template>
  <div class="box" @click.stop=""  v-loading="loading">
    <div class="header">
      <div class="tab">
        <div v-for="(item, index) in tabList" :key="index">
          <div class="list" :class="{ active: currentType == index }">
         
            {{ item.value }}
          </div>
        </div>
      </div>
      <div>
        <span v-if="allRead" @click.stop="DeletingRead()">删除已读</span>
      <span v-if="allRead" @click.stop="logAllLook()">全部已读</span>
      </div>


    </div>
    <!-- v-show="currentType == 0" -->
    <div class="content" v-infinite-scroll="load">
      <div v-for="(item,index) in messageList" :key="index">
        <div class="con_list" @click.stop="clickLog(item)">
          <div class="img_box">
            <img src="../../../assets/Project/group.png" alt />
            <div v-show="item.isRead==0" class="flag"></div>
          </div>
          <div class="list">
            <el-tooltip class="item" effect="dark" :content="item.log" placement="top">
              <div class="itemLog">{{item.log}}</div>
            </el-tooltip>
            <span>{{item.createTime}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="content" v-show="currentType == 1">功能暂未开放</div> -->

    <!-- <div class="myPaging" v-if="myPaging">
      <div class="pagingPositioning">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="count"
        ></el-pagination>
      </div>
    </div> -->

    <!-- <div class="bottom" v-else>
      <span>*仅显示三天内的消息</span>
      <p @click="messageHistoryShow">全部消息</p>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabList: [
        {
          id: 0,
          value: "通知"
        },
      ],

      //页码
      page: 1,
      //条数
      count: 10,

      currentType: 0,

      isThreeDay: true,
      total: 0,
      messageList: [
        
      ],
      loading:false,

    };
  },

  props: {
    myPaging: {
      type: Boolean,
      default: false
    },
    allRead: {
      type: Boolean,
      default: true
    },
    groupId:{
      type: String,
      default: ""

    }

  },
  mounted() {

if (this.myPaging){

    this.getMessage();

}
    this.isNotLook()
  },

  methods: {

    DeletingRead(){
      this.deleteIsRead()
    },

   async deleteIsRead(){
      const res= await  this.$https.deleteIsRead({})
      
       if (res.success == true) {
        this.page=1
        this.getMessage(1);
       
       }
   },


    //是否存在未读
    async isNotLook() {
      let data = {};
      const res = await this.$https.haveNotLook(data);

      if (res.success == true) {
        // this.notLook = res.data;
        this.$store.commit('changeUnread',res.data)
        
      } else {
        this.$message(res.errMessage);
      }

    },
      //查看全部
     async logAllLook(){
           let data={
            
        }
      const res = await this.$https.cloudStoreAllRead(data);
        
      if (res.success == true) {
          this.messageList=[]
          this.page=1
        this.getMessage();

        this.isNotLook()
   
        
      } else {
        this.$message(res.errMessage);
      }
      },


    load(){
        if (!this.myPaging) {
            this.getMessage()

            this.page=this.page +1
        }

     
    },
      //查看
   async clickLog(item){
     
       item.isRead=1
        let data={
            id:item.id
        }
      const res = await this.$https.cloudStoreReadLog(data);
        
      if (res.success == true) {
  
        // this.getMessage();
        this.$forceUpdate();

  
        this.isNotLook()



      } else {
        this.$message(res.errMessage);
      }
    },

    handleCurrentChange(val) {
      this.page = val;
      this.listLoading = true;
      this.getMessage();
    },


    //获取消息 
    async getMessage(type) {
      this.loading=true
      let data = {
        page: this.page,
        count: this.count,

        groupId:this.groupId
      };
      const res = await this.$https.cloudStoreLogList(data);

      if (res.success == true) {
        // this.intendantList = res.data;
        this.loading=false

        this.total = res.data.total;

        if (type==1) {
          this.messageList = res.data.cloudStoreLogCOS;
        } else {
          
      

        if (!this.myPaging){
            
            this.messageList =this.messageList.concat(res.data.cloudStoreLogCOS);


        }else{
            this.messageList = res.data.cloudStoreLogCOS;

        }
      }
      } else {
        this.$message(res.errMessage);
      }
    },

    tabChnage(index) {
      this.currentType = index;
      this.messageList=[]
       this.page=1

      this.getMessage();
    },
    //全部信息
    messageHistoryShow() {
      this.$parent.messageHistoryShow();
    }
  }
};
</script>

<style scoped>
.box {
  width: 420px;
  height: 510px;
  background: #fff;
  border-radius: 8px;
}
.header {
  width: 420px;
  height: 50px;
  border-bottom: 1px solid #eff3f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .tab {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
}
.header .tab .list {
  width: 60px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bolder;
  position: relative;
}
.header span {
  font-weight: bold;
  color: #0560fd;
  font-size: 14px;
  display: inline-block;
  width: 90px;
  text-align: left;
  cursor: pointer;
}
.active {
  font-weight: bold;
  color: #0560fd;
}
.content {
  width: 100%;
  height: 400px;
  overflow: auto;
}
.content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* 滚动条的滑块 */
.content::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
  border-radius: 3px;
}

.bottom {
  width: 100%;
  height: 40px;
  /* border-bottom: 1px solid #EFF3F4; */
  display: flex;

  align-items: center;

  border-top: 1px solid #eff3f4;
  /* line-height: 50px; */
}
.bottom span {
  display: inline-block;
  width: 50%;
  color: #ff7502;
  font-size: 12px;
  padding-left: 15px;
  font-weight: bold;
}
.bottom p {
  width: 50%;
  color: #000;
  font-size: 12px;
  padding-right: 15px;
  text-align: right;
  font-weight: bold;
  cursor: pointer;
}
.content .con_list {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.con_list .img_box {
  width: 44px;
  height: 44px;
  background: #fefefe;
  box-shadow: 0px 0px 6px 0px rgba(5, 96, 253, 0.2);
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.con_list .img_box img {
  width: 30px;
  /* height: 40px； */
}
.con_list .img_box .flag {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background: #ff7502;
  border-radius: 50%;
}

.topFlag {
  position: absolute;
  top: 0;
  right: 10px;
  width: 6px;
  height: 6px;
  background: #ff7502;
  border-radius: 50%;
}
.con_list .list {
  width: 350px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.con_list .list div {
  height: 25px;
  line-height: 25px;
  font-weight: bold;
  font-size: 14px;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.con_list .list span {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  color: #999999;
  font-size: 12px;
}

.myPaging {
  width: 90%;
  height: 20px;
}

.pagingPositioning {
  float: right;
}
</style>