<template>
  <div class="box">



    <div class="BasicSettingBottom">
      <div class="BasicSettingBottomLeft">
        <div class="title">预览</div>
        <div class="cameraDisplay" id="runtime_container" ref="runtime_container"></div>
      </div>

      <div class="BasicSettingBottomRight">
        <div class="switchover">
          <div class="tabTitle">
            <a :title="item.title" v-for="(item, index) in tabImgList" :key="index">
              <div class="ImgList" @click="propertyChange(index)" :class="{ 'active': currentType == index }">
                <img v-if="currentType == index" :src="item.selecImg" alt="">
                <img v-else :src="item.img" alt="">


              </div>
            </a>
          </div>
        </div>

        <div class="rightContent">
          <!-- <div class="title"></div> -->


          <div class="attribute" v-show="currentType == 0">
            <div class=" fovAttributeName">相机名称：</div>
            <div class="fovAttributeValue">
              <div class="CameraName">

                {{ nowSelectData.m_name }}
              </div>

            </div>
          </div>


          <div class="attribute" v-show="currentType == 0">
            <div class=" fovAttributeName">FOV视角：</div>
            <div class="fovAttributeValue">
              <el-slider @input="fovChange()" size="mini" v-model="nowSelectData.m_FOV" show-input :min="30" :max="130"
                :show-tooltip="false"></el-slider>
            </div>
          </div>
          <div class="attribute" v-show="currentType == 0">
            <div class=" fovAttributeName">近视口：</div>
            <!--  -->
            <div class="fovAttributeValue">
              <el-input-number size="mini" @input="fovChange()" v-model="nowSelectData.m_Near" :min="1" :max="nowSelectData.m_Far"   label="far"></el-input-number>
            </div>
          </div>
          <div class="attribute" v-show="currentType == 0">
            <div class=" fovAttributeName">远视口：</div>
            <div class="fovAttributeValue">
              <el-input-number size="mini" @input="fovChange()" v-model="nowSelectData.m_Far" :min="nowSelectData.m_Near"  label="far"></el-input-number>
            </div>
          </div>


          <div class="attribute" v-show="currentType == 2">
            <div class=" fovAttributeName">背景角度：</div>
            <div class="fovAttributeValue">
              <el-slider @input="changeAngle()" v-model="angleValue" show-input :min="1" :max="360"
                :show-tooltip="false"></el-slider>
            </div>
          </div>
          <div class="attribute" v-show="currentType == 2">
            <div class=" fovAttributeName">环境模板：</div>
            <div class="fovAttributeValue">

              <div class="btn" @click="setCameraTemplateShow()">
                点击设置
              </div>
            </div>
          </div>


          <div class="attribute" v-show="currentType == 1">
            <div class=" fovAttributeName">触发距离：</div>
            <div class="fovAttributeValue">
              <el-slider v-model="distance" show-input :min="1" :max="10" :show-tooltip="false"></el-slider>
            </div>
          </div>

          <!-- <div class="attribute">
          <div class="attributeName">水平角度：</div>
          <div class="attributeValue">
            <el-slider @change="fovChange()" v-model="nowSelectData.m_horizontal" show-input :min="0" :max="360"
              :show-tooltip="false"></el-slider>
          </div>
        </div> -->

          <div class="attribute" v-show="currentType == 0">
            <div class="attributeName">设置为初始相机:</div>
            <div class="initialCamera">
              <el-checkbox v-model="nowSelectData.m_initialCamera" @change="setInitial()"></el-checkbox>
            </div>
          </div>

          <!-- <div class="BackgroundTemplateBtn">

          <div class="myAffirm" @click="confirm()">设置背景模板</div>

        </div> -->

          <div class="myAffirmAttribute">
            <div class="myAffirm" @click="confirm()">确认修改</div>
          </div>
        </div>
      </div>
    </div>
    <div class="BasicSettingTop">
      <div class="title">
        <div class="titleText">

          投影
        </div>

        <div class="tabList">
          <div class="tabListItem" v-for="item in slideshowList" :key="item.m_id" @click="selectCamera(item)">
            <!---->
            <div :class="{ 'tabListItemText': true, 'optTabListItemText': nowSelectCamera == item.m_id }">
              {{ item.m_name }}
            </div>
          </div>



        </div>
        <!-- <div class="cameraIcon" @click="viewMenuChange()">

          <img src="../../../assets/PlugIn/cloudStore/menu.png" alt="">

          <div class="myMenu" v-show="viewMenu">
            <div class="myMenuItem" @click="setCameraTemplateShow()">设置环境模板</div>
          </div>

          <div class="cameraList" v-show="cameraListShow">
            <div class="cameraListTitle">
              机位列表
              <div class="close">
                <i class="el-icon-close" @click="cameraListShow = false"></i>
              </div>
            </div>

            <div class="cameraConter">
              <div v-for="item in slideshowList" :key='item.m_id'
                :class="{ 'cameraItem': true, 'optCameraItem': nowSelectCamera == item.m_id }"
                @click="selectCamera(item)">
                <img :src="icon" alt="" />
                <span> {{ item.m_name }}</span>
              </div>
            </div>
          </div>
        </div> -->
      </div>



      <div class="topContent">
        <!-- <div class="slideshowLeft" @click="slideshowLeft()">

          <i class="el-icon-arrow-left"></i>

        </div> -->
        <div class="slideshowContent" ref="slideshowContent">
          <div class="slideshowInterior" ref="slideshowInterior">

            <!-- @click="selectCamera(item)" -->
            <div v-for="(i, index) in nowSelectData.m_img" :key="index" :class="{ 'slideshowItem': true }">
              <div :class="{ 'showImg': true, 'selectSlideshowItem': true }">

                <img v-show="i != ''" class="slideshowImg" :src="i | filtrationImg" alt="">

                <div class="displace" v-show="i != '' && index == 0" @click="setImg()">替换图片</div>
              </div>
              <div v-show="i == '' && index == 0" class="uploading" @click="setImg()">
                <i class="el-icon-plus"></i>
              </div>
              <!-- <div class="cameraName">
                {{ item.m_name }}
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="slideshowRight" @click="slideshowRight()">
          <i class="el-icon-arrow-right"></i>

        </div> -->
      </div>
    </div>
    <el-dialog :visible="cameraTemplateShow" width="50%" center :modal="false" title="" append-to-body
      :before-close="cameraTemplateClose" top="15vh">
      <div class="storeType">
        店铺类型：
        <el-select v-model="shopType" placeholder="请选择" @change="getstoreEnvironmentList()">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="templateList">
        <div @click="setbackgroundUrl(item)"
          :class="{ 'templateItem': true, }"
          v-for="(item, index) in storeEnvironmentList  " :key='index'>
          <div :class="{ 'selectTemplateItem': backgroundId == item.id}" >
          <div class="itemTop">
            <img :src="item.image" alt="" />
          </div>
          <div class="templateName">{{ item.name }}</div>

        </div>
        </div>
      </div>

      <div class="templateBtn" @click="cameraTemplateOk()">确认</div>
    </el-dialog>
    <input type="file" value="选择图片" ref="file" multiple @change="fileChange($event)" accept="image/*"
      style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
  </div>
</template>
  
<script>
import icon from "../../../assets/PlugIn/cloudStore/basics.png";
import commodity from "../../../assets/PlugIn/cloudStore/commodity.png";
import { OssManager } from "../../../threejs/network/OssManager";

import { MainObjects } from "../../../threejs/common/MainObjects";

import { INetwork } from "../../../threejs/interface/INetwork"
import { ILoader, IAnimation, IScene, IRender, ITween, ISetting, ICameraControls } from "yi_creator_runtime";
import { ICloudSores } from "../CloudStores/cloudSoresThree/ICloudSores"

import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"




import CameraAttribute from "../../../assets/PlugIn/cloudStore/CameraAttribute.png";

import character from "../../../assets/PlugIn/cloudStore/character.png";
import selectCharacter from "../../../assets/PlugIn/cloudStore/selectCharacter.png";


import selectCameraAttribute from "../../../assets/PlugIn/cloudStore/selectCameraAttribute.png";

import background from "../../../assets/PlugIn/cloudStore/background.png";

import backgroundSelect from "../../../assets/PlugIn/cloudStore/backgroundSelect.png";




// import { sphereToCub } from "../../../util/s2c"

import { sphereToCub } from "@/util/s2c";

// import { config } from 'vue/types/umd';


export default {
  data() {
    return {
      // slideshowList: [
      // ],
      options: [

        {
          value: "1",
          label: "类型1",
        },
        {
          value: "2",
          label: "类型二",
        },
        {
          value: "3",
          label: "类型三",
        },
        {
          value: "4",
          label: "钝角",
        },
      ],
      value: "",
      checked: "",
      icon: icon,
      commodity: commodity,
      rot1: 0,
      rot2: 0,
      rot3: 0,
      //视角菜单
      viewMenu: false,
      //相机列表显示
      cameraListShow: false,
      // 相机模板显示
      cameraTemplateShow: false,

      // 当前选中id
      nowSelectCamera: "",
      nowSelectData: {},
      plain: 1,

      control: null,
      LoadingP: 0,
      shopType: '',
      storeEnvironmentList: [],
      backgroundId: "",
      backgroundUrl: "",
      angleValue: 1,
      distance: 1,
      tabImgList: [
        { img: CameraAttribute, title: '相机', selecImg: selectCameraAttribute },

        { img: character, title: '标签', selecImg: selectCharacter },

        { img: background, title: '背景', selecImg: backgroundSelect },
      ],
      currentType: 0,

      far: 50,
      near: 45
    };
  },
  filters: {
    filtrationImg: function (value) {
      if (value != null && value != "" && value != undefined) {
        var realUrl = INetwork.getUrl(value);

        return realUrl;
      } else {
        return "";
      }
    },
  },
  props: ["slideshowList"],
  mounted() {

    console.log("走到这里了");

    // this.$refs.slideshowInterior.style.left=0

    let len = this.slideshowList.length;
    let num = len / 3;
    let myNum = Math.ceil(num);

    // this.$refs.slideshowInterior.style.width = 440 * 3 * myNum + "px";



    window.addEventListener("click", e => {
      // this.viewMenu=false

    });
    // console.log(this.slideshowList, "this.slideshowList");

    var timeOut = null
    timeOut = setInterval(() => {
      // console.log(this.slideshowList, "this.slideshowList");
      if (this.slideshowList.length > 0) {
        this.nowSelectCamera = this.slideshowList[0].m_id
        this.nowSelectData = this.slideshowList[0]
        if (this.slideshowList[0].m_img[0] != "") {
          this.selectCamera(this.nowSelectData)

        }

        clearTimeout(timeOut)
      }
    }, 200);



    // console.log(this.nowSelectData, "this.nowSelectData");

    setTimeout(() => {
      this.init()

    }, 0);
    this.storePropertyList()
  },

  // watch: {
  //   //方法1
  //   "something"(newVal, oldVal) {
  //     console.log(`新值：${newVal}`);
  //     console.log(`旧值：${oldVal}`);
  //     console.log("hellow  world");

  //   },



  methods: {
    propertyChange(index) {
      this.currentType = index;
    },




    changeAngle() {

      if (ICloudSores.m_cloudSores.m_poc) {

        if (ICloudSores.m_cloudSores.m_poc.m_sphere) {
          ICloudSores.m_cloudSores.m_poc.m_sphere.rotation.set(0, Math.PI * this.angleValue / 360, 0)


        }
      }


    },
    setbackgroundUrl(item) {
      this.backgroundId = item.id
      this.backgroundUrl = item.image
    },


    async getstoreEnvironmentList() {

      let data = {
        storeProperty: this.shopType
      }
      const res = await this.$https.storeEnvironmentList(data)
      if (res.success == true) {

        this.storeEnvironmentList = res.data
      }
    },

    // 获取类型
    async storePropertyList() {
      let data = {}
      const res = await this.$https.storePropertyList(data)

      if (res.success == true) {
        console.log("获取成功", res.data);

        let data = res.data;
        let sectionList = [];

        for (let i in data) {
          let obg = {
            value: i,
            label: data[i]
          };
          sectionList.push(obg);
        }
        sectionList.push({
          value: "",
          label: "全部",
        })


        this.options = sectionList;

        // console.log(sectionList, "this.sectionList");
      }

    },


    fovChange() {

      if (this.LoadingP > 0) {
        console.log(this.nowSelectData, "this.nowSelectData");
        this.setThreeCamera(this.nowSelectData)

        ICloudSores.m_cloudSores.setCameraData(this.nowSelectCamera, this.nowSelectData)


      }


    },

    setThreeCamera(data) {

      let nowCamera = IScene.getCurrentCamera()
      // let container = document.getElementById("runtime_container");
      let nowSelectData = data
      // console.log(nowSelectData,"nowSelectData");
      let control = this.control.m_controls
      let pi = Math.PI
      // control.minPolarAngle = (pi / 180) * nowSelectData.m_vertical;
      // control.maxPolarAngle = (pi / 180) * nowSelectData.m_vertical;
      // control.minAzimuthAngle = (pi / 180) * nowSelectData.m_horizontal;
      // control.maxAzimuthAngle = (pi / 180) * nowSelectData.m_horizontal;
      // control.enableRotate = false; //禁止旋转
      // control.enablePan = false;//禁止平移
      // control.enableZoom = false;//禁止缩放
      // control.update()
      nowCamera.rotateX((pi / 180) * nowSelectData.m_vertical)
      nowCamera.rotateY((pi / 180) * nowSelectData.m_horizontal)
      nowCamera.position.set(nowSelectData.m_pos.x, nowSelectData.m_pos.y, nowSelectData.m_pos.z)
      // console.log(nowSelectData.m_vertical, " nowSelectData.m_vertical");
      nowCamera.fov = nowSelectData.m_FOV
      nowCamera.far=nowSelectData.m_Far
      nowCamera.near=nowSelectData.m_Near

      nowCamera.updateProjectionMatrix()

    },
    init() {

      var _this = this
      ISetting.turnOffStats(false);

      var option = {};
      // option.container = document.getElementById("runtime_container");

      option.container = this.$refs.runtime_container
      // console.log(option.container.offsetWidth, "option.container")

      IRender.initRender(option);
      // let control = ICameraControls.createCameraOrbite(true);
      let control = ICameraControls.createFirstPersonControl()
      this.control = control
      var camera = IScene.getCurrentCamera();
      camera.far = 10000000
      camera.updateProjectionMatrix();
      IRender.setToneMapping(THREE.NoToneMapping)

      const loading = this.$loading({
        lock: true,
        text: this.LoadingP + '%',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      // _this.$parent.ICloudSoresThree.setCubeList(function (p) {
      ICloudSores.m_cloudSores.setCubeList(function (p) {


        _this.LoadingP = p

      }, function () {
        loading.close();
      });


      // console.log(this.control,"this.control");



      // this.setThreeCamera(this.nowSelectData)
      // _this.$parent.ICloudSoresThree.initProjectCameraImage()


      // for (let data of _this.$parent.ICloudSoresThree.m_CubeList) {
      //   IScene.addToScene(data.cube)

      // }
      // IScene.addToScene(_this.$parent.ICloudSoresThree.m_projectMesh)


    },
    selectCamera(item) {
      this.nowSelectCamera = item.m_id;
      this.nowSelectData = item;
      this.angleValue = ICloudSores.m_cloudSores.m_angleValue
      this.distance = ICloudSores.m_cloudSores.m_distance
      // this.setThreeCamera(this.nowSelectData)

      // let index = this.$parent.ICloudSoresThree.m_cameraDataList.indexOf(item)
      let index = ICloudSores.m_cloudSores.m_cameraDataList.indexOf(item)




      if (item.m_img[0] != "") {
        console.log("click---------------------------------------------")
        // this.$parent.ICloudSoresThree.initProjectCameraImage("point" + index)
        ICloudSores.m_cloudSores.initProjectCameraImage("point" + index, index)
      }
    },
    setImg() {
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    // 上传图片
    fileChange(e) {
      var _this = this;
      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;
      const file = e.target.files[0];
      const options = {}
      options.cubeRotation = 0
      options.interpolation = ['lanczos', 'cubic', 'linear'][0]

      let format = file.name.substring(file.name.lastIndexOf(".") + 1)
      console.log(format, "format");
      // options.format = ['jpg', 'png'][1]
      options.format = format



      const loading = this.$loading({
        lock: true,
        text: '正在解析您的图片请不要关闭页面',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      sphereToCub(file, (res) => {
        // console.log(res, '1')
      }, (res) => {
        console.log(res, '2')
        loading.close();

        let files = []

        res.forEach(element => {
          files.push(element.file)
        })

        if (files.length == 6) {

          // console.log("files", files);
          let Verify = true
          // let nameVerify = {
          //   "px.jpg": true,
          //   "nx.jpg": true,
          //   "py.jpg": true,
          //   "ny.jpg": true,
          //   "pz.jpg": true,
          //   "nz.jpg": true,
          // }
          // files.forEach((element) => {
          //   if (!nameVerify[element.name]) {
          //     Verify = false
          //   }
          // })
          if (Verify) {
            let data = new OssManager(MainObjects.Network.m_bucketName);
            let time = Date.now()
            // https +
            let url = data.getCloudShopFloder("viewingAngle" + time, _this.nowSelectCamera);
            // console.log(url, "url");
            function uploadFinish(list) {
              console.log(list, "list");
              let imgList = ["", "", "", "", "", ""]
              let sequenceList = {
                "px": 0,
                "nx": 1,
                "py": 2,
                "ny": 3,
                "pz": 4,
                "nz": 5,
              }
              list.forEach(element => {
                // imgList.push(element.url.replace(/(\?|#)[^'"]*/, ''))
                let url = element.name

                console.log("element", element);
                // dev/CloudShop/ff55d1a9-e33d-4d1e-a913-af034a385638/viewingAngle/pz.jpg
                let sequence = url.split("/")[url.split("/").length - 1].split(".")[0]
                console.log(url, "url");
                imgList[sequenceList[sequence]] = element.name
                // imgList.push(element.name)
              });
              // _this.nowSelectData.m_img = list[0].url.replace(/(\?|#)[^'"]*/, '');
              let nowSelectData = _this.nowSelectData
              let data = {
                m_FOV: nowSelectData.m_FOV,
                m_vertical: nowSelectData.m_vertical,
                m_horizontal: nowSelectData.m_horizontal,
                m_Near: nowSelectData.m_Near,
                m_Far: nowSelectData.m_Far,

   
                m_pos: {
                  x: nowSelectData.m_pos.x,
                  y: nowSelectData.m_pos.y,
                  z: nowSelectData.m_pos.z,
                },
                m_img: imgList,
              }
              _this.nowSelectData.m_img = imgList;
              // _this.$parent.ICloudSoresThree.setCameraData(this.nowSelectCamera, data)


              ICloudSores.m_cloudSores.setCameraData(_this.nowSelectCamera, data)
              _this.$parent.getData()
              setTimeout(function () {
                _this.selectCamera(_this.nowSelectData)
                console.log("time out---------------------------------------------")
              }, 500);
              console.log("finish---------------------------------------------")
            }

            function process(p) {
              console.log(p, "pppppppp")
            }

            data.f_progressCallback = process
            data.f_finishCallback = uploadFinish;
            data.uploadFileList(files, url);

          } else {
            this.$message("上传的图片名字不正确")

          }

        } else {
          this.$message("请选择6张图片，提示按住‘ctrl’可进行多选")
        }
      }, options)

    },
    // 设置初始相机 
    setInitial() {
      this.$confirm('您确定要将摄像机设置为初始相机吗？请注意，一旦确定用户登录店铺链接后，首次进入时就会出现在初始位置，店铺尽可有一个初始相机', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$parent.ICloudSoresThree.setInitialCamera(this.nowSelectCamera)


        ICloudSores.m_cloudSores.setInitialCamera(this.nowSelectCamera)

        this.$parent.getData()
        this.$message({
          type: 'success',
          message: '成功!'
        });
      }).catch(() => {
        this.nowSelectData.m_initialCamera = false
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    confirm() {
      this.$confirm('此操作将修改数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$parent.ICloudSoresThree.setCameraData(this.nowSelectCamera, this.nowSelectData)
        ICloudSores.m_cloudSores.m_angleValue = this.angleValue

        ICloudSores.m_cloudSores.m_distance = this.distance
        ICloudSores.m_cloudSores.setCameraData(this.nowSelectCamera, this.nowSelectData)
        this.$parent.getData()
        this.$message({
          type: 'success',
          message: '成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });



    },



    setCameraTemplateShow() {
      this.cameraTemplateShow = true;

      this.viewMenu = false;
      this.getstoreEnvironmentList()
    },
    cameraTemplateClose() {
      this.cameraTemplateShow = false;
      this.viewMenu = false;
    },

    cameraTemplateOk() {
      this.cameraTemplateShow = false;
      this.viewMenu = false;
      // ICloudSores.m_cloudSores.m_backgroundUrl = this.backgroundUrl
      ICloudSores.m_cloudSores.m_backgroundUrl = 'https://file.yicreator.com/' + this.backgroundUrl.substring(this.backgroundUrl.lastIndexOf("com/") + 4)

      console.log(ICloudSores.m_cloudSores.m_backgroundUrl,"ICloudSores.m_cloudSores.m_backgroundUrl");
      // console.log(ICloudSores.m_cloudSores.m_backgroundUrl,"ICloudSores.m_cloudSores.m_backgroundUrl");
      this.selectCamera(this.nowSelectData)



    },

    setCameraListShow() {
      this.cameraListShow = true;
      this.viewMenu = false;
    },








    viewMenuChange() {
      console.log("11111")
      this.viewMenu = !this.viewMenu

    },




    downloadClose() {

    },
    slideshowLeft() {
      let slideshowInterior = this.$refs.slideshowInterior;

      let left = Number(slideshowInterior.style.left.slice(0, -2));
      let len = this.slideshowList.length;

      let num = len / 3;

      let myNum = Math.ceil(num) - 1;
      console.log(myNum, "myNum");

      console.log(left, "left", myNum);

      let moveLeft = Math.ceil(this.$refs.slideshowContent.offsetWidth);



      if (left == 0) {
        slideshowInterior.style.left = -(moveLeft * myNum) + "px";

        // console.log(this.$refs.slideshowContent.offsetWidth, myNum ,"moveLeft  myNum")
      } else {
        slideshowInterior.style.left = left + moveLeft + "px";
      }
    },
    slideshowRight() {
      let slideshowInterior = this.$refs.slideshowInterior;

      let left = Number(slideshowInterior.style.left.slice(0, -2));
      let len = this.slideshowList.length;

      let num = len / 3;

      let myNum = Math.ceil(num) - 1;
      let moveLeft = Math.ceil(this.$refs.slideshowContent.offsetWidth);


      if (left == -moveLeft * myNum) {
        slideshowInterior.style.left = 0 + "px";
      } else {
        slideshowInterior.style.left = left - moveLeft + "px";
      }
    },
  },
};
</script>
  
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  // background-color: antiquewhite;
  overflow: hidden;

  .BasicSettingTop {
    background-color: #fff;
    border-radius: 8px;
    width: 97%;
    height: 25vh;
    margin: 0 auto;
    margin-top: 10px;
    overflow: hidden;
    border-top: 1px solid #fff;


    .title {
      width: 96%;
      margin: 0 auto;
      margin-top: 15px;
      height: 30px;
      // line-height: 30px;
      font-size: 16px;
      color: #333;
      font-family: PingFang SC;
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      .titleText {
        width: 60px;
        height: 18px;
        line-height: 18px;
        border-left: 3px solid #0560fd;
        margin-top: 5px;
        padding-left: 10px;

      }

      .tabList {
        height: 30px;
        width: 100%;
        overflow: hidden;
        background-color: #F7F7F7;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;


        .tabListItem {
          float: left;
          min-width: 100px;
          // height: 30px;
          line-height: 25px;
          height: 18px;
          font-weight: 500;
          font-size: 15px;
          border-right: 1px solid #CECECE;
          text-align: center;
          padding: 0 10px;
          cursor: pointer;
          margin-top: 5px;

        }

        .tabListItem:last-child {
          border: none;

        }

        .tabListItemText {
          height: 20px;
          line-height: 20px;
          border-radius: 5px;
          // margin-top: 2px;

        }

        .optTabListItemText {
          background-color: #FFFFFF;

        }

      }

      .cameraIcon {
        width: 40px;
        height: 30px;
        margin-right: 10px;
        // margin-top: 5px;
        background-color: #F7F7F7;
        line-height: 30px;
        // background-color: red;
        float: right;

        position: relative;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          margin-top: 5px;
          width: 20px;
          height: 20px;
          background-color: #F7F7F7;

        }

        .myMenu {
          position: absolute;
          width: 158px;
          // height: 79px;
          background: #ffffff;
          border-radius: 8px;
          left: -150px;
          top: 20px;
          z-index: 999;

          .myMenuItem {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
          }
        }

        .cameraList {
          position: absolute;
          left: -350px;
          top: 20px;
          z-index: 9999;
          width: 350px;
          height: 180px;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0px 0px 11px 0px rgba(72, 146, 244, 0.15);

          .cameraListTitle {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #000000;
            line-height: 56px;
            text-align: center;

            .close {
              width: 10px;
              height: 10px;
              position: absolute;
              right: 20px;
              top: 0px;
            }
          }

          .cameraConter::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          /* 滚动条的滑块 */
          .cameraConter::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px #69a0fe;
            // background: #69a0fe;
            border-radius: 3px;
          }

          .cameraConter {
            width: 80%;
            height: 50%;
            margin: 0 auto;
            margin-top: 10px;
            overflow-y: auto;

            .optCameraItem {
              background-color: #F7F7F7;


            }

            .cameraItem {
              width: 100%;
              height: 40px;
              margin-top: 10px;

              line-height: 40px;
              font-weight: 500;
              color: #333333;
              font-size: 16px;
              display: flex;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;

              img {
                width: 25px;
                height: 25px;
                margin: 5px;
              }

              span {
                width: 80%;
                line-height: 32px;
              }
            }
          }
        }
      }
    }

    .topContent::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* 滚动条的滑块 */
    .topContent::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 5px #69a0fe;
      // background: #69a0fe;
      border-radius: 3px;
    }

    .topContent {
      width: 100%;
      height: 85%;

      margin: 0 auto;
      margin-top: 0px;
      // background-color: #0560fd;
      // display: flex;
      // justify-content: space-between;
      overflow-y: auto;

      .slideshowLeft {
        width: 90px;
        height: 100%;
        // background-color: blanchedalmond;
        text-align: center;
        font-size: 28px;
        line-height: 200px;

      }

      .slideshowContent {
        width: 100%;
        height: 150px;
        // overflow: hidden;
        // background-color: darkred;
        // position: relative;
        margin-top: 20px;
        margin-bottom: 20px;


        .cameraName {
          width: 95%;
          height: 20%;
          line-height: 30px;

          // position: absolute;
          bottom: 0;
          margin: 0 auto;
          margin-bottom: 10px;
          text-align: left;

        }


        .slideshowInterior {
          // position: relative;
          height: 75%;
          width: 95%;
          margin: 0 auto;
          left: 0;
          display: flex;
          justify-content: space-between;

          .slideshowItem {
            width: 200px;
            height: 140px;
            border-radius: 8px;
            float: left;
            // margin: 16px;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;

            .showImg {
              width: 100%;
              height: 85%;
              background: #f0f5ff;
              position: relative;

              .slideshowImg {
                width: 100%;
                height: 100%;

              }

              .displace {
                width: 100%;
                height: 25px;
                line-height: 25px;
                // border-radius: 8px;
                position: absolute;
                // left: 8px;
                bottom: 0px;
                text-align: center;
                cursor: pointer;
                background-color: rgba(5, 96, 253, 0.4);
                color: #fff;

              }

            }

            .selectSlideshowItem {
              box-shadow: 0 0 2px 0 rgb(5 96 253 / 50%);
            }

            .uploading {
              height: 50px;
              width: 50px;
              background: #fff;
              border-radius: 50%;
              position: absolute;
              top: 40%;
              left: 50%;
              transform: translate(-50%, -50%);

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 30px;
              }

            }


          }
        }
      }

      .slideshowRight {
        width: 90px;
        height: 100%;
        // background-color: blanchedalmond;
        text-align: center;
        font-size: 28px;
        line-height: 200px;
      }
    }
  }

  .BasicSettingBottom {
    // background-color: #fff;
    // border-radius: 8px;
    width: 97%;
    // height: 300px;
    margin: 0 auto;
    height: 62vh;
    margin-top: 20px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .BasicSettingBottomLeft {
      background-color: #fff;
      border-radius: 8px;
      // border-top-right-radius: 0px;
      // border-bottom-right-radius: 0px;

      width: 67.5%;
      height: 100%;
      border-top: 1px solid #fff;
      position: relative;

      .title {
        width: 96%;
        border-left: 3px solid #0560fd;
        margin: 0 auto;
        margin-top: 20px;
        height: 18px;
        padding-left: 10px;
        line-height: 18px;
        font-size: 16px;
        color: #333;
        font-family: PingFang SC;
        font-weight: bold;
      }
    }



    .BasicSettingBottomRight {
      background-color: #fff;
      border-radius: 8px;
      // border-top-left-radius: 0px;
      // border-bottom-left-radius: 0px;
      width: 32%;
      height: 100%;
      border-top: 1px solid #fff;
      display: flex;

      .rightContent{
        width: 80%;
      }
      .switchover {
        width: 50px;
        height: 100%;
        background-color: #fff;
        position: relative;
        margin-left: 8px;

        .tabTitle {
          margin-top: 8px;
          width: 40px;
          height: 97%;
          position: absolute;
          // left: 20px;
          // top: 30px;
          background: rgba(247, 247, 247, 1);
          z-index: 999;

          .active {
            background-color: #fff;
          }

          .ImgList {
            width: 38px;
            height: 45px;
            margin: 2px auto;
            align-items: center;
            display: flex;
            justify-content: center;
            cursor: pointer;
            margin-left: 2px;

            img {
              width: 15px;
              height: 15px;
            }
          }

          .ImgList:hover {
            background: #cddfff;
          }
        }


      }

      .title {
        width: 90%;
        // border-left: 5px solid #0560fd;
        margin: 0 auto;
        margin-top: 20px;
        height: 20px;
        padding-left: 10px;
        line-height: 20px;
        font-size: 18px;
        color: #333;
        font-family: PingFang SC;
        font-weight: bold;
      }

      .myAffirm {
        width: 200px;
        height: 40px;
        background: #0560fd;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        // margin: 0 auto;
        cursor: pointer;
        // position: absolute;
        top: 10px;
        right: 10px;
        margin: 0 auto;
      }

      .BackgroundTemplateBtn {
        width: 90%;
        height: 60px;

        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        // margin-top: 100px;
      }

      .myAffirmAttribute {
        width: 90%;
        height: 60px;

        margin: 0 auto;
        margin-top: 12px;

        display: flex;
        justify-content: space-between;
        margin-top: 100px;
      }


      .attribute {
        width: 90%;
        height: 60px;

        margin: 0 auto;
        margin-top: 8px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;


        .attributeName {
          width: 160px;

          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 60px;
          margin-right: 10px;
        }

        .fovAttributeName {
          width: 120px;

          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 60px;
          margin-right: 10px;
        }

        .fovAttributeValue {
          width: 400px;
          margin-top: 12px;

          .CameraName {
            line-height: 38px;
          }

          .btn {
            width: 180px;
            height: 40px;
            margin: 0 auto;
            background-color: #0560fd;
            background: #0560fd;
            border-radius: 20px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 40px;
            text-align: center;
            // margin: 0 auto;
            cursor: pointer;
            // position: absolute;
            top: 10px;
            right: 10px;
            margin: 0 auto;



          }
        }

        .attributeValue {
          width: 310px;
          margin-top: 12px;
        }

        .initialCamera {
          width: 310px;
          margin-top: 23px;
        }
      }
    }

    .cameraDisplay {
      width: 53vw;
      height: 52vh;
      background: #f0f5ff;
      border-radius: 8px;
      margin: 20px auto;
    }


  }
}

.templateList::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* 滚动条的滑块 */
.templateList::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
  border-radius: 3px;
}

.templateList {
  width: 100%;
  height: 500px;
  margin-top: 20px;

  overflow-y: auto;

  .selectTemplateItem {
    // box-shadow: 0 0 2px 0 rgb(5 96 253 / 50%);
    box-sizing: content-box;
    padding: 2px;
    border: #0560fd solid 1px;


  }

  .templateItem {
    box-sizing: border-box;

    width: 25%;
    height: 240px;
    background-color: #fff;

    padding: 20px;
    float: left;

    .itemTop {
      width: 100%;
      height: 150px;

      img {
        width: 100%;
        height: 100%;
      }
    }


    .templateName {
      width: 100%;
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 50px;
    }
  }
}

.templateBtn {
  width: 160px;
  height: 40px;
  background: #0560fd;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}</style>