<template>
    <div class="parent">
      <div v-for="(item,index) in list" class="child" :data-id="item.id" :key="index">{{ item.name }}</div>
    </div>
  </template>
  
  <script>
  // import SelectionArea from 'selection-area';
  
  export default {
    name: "selection",
    computed: {
      list() {
        let arr = []
        for(let i =0;i<=10;i++) {
          arr.push({name: `file-${i}`, id: i})
        }
        return arr
      }
    },
    mounted() {
      // let config = {
      //   container: document.querySelector('.parent'),
      //   area: {
      //     class: 'custom-area'
      //   },
      //   targets: '.child',
      //   touchable: true,
      //   autostart: true,
      //   callback: selection => {

      //   //   if (selection.length == 0) alert("empty selection");
      //   //   else alert(`${ selection.length } items selected`);
  
      //     console.log(selection.map(item => item.dataset.id))
      //     document.querySelectorAll('.child').forEach(item => {
      //       item.classList.remove('active')
      //     })
      //     selection.forEach(item => {
      //       item.classList.add('active')
      //     })
      //   }
      // }
  
      // let selectable = new SelectionArea(config);
    }
  }
  </script>
  
  <style scoped>
  body {
    user-select: none;
  }
  .parent {
    width: 100%;
    height: 100vh;
    background-color: aliceblue;
    display: flex;
    gap: 10px;
    padding: 20px;
  }
  .child {
    width: 50px;
    height: 50px;
    background-color: antiquewhite;
  }
  .child.active {
    box-shadow: 0 0 2px 1px rgba(255,0,0,0.4);
  }
  .parent>>>.custom-area {
    background: rgba(52, 152, 219, 0.1);
    border: 1px dotted #2980b9;
  }
  </style>