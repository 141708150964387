<template>
  <div>
    <!-- 上部轮播部分 -->
    <div class="slideshow">
      <div  :class="{'liangztupian':stl&&bannerDate.length==2,'yizhangtupian':!stl&&bannerDate.length==2}">
      <el-carousel v-if="bannerDate.length>1" 	 :interval="5000" height="39.0625vw" @change="carouselChange"  >
        <el-carousel-item v-for="item in bannerDate" :key="item.id">
          <div class="bannerItem">
            <img class="bannerImg" :src="item.image" alt />
            <a href="#" @click="clickBanner(item.id)">

              <div class="useYida">




              </div>
            </a>

          </div>

        </el-carousel-item>
        <div v-if="bannerDate.length==2"  >

        <el-carousel-item   v-for="item in bannerDate" :key="item.id+2"  >
          <div class="bannerItem">
            <img class="bannerImg" :src="item.image" alt />
            <a href="#" @click="clickBanner(item.id)">

              <div class="useYida">




              </div>
            </a>

          </div>

        </el-carousel-item>
      </div>
        


      </el-carousel> 
     </div>


      <img v-if="bannerDate.length==1" class="bannerImg" :src="bannerDate[0].image" alt />



    </div>

    <!-- 中间功能介绍 -->
    <div class="introduce">
      <div class="introduce_title">
        <div class="introduce_title_text">功能介绍</div>
        <div class="introduce_title_box">FUJCTION INTRODUCTION</div>
      </div>
      <!-- unction introduction -->
      <!-- <div class="introduceContent">

        <div class="introduceContent_left">
          <div class="introduceContent_left_sign">
            <img src="../../assets/OfficialWebsite/uploadTheCloud.png" alt />
          </div>
          <div class="word">
            <div class="introduceContent_left_title_text">资源上传云端</div>
            <div class="introduceContent_left_title_title_box">云端储存数据，易于访问到安全、协作、与其他用户共享文件到易于同步，资源共享随时随地办公。</div>
          </div>
        </div>
        <div class="introduceContent_right">
          <div class="introduceContent_right_sign">
            <img src="../../assets/OfficialWebsite/realRendering.png" alt />
          </div>
          <div class="word">
            <div class="introduceContent_right_title_text">场景真实物理渲染</div>
            <div class="introduceContent_right_title_title_box">利用更接近实际物理理论的光照计算，使得呈现的画面更加真实。</div>
          </div>
        </div>
        <div class="introduceContent_left">
          <div class="introduceContent_left_sign">
            <img src="../../assets/OfficialWebsite/ScenarioDuilding.png" alt />
          </div>
          <div class="word">
            <div class="introduceContent_left_title_text">场景快速搭建</div>
            <div class="introduceContent_left_title_title_box">坐标定位，模型吸附，提前预制模型组快速摆放，材质实例化快速修改需要的材质。</div>
          </div>
        </div>
        <div class="introduceContent_right">
          <div class="introduceContent_right_sign">
            <img src="../../assets/OfficialWebsite/easy.png" alt />
          </div>
          <div class="word">
            <div class="introduceContent_right_title_text">简单易学上手快</div>
            <div class="introduceContent_right_title_title_box">操作简单，直观的界面，对于零基础的用户有良好的体验。</div>
          </div>
        </div>
      </div>  -->

      <div class="goleft" v-show="!move" @click="setMove()">
        <img src="../../assets/OfficialWebsite/sectorization/left.png" alt="">
      </div>

      <div class="goReight" v-show="move" @click="setMove()">
        <img src="../../assets/OfficialWebsite/sectorization/right.png" alt="">
      </div>
      <div class="introduceContentNew">
        <div class="rollingRegion" :class="{ moveLeft: move, moveright: !move }">
          <div class="sectorizationItonm" v-for="(item, index) in sectorizationList" :key="index">
            <div class="sectorizationItonmLeft">
              <img :src="item.img" alt="">
            </div>
            <div class="sectorizationItonmReight">
              <div class="sectorizationItonmText">
                {{ item.name }}
              </div>
            </div>
          </div>


        </div>

        <!-- <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">Slide 1</div>
            <div class="swiper-slide">Slide 2</div>
            <div class="swiper-slide">Slide 3</div>
          </div>
        </div> -->



      </div>

    </div>

    <!-- 案例展示 -->
    <div class="caseShow">
      <div class="introduce_title">
        <div class="introduce_title_text">案例展示</div>
        <div class="introduce_title_box">CASE DISPLAY</div>
        <!-- Case display -->
      </div>

      <div class="caseShow_slideshow">

        <!-- <el-carousel indicator-position="outside" :interval="5000" type="card" height="26.04167vw">
          <el-carousel-item v-for="item in CasebannerDate" :key="item.id">
            <a @click="clickBanner(item.id)">
              <img class="bottomCarouselImg" :src="item.image" alt />
            </a>
          </el-carousel-item>
        </el-carousel> -->

        <el-carousel  :interval="5000" type="card" height="26.04167vw">
          <el-carousel-item v-for="item in CasebannerDate" :key="item.id">
            <a @click="clickBanner(item.id)">
              <img class="bottomCarouselImg" :src="item.image" alt />
            </a>

          </el-carousel-item>
        </el-carousel>


      </div>
    </div>
  </div>
</template>

<script>

// import Swiper from 'swiper';
import { Swiper, SwiperSlide } from 'swiper';
import 'swiper/swiper.css';

import AIFindAPath from "../../assets/OfficialWebsite/sectorization/AIFindAPath.png"


import compile from "../../assets/OfficialWebsite/sectorization/compile.png"

import plugIn from "../../assets/OfficialWebsite/sectorization/plugIn.png"
import cartoon from "../../assets/OfficialWebsite/sectorization/cartoon.png"

import script from "../../assets/OfficialWebsite/sectorization/script.png"
import particle from "../../assets/OfficialWebsite/sectorization/particle.png"

import patterning from "../../assets/OfficialWebsite/sectorization/patterning.png"

import physics from "../../assets/OfficialWebsite/sectorization/physics.png"
import project from "../../assets/OfficialWebsite/sectorization/project.png"

import audio from "../../assets/OfficialWebsite/sectorization/audio.png"




export default {
  data() {
    return {
      bannerDate: [],
      CasebannerDate: [],


      sectorizationList: [
        {
          name: "Ai寻找路径",
          img: AIFindAPath
        },
        {
          name: "编辑系统",
          img: compile
        },
        {
          name: "插件系统",
          img: plugIn
        },
        {
          name: "动画系统",
          img: cartoon
        },
        {
          name: "脚本系统",
          img: script
        },
        {
          name: "粒子系统",
          img: particle
        },

        {
          name: "图形系统",
          img: patterning
        },
        {
          name: "物理系统",
          img: physics
        },
        {
          name: "项目管理",
          img: project
        },
        {
          name: "音频系统",
          img: audio
        },
      ],
      move: true,
      stl:true

    }
  },
  created() {

  },
  mounted() {

    const state = { name: "/" }

    history.pushState(state, null, "/");

    this.getbannerDate(1)
    this.getCasebannerDate(3)

    new Swiper('.swiper-container', {
      // Swiper 的配置选项
    });


  },
  methods: {
    carouselChange(data){

           if(data==0||data==1){

            this.stl=true

           }else{

            this.stl=false

           }

    },

    setMove() {
      this.move = !this.move

      console.log(this.move, "this.move")
    },




    // 开始使用
    onBegin() {






      // this.showVideo=true;
      let token = localStorage.getItem("token")

      if (token) {
        this.$router.push({ path: '/index/user/User' })

      } else {
        //  this.$router.push({path:'/login'})

        this.$parent.changelogInShow()

      }


    },



    async clickBanner(id) {


      this.onBegin()


      let data = {
        id: id
      }
      const res = await this.$https.clickBanner(data)
      if (res.success == true) {

      }
    },



    //获取轮播数据
    async getbannerDate(plateId) {
      let data = {
        plateId: plateId
      }
      const res = await this.$https.bannerDate(data)
      if (res.success == true) {
        this.bannerDate = res.data
      }
    },
    //获取案例展示轮播数据
    async getCasebannerDate(plateId) {
      let data = {
        plateId: plateId
      }
      const res = await this.$https.bannerDate(data)
      if (res.success == true) {
        this.CasebannerDate = res.data
      }
    }
  }
};
</script>

<style scoped>
.slideshow {
  margin-top: 60px;
}

/* 上部轮播 */
.slideshow .slideshowBackground {
  display: inline-block;
  width: 100%;
  /* height: 100px; */
  position: absolute;
  bottom: 0;
  z-index: 9;
  /* background: #fff; */
}

.slideshow>>>.el-carousel__indicators--horizontal {
  bottom: 70px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 999;


}

/* 切换按钮左右 */
.slideshow>>>.el-carousel__arrow--left {

  width: 45px;
  height: 45px;
  /* font-size: 30px; */
  color: rgba(0, 0, 0, 0);
  background-image: url("../../assets/OfficialWebsite/sectorization/left.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0);

}

.slideshow>>>.el-carousel__arrow--right {

  width: 45px;
  height: 45px;
  color: rgba(0, 0, 0, 0);
  background-image: url("../../assets/OfficialWebsite/sectorization/right.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0);

}




.slideshow a img {
  /* width: 100%; */
  height: auto;
  /* object-fit: cover; */
}

/* 中间功能介绍部份 */
.introduce {
  width: 100%;
  height: 400px;
  background-color: #ffffff;
  /* background-color: #f1f1f1; */

  display: inline-block;
  position: relative;
}

/* 功能标题 */
.introduce_title {
  width: 320px;
  height: 100px;
  margin: 0 auto;
  margin-top: 0px
}

.introduce_title_text {
  width: 320px;
  height: 40px;
  text-align: center;
  color: #333333;
  font-size: 30px;
  font-family: MicrosoftYaHei;
  font-weight: 600;
  color: #333333;
  line-height: 88px;
}

.introduce_title_box {

  width: 317px;
  height: 19px;
  text-align: center;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B0CBE0;
  line-height: 88px;
}

/* 功能内容部份 */
.introduceContent {
  width: 80%;
  height: 600px;
  margin: 0 auto;
}

.introduceContent .introduceContent_left {
  height: 300px;
  width: 700px;
  /* background: #000; */
  float: left;
}

.introduceContent .introduceContent_left .introduceContent_left_sign {
  width: 300px;
  height: 300px;
  background: url("../../assets/OfficialWebsite/introduce_sign.png");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  position: relative;
  float: left;

}

.introduceContent .introduceContent_left .introduceContent_left_sign img {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.introduceContent .introduceContent_left .word {
  width: 400px;
  height: 300px;
  float: right;

}

.introduceContent .introduceContent_left .word .introduceContent_left_title_text {

  width: 400px;
  height: 88px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
  margin-top: 35px;
  border-bottom: 2px solid #E8E8E8;

}

.introduceContent .introduceContent_left .word .introduceContent_left_title_title_box {

  width: 390px;
  height: 58px;
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 36px;
  margin-top: 30px;
}


/* 右侧 */


.introduceContent .introduceContent_right {
  height: 300px;
  width: 700px;
  /* background: #000; */
  float: right;
}

.introduceContent .introduceContent_right .introduceContent_right_sign {
  width: 300px;
  height: 300px;
  background: url("../../assets/OfficialWebsite/introduce_sign.png");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  position: relative;
  float: right;

}

.introduceContent .introduceContent_right .introduceContent_right_sign img {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.introduceContent .introduceContent_right .word {
  width: 400px;
  height: 300px;
  float: left;

}

.introduceContent .introduceContent_right .word .introduceContent_right_title_text {

  width: 400px;
  height: 88px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
  margin-top: 35px;
  border-bottom: 2px solid #E8E8E8;
  /* text-align: right; */
}

.introduceContent .introduceContent_right .word .introduceContent_right_title_title_box {

  width: 390px;
  height: 58px;
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 36px;
  margin-top: 30px;
}



.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;

}

.el-carousel__item:nth-child(2n) {
  background-color: #fff;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff;
}

.slideshow>>>.el-carousel__indicator--horizontal {
  padding-left: 15px;
  padding-right: 15px;
}

.slideshow>>>.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border: 0px solid #ffffff !important;
  border-radius: 50% !important;
  /* opacity: 0.5; */
  background-color: rgba(214, 214, 214, 1) !important;
}

.slideshow>>>.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: RGBA(17, 104, 253, 1) !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}



/* 案例展示 */
.caseShow {
  width: 100%;
  display: inline-block;
  background: #ffffff;
  height: 700px;
}

.caseShow_slideshow {
  width: 1500px;
  margin: 0 auto;
  margin-top: 30px;
  /* border-radius: 20px; */

}

.caseShow_slideshow .medium {
  border-radius: 20px;
}


.caseShow_slideshow .bottomCarouselImg {
  display: inline-block;
  width: 100%;
  height: 400px;
  border-radius: 20px;



}

.caseShow_slideshow>>>.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border: 0px solid #ffffff !important;
  border-radius: 50% !important;
  /* opacity: 0.5; */
  background-color: rgba(214, 214, 214, 1) !important;
}

.caseShow_slideshow>>>.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: RGBA(17, 104, 253, 1) !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

/* 切换按钮左右 */
.caseShow_slideshow>>>.el-carousel__arrow--left {
  /* width: 100px;
  height: 100px; */
  /* font-size: 30px; */
  color: rgba(0, 0, 0, 0);
  background-image: url("../../assets/OfficialWebsite/sectorization/left.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0);
}

.caseShow_slideshow>>>.el-carousel__arrow--right {
  color: rgba(0, 0, 0, 0);
  background-image: url("../../assets/OfficialWebsite/sectorization/right.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0);

}


.caseShow_slideshow>>>.el-carousel__indicators--outside {
  position: absolute;

  bottom: 40px;
}

.bottom {
  width: 100%;
  height: 300px;
}

.bottom .bottom_choice {
  width: 500px;
  height: 20px;
  /* background: red; */
  margin: 0 auto;
  margin-top: 30px;
}

.bottom .bottom_choice .bottom_choice_one {
  float: left;
  width: 25%;
  height: 24px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
  text-align: center;
}


.bottom .productInformation {
  width: 1600px;
  height: 100px;
  margin: 0 auto;
  margin-top: 20px;
  border-top: 2px solid RGBA(187, 187, 187, 1);
}

.bottom .company {
  margin: 0 auto;
  margin-top: 30px;
  width: 350px;
  height: 12px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 16px;
  text-align: center;

}


.bottom .productInformation .caseNo {
  width: 200px;
  height: 36px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 200;
  color: #666666;
  line-height: 23px;
  margin: 0 auto;
  margin-top: 70px;
}


/* 蒙版 */
.masking {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

/* 视频 */

.teaching {
  width: 1000px;
  /* height: 500px;*/
  background: red;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border-radius: 20px;
}

/* 登录*/
.logIn {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: RGBA(103, 109, 120, 0.5);
}

.logIn .logInContent {
  width: 1000px;
  height: 500px;
  position: absolute;
  background-color: RGBA(255, 255, 255, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;

}

.logIn .logInContent .logInContentLeft {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  background: url(../../assets/Login/background.png);
  background-size: 100%;
  background-repeat: no-repeat;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.logIn .logInContent .logInContentRight {
  position: relative;
  float: right;
  width: 50%;
  height: 100%;
}

.logIn .logInContent .logInContentRight .sign {
  position: absolute;
  top: 14%;
  left: 50%;
  width: 150px;
  height: 100px;
  /* background-color: red; */

  background-image: url("../../assets/Login/icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transform: translate(-50%, -50%);
}

.logIn .logInContent .logInContentRight .loginCenter-right-conter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logIn .logInContent.login-num {
  margin-top: 50px;
  width: 370px;
  margin: 30px auto;

}

.login-num .num /deep/ .el-input__inner {
  height: 60px;
  border-radius: 10px;
}

.logIn .logInContent .login-verifyCode {
  margin-top: 30px;
  width: 370px;
  margin: 30px auto;
  border-radius: 20px;
}

.login-verifyCode /deep/ .el-input__inner {
  height: 60px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.login-verifyCode /deep/ .el-input-group__append {
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}



.login-num>>>.el-input__inner {
  height: 60px;
  border-radius: 10px;
}

.login-verifyCode>>>.el-input__inner {
  height: 60px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.login-verifyCode>>>.el-input-group__append {
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}




.logIn .logInContent .onlogin {
  position: relative;
  margin-top: 50px;
  width: 370px;
  height: 50px;
  background: #0560fd;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.2);
  border-radius: 10px;
  cursor: pointer;
}

.logIn .logInContent .onlogin .onlogin-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 57px;
  height: 27px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}

.bannerImg {
  width: 100%;
}

.bannerItem {
  position: relative;
}

.useYida {
  width: 260px;
  height: 100px;
  background-color: RGBA(195, 211, 240, 0);



  border-radius: 8px;
  position: absolute;
  left: 180px;
  top: 430px;


}
</style>

<style lang="less" scoped>
.goleft {
  width: 35px;
  height: 35px;
  // background-color: beige;
  position: absolute;
  top: 75%;
  left: 220px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  cursor: pointer;
  z-index: 9999;

  img {
    width: 100%;
  }

}

.goReight {
  width: 35px;
  height: 35px;
  // background-color: beige;
  position: absolute;

  top: 75%;
  right: 160px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  z-index: 9999;

  img {
    width: 100%;
  }
}


.introduceContentNew {

  width: 70%;
  height: 400px;
  margin: 0 auto;
  // background-color: red;
  position: relative;
  overflow: hidden;




  .moveLeft {
    left: 10%;

    transition: left 1s;
  }

  .moveright {
    left: -62%;
    transition:  left 1s;
  }



  .rollingRegion {
    width: 167%;
    height: 400px;
    // background-color: blue;
    position: absolute;
    display: flex;
    // left: 0%;

    flex-wrap: wrap;



    .sectorizationItonm {
      width: 20%;
      height: 50%;
      box-sizing: border-box;
      flex-basis: 20%;
      // height: 50px;
      // background-color: #ccc;
      // border: 1px solid #000;
      box-sizing: border-box;
      display: flex;

      .sectorizationItonmLeft {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        /* 水平居中 */
        align-items: center;

        /* 垂直居中 */
        img {
          width: 100px;
          // height: 50px;
        }


      }

      .sectorizationItonmReight {
        font-size: 20px;
        line-height: 100%;
        display: flex;
        justify-content: center;
        /* 水平居中 */
        align-items: center;

        /* 垂直居中 */
        .sectorizationItonmText {
          position: relative;

        }

        .sectorizationItonmText::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: -10px;
          height: 1px;
          width: 150%;
          background-color: #f1f1f1;
        }



      }
    }


    // .rollingRegionTop {
    //   width: 100%;
    //   height: 50%;
    //   display: flex;

    // }
  }




}


.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  // background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  // background-color: #d3dce6;
}


.yizhangtupian{

  /deep/ .el-carousel__indicators{

        &>li:nth-child(1),&>li:nth-child(2){

           display: none;

        }

    }

}




.liangztupian{

    /deep/ .el-carousel__indicators{

        &>li:nth-child(3),&>li:nth-child(4){

           display: none;

        }

    }

}

</style>