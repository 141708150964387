<template>
  
  <div class="box">
    <div class="name">
        店铺名称：<el-input v-model="storeName" placeholder="请输入内容"></el-input>
    </div>
    <div class="type">店铺类型

      <el-select v-model="storeType" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
    </div>
    店铺营业时间

    <div class="time">
      <el-time-select
    placeholder="起始时间"
    v-model="startTime"
   value-format="timestamp"

>
  </el-time-select>
  <el-time-select
    placeholder="结束时间"
    v-model="endTime"
   value-format="timestamp"
    
    >
  </el-time-select>





    </div>
    <div class="location">
        店铺地址

        <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="storeSite">
      </el-input>

    </div>

    
  </div>
</template>

<script>
export default {
  data(){
    return{
      storeName:"",
      storeType:"",
      options: [{
          value: 0,
          label: '中岛店铺'
        }, {
          value: 1,
          label: '边厅店铺'
        }, {
          value: 2,
          label: '街店'
        }, {
          value:3,
          label: '店中店'
        }],
        value: '',
        openingHours:[
          "",""
        ],
        // 地址
        storeSite:"",


        startTime: '',
        endTime: ''
    }
  }

}
</script>

<style lang="less" scoped>
.box{
  width: 80%;
  margin:  0 auto;
  margin-top: 30px;
  .name{
    margin-bottom: 10px;

  }
  .type{
    margin-bottom: 10px;

  }
  .time{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

  }
}

.time /deep/{
  .el-date-editor{
    .el-input{
      width: 146px;
      margin: 0 10px;
    }
  }
  .el-date-editor{
    .el-input__inner {
      width: 146px;
      margin: 0 10px;
    }
  }


} 

.time {
  .el-input__inner {
  width: 240px;
  height: 40px;
}

}
</style>