<template>
  <div class="install">
    <!-- 设置 -->
    <div class="content">
      <div class="set">



        <el-form ref="form" :model="form" label-width="5.20833vw">
          <el-form-item label="端口号">
            <el-input v-model="form.ports"></el-input>
          </el-form-item>
          <el-form-item label="ip">
            <el-input v-model="form.IP"></el-input>
          </el-form-item>
          <el-form-item>

            <div class="formBtn" @click="onSubmit()">
               {{ isLink?' 断 开 ':'连 接' }}
            </div>

            <!-- <el-button type="primary" @click="onSubmit">连接</el-button>
          <el-button type="primary" @click="onSubmit">断开</el-button> -->

          </el-form-item>
        </el-form>
      </div>

    </div>

    <div class="logIn">
      <div class="loginTitle">日志窗口</div>
      <div class="loginContent">
        <p class="logItem">cad>


        </p>

      </div>

    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        // 端口
        ports: "",
        IP: "",
      },
      isLink:false

    }
  },
  methods: {
    onSubmit() {
      this.isLink=!this.isLink
    }
  }
}
</script>
<style lang="less" scoped>
.install {
  width: 100%;
  height: 100%;
  // background-color: #fff;
  overflow: hidden;
  // background-color: #fff;
  // margin: 10px;


  .content {
    width: 90vw;
    height: 200px;
    margin: 10px;
    border-radius: 8px;
    background-color: #fff;
    padding-top: 40px;

    .set {
      width: 500px;

      .formBtn {
        width: 160px;
        height: 40px;
        background: #0076F6;
        border-radius: 10px;
        margin: 0 auto;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .logIn {
    width: 90vw;
    height: 620px;
    margin: 10px;
    background-color: #fff;
    // box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    border-radius: 8px;

    .loginTitle::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 3px;
      background-color: #0560fd;
      margin-right: 5px;
    }

    .loginTitle {
      width: 98%;
      height: 50px;
      line-height: 40px;
      font-size: 16px;
      padding-left: 5px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      font-weight: 700;
      // box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);

    }

    .loginContent::-webkit-scrollbar {
      margin-top: 5px;

      width: 10px;

      height: 10px;
    }

    .loginContent::-webkit-scrollbar-thumb {

      border-radius: 10px;
      box-shadow: inset 0 0 0px #69a0fe;
      background: #69a0fe;
    }

    .loginContent::-webkit-scrollbar-track {

      box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #e9f1fe;
    }

    .loginContent {
      box-sizing: border-box;
      width: 98%;
      height: 88%;
      padding: 10px;
      background-color: #fff;
      color: #000000;
      overflow-y: auto;
      font-size: 16px;
      border: 1px solid #E6EAEB;
      margin: 0 auto;
      margin-top: 5px;
      border-radius: 8px;

      .logItem {
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 100%;
        line-height: 30px;
        letter-spacing: 3px;
        font-size: 14px;
      }
    }



  }
}</style>