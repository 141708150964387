<template>
    <div class="redact">

        <div class="head">
            <div class="headLeft">
                <div class="icon">
                    <img src="../../../assets/PlugIn/cloudStore/icon.png" alt="">

                </div>
                <div class="text">
                    {{ projectName }}
                </div>
            </div>

            <div class="title">
                <div class="titleText">

                    CAD插件
                </div>
            </div>
        </div>
        <div class="content">
            <div class="contentLeft">
                <div class="menuList">
                    <div :class="{ 'pitchOnMenuListItem': item.id == currentFunctionsId, 'menuListItem': true }"
                        v-for="item in menuList" :key="item.id" @click="switchFunction(item.id)">
                        <div :class="{'icon':true,'selectIcon':item.id == currentFunctionsId }" >
                            <img v-if="(item.id == currentFunctionsId)" :src="item.selectImg" alt="">
                            <img v-else :src="item.icon" alt="">
                        </div>
                        <span class="menuListItemTitle">
                            {{ item.name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="contentRight">
                <!-- v-show="currentFunctionsId == 2"  -->
                <Drawing ref="drawing" v-show="currentFunctionsId == 3" :projectId="projectId"></Drawing>

                <Install v-show="currentFunctionsId == 2"></Install>

                <Inventory v-show="currentFunctionsId == 1" :projectId="projectId"></Inventory>
            </div>
        </div>


    </div>
</template>

<script>
import angleOfview from "../../../assets/PlugIn/cad/settingUp.png"
import basics from "../../../assets/PlugIn/cad/goods.png"
import furniture from "../../../assets/PlugIn/cad/blueprint.png"
import selectVisualAngle from "../../../assets/PlugIn/cad/settingUpSelect.png"
import SelectBase from "../../../assets/PlugIn/cad/goodsSelect.png"
import catalogue from "../../../assets/PlugIn/cad/blueprintSelect.png"


import Drawing from "./Drawing"
import Install from "./Install"
import Inventory from "./Inventory"

// import CadTree from "./CadTree.vue"




// angleOf view.png

export default {
    data() {
        return {






            menuList: [

                {
                    id: "1",
                    icon: basics,
                    selectImg: SelectBase,
                    name: "物品分类"
                },
                {
                    id: "2",
                    icon: angleOfview,
                    selectImg: selectVisualAngle,

                    name: "基础设置"
                },
                {
                    id: "3",
                    icon: furniture,
                    selectImg: catalogue,



                    name: "设置图纸"
                },

            ],
            currentFunctionsId: 1,
            // 项目id
            projectId: "",
            projectName: ""

        }
    },
    components: {
        Drawing,
        Install,
        Inventory,
        // CadTree
    },
    mounted() {


    },
    created() {

        document.title = '艺搭';

        this.projectId = this.$route.query.projectId
        this.projectName = this.$route.query.projectName



        console.log(this.projectId, "projectId");

    },
    destroyed() {



    },
    methods: {
        // 切换功能
        switchFunction(id) {
            this.currentFunctionsId = id
        },

    },



}
</script>

<style lang="less" scoped>
.redact {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .head {
        width: 100%;
        height: 5%;
        background: rgba(5, 96, 253, 1);
        position: relative;

        .headLeft {
            width: 300px;
            height: 100%;
            margin-left: 10px;
            // background-color: red;
            // float: left;
            position: absolute;
            left: 0px;
            display: flex;
            align-items: center;

            .icon {
                width: 30px;
                height: 30px;


                img {
                    width: 100%;

                }

            }

            .text {
                width: 200px;
                font-size: 14px;
                margin-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #fff;
                font-weight: 400;
            }
        }

        .title {
            max-width: 200px;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 auto;
            .titleText{
                font-size: 14px;
                margin-left: 10px;
                text-align: center;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #fff;
                font-weight: 400;
            }
        }


    }

    .content {
        width: 100%;
        height: 95%;

        // background-color: #fff;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        .contentLeft {
            width: 190px;
            height: 100%;
            // background-color:rgb(206, 206, 206);
            background-color: #fff;

            .menuList {
                margin-top: 20px;

                .pitchOnMenuListItem {

                    height: 60px;
                    margin-top: 20px;
                    background-color: rgba(230, 239, 255, 1);
                    // box-shadow: 1px 1px 6px 1px rgba(33, 35, 49, 0.1);
                    text-align: center;
                    cursor: pointer;
                    border-left: 5px solid #0560FD;

                    color: #0560FD;


                }

                .menuListItem {
                    height: 60px;
                    margin-top: 20px;

                    // box-shadow: 1px 1px 6px 1px rgba(33, 35, 49, 0.1);
                    text-align: center;
                    cursor: pointer;


  
                    .icon {
                        float: left;
                        height: 50px;
                        text-align: center;
                        line-height: 70px;
                        margin-left: 20px;

                        img {
                            width: 20px;
                        }
                    }
                    .selectIcon{
                        float: left;
                        height: 50px;
                        text-align: center;
                        line-height: 70px;
                        margin-left: 15px;
                    }
                    .menuListItemTitle {
                        float: left;
                        line-height: 60px;
                        margin-left: 20px;

                    }
                }
            }
        }

        .contentRight {

            height: 100%;
            width: 100%;



        }


    }

}

</style>