<template>
  <div class="box">
    <!-- <div id="top"></div> -->

    <div class="top">
      <div class="top_content">
        <!-- t图标 -->
        <div class="logo"><img src="../../assets/OfficialWebsite/icon.png" alt=""></div>

        <!-- 导航 -->
        <div class="navigation">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#333333"
            active-text-color="#1568FB" :router="true">
            <el-menu-item index="/OfficialWebsite/HomePage/HomePageContent">首页</el-menu-item>
            <el-menu-item index="/OfficialWebsite/HomePage/introduce">功能介绍</el-menu-item>
            <el-menu-item index="/OfficialWebsite/HomePage/case">案例展示</el-menu-item>
            <!-- <el-menu-item index="/OfficialWebsite/HomePage/LearningCommunity">学习社区</el-menu-item> -->
            <!-- <el-menu-item index="/OfficialWebsite/HomePage/OfficialResources">官方资源</el-menu-item> -->
            <!-- <el-menu-item index="/OfficialWebsite/HomePage/LearningVideoList">教学视频</el-menu-item> -->
            <el-menu-item index="/OfficialWebsite/HomePage/document">使用文档</el-menu-item>



            <!--  -->

            <el-menu-item index="/OfficialWebsite/HomePage/ourselves">关于我们</el-menu-item>


          </el-menu>
        </div>
        <div class="smallMenu" @click="MenuSmallChange">
          <div class="smallMenuStart">
            <img class="smallMenuImg" v-show="MenuSmall" src="../../assets/OfficialWebsite/menu.png" alt="">
            <img class="smallMenuImg" v-show="!MenuSmall" src="../../assets/OfficialWebsite/menuClose.png" alt="">

            <div class="smallMenuList" v-show="!MenuSmall">
              <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#333333"
                active-text-color="#1568FB" :router="true">
                <el-menu-item index="/OfficialWebsite/HomePage/HomePageContent">首页展示 </el-menu-item>
                <el-menu-item index="/OfficialWebsite/HomePage/introduce">功能介绍</el-menu-item>
                <el-menu-item index="/OfficialWebsite/HomePage/case">案例展示</el-menu-item>
                <!-- <el-menu-item index="/OfficialWebsite/HomePage/LearningCommunity">学习社区</el-menu-item> -->
                <!-- <el-menu-item index="/OfficialWebsite/HomePage/OfficialResources">官方资源</el-menu-item>  -->
                <!-- <el-menu-item index="/OfficialWebsite/HomePage/LearningVideoList">教学视频</el-menu-item> -->
                <el-menu-item index="/OfficialWebsite/HomePage/document">使用文档</el-menu-item>



                <!--  -->

                <el-menu-item index="/OfficialWebsite/HomePage/ourselves">关于我们</el-menu-item>


              </el-menu>

            </div>
          </div>
        </div>
        <!-- 开始使用 -->
        




        <!-- 用户信息 -->
        <div class="userinfo" v-if="alreadyLogIn" @click.stop="onUser">
          <div class="headPortrait">
            <img :src="headPortrait" alt="">
          </div>
          <div class="userName">
            {{ userName }}
          </div>

          <div class="secede" v-show="secedeShow" @click.stop="onSecede">
            <i class="secedeIcon"></i> 退出登录
          </div>
        </div>
        <div class="loginOrRegister" v-if="!alreadyLogIn">
          <div class="enroll" @click="changelogInShow">
            登录
          </div>
          |
          <div class="register" @click="changelogInShow">
            注册
          </div>


        </div>

        <div class="comeIntoUse" @click="onBegin()">
          <div class="start">
            进入引擎
          </div>
        </div>

      </div>
    </div>

    <!-- <HomePageContent  ></HomePageContent> -->

    <router-view />


    <div class="bottom">
      <div class="bottom_choice">

        <el-tooltip placement="top">
          <div slot="content"><img class="WXGZH" src="../../assets/OfficialWebsite/WXGZH.jpg" alt=""></div>
          <div class="bottom_choice_one bottom_choice_one2">
            联系我们
          </div>
        </el-tooltip>



        <router-link :to="{ path: '/OfficialWebsite/HomePage/ourselves' }">
          <div class="bottom_choice_one" @click="goTop">


            关于我们

          </div>
        </router-link>
        <div class="bottom_choice_one" @click="forMe">
          用户协议
        </div>
        <div class="bottom_choice_one">
          隐私条款
        </div>
      </div>

      <div class="productInformation">

        <!-- <p class="company">版权所有：北京艺搭科技有限责任公司</p> -->

        <div class="copyrightPis">
          <div class="caseNo">
            Copyright © 2023 北京艺搭科技有限责任公司 版权所有

          </div>

          <div class="caseNo">
            联系邮箱: yida@yicreator.com
          </div>
          <a style="text-decoration: none;" href="http://beian.miit.gov.cn?spm=a213l2.13146415.0.0.36b771e1CQZ57r"
            target="_blank">
            <div class="caseNo">
              <img src="../../assets/OfficialWebsite/jbah.gif" alt=""> 京ICP备2022033211号-3

            </div>

          </a>

          <a style="text-decoration: none;"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010602104732" target="_blank">
            <div class="caseNo caseNo2">
              <img src="../../assets/OfficialWebsite/beiantubiao.png" alt=""> 京公网安备 11010602104732号

            </div>

          </a>
        </div>
        <!-- <div style="width:19.53125vw;margin:0 auto; padding:1.30208vw 0;">
         <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010602104732" style="display:inline-block;text-decoration:none;height:1.30208vw;line-height:1.30208vw;"><img src="" style="float:left;"/><p style="float:left;height:1.30208vw;line-height:1.30208vw;margin: undefined undefined undefined 0.32552vw; color:#939393;">  <img src="../../assets/OfficialWebsite/jbah.gif" alt="">京公网安备 11010602104732号</p></a>
       </div> -->


      </div>
    </div>



    <div class="teaching" v-if="showVideo">
      <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
        :options="playerOptions"></video-player>
    </div>

    <div class="masking" @click="hideVideo" v-if="showVideo">

    </div>

    <div class="logIn" v-if="logInShow" @click="hideLogInSho">

      <div class="logInContent" @click.stop="">
        <div class="logInContentLeft">

        </div>
        <div class="logInContentRight">

          <div class="sign">

          </div>
          <div class="loginCenter-right-conter">


            <p class=" login-num">
            <section class="num">
              <el-input v-model="loginAccount" maxlength="11" placeholder="请输入手机号"></el-input>
            </section>
            </p>
            <div class="inputstyle">
              <p class=" login-verifyCode">
              <section class="loginCode">
                <el-input v-model="verifyCode" maxlength="6" placeholder="请输入验证码">
                  <el-button slot="append" v-if="codeShow" @click="getCode">获取验证码</el-button>
                  <template slot="append" v-else>{{ count }}s</template>

                </el-input>

              </section>



              </p>
            </div>

            <div class="onlogin" @click="loginClick" ref="login">
              <div class="onlogin-text"> 登录</div>

            </div>

          </div>

        </div>

      </div>



    </div>




  </div>
</template>

<script>
import utils from "../../method/anti-shake";

import HomePageContent from "./HomePageContent";

export default {


  data() {
    return {

      // 已登录？
      alreadyLogIn: false,

      //导航栏
      activeIndex: '/OfficialWebsite/HomePage/HomePageContent',
      // 登录显示
      logInShow: false,


      //手机号
      loginAccount: "",
      //验证码
      verifyCode: "",
      //获取验证码
      codeShow: true,

      //用户名
      userName: "",
      //头像
      headPortrait: "",
      count: 60,
      //推出登录
      secedeShow: false,

      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "",
          src: 'http://www.html5videoplayer.net/videos/madagascar3.mp4'//url地址
        }],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,//当前时间和持续时间的分隔符
          durationDisplay: true,//显示持续时间
          remainingTimeDisplay: false,//是否显示剩余时间功能
          fullscreenToggle: true  //全屏按钮
        }
      },

      //   视频显示隐藏
      showVideo: false,

      MenuSmall: true,
    }
  },
  components: {

    HomePageContent

  },
  watch: {
    $route() {
      this.setCurrentRoute();
    }
  },


  mounted() {
    this.initialize()
    document.onclick = () => {
      this.secedeShow = false;
    };
    document.title = '艺搭官网';

    this.setCurrentRoute()
    this.getOSSToken()

  },


  methods: {

    MenuSmallChange() {
      this.MenuSmall = !this.MenuSmall
    },

    goTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    setCurrentRoute() {
      this.activeIndex = this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单


    },



    forMe() {
      this.activeIndex = "/OfficialWebsite/HomePage/ourselves"
    },
    //退出登录
    async logoutArgument() {
      let phone = localStorage.getItem("phone");

      const res = await this.$https.logout({
        phone: phone
      });

      if (res.success == true) {
        // this.$message({
        //   showClose: true,
        //   message: res.errMessage,
        //   type: "success"
        // });
        //标识为退出登录
        this.alreadyLogIn = false


        //标识名字为空
        this.userName = ""
        this.headPortrait = ""

        localStorage.clear(); //清楚缓存addMemberPage

      } else if (res.errCode == "411") {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning"
        });
        //标识为退出登录
        this.alreadyLogIn = false


        //标识名字为空
        this.userName = ""
        this.headPortrait = ""
      } else if (res.errCode == "412") {
        this.$message({
          message: res.errMessage,
          // type: "warning"
        });
        //标识为退出登录
        this.alreadyLogIn = false
        //标识名字为空
        this.userName = ""
        this.headPortrait = ""

      } else if (res.errCode == "500") {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning"
        });
      }
    },



    //退出登录
    onSecede() {


      this.secedeShow = false;
      // 执行退出
      this.logoutArgument();

    },

    // 点击用户
    onUser() {
      this.secedeShow = !this.secedeShow

    },
    //隐藏退出
    hideSeced() {
      this.secedeShow = false
    },


    //判断当前是否登录
    initialize() {
      var token = localStorage.getItem("token")

      if (token) {
        // 状态改为一登陆
        this.alreadyLogIn = true
        //头像
        this.headPortrait = localStorage.getItem("image")
        this.userName = localStorage.getItem("userName")


      } else {
        this.alreadyLogIn = false
      }


    },

    //隐藏视频
    hideVideo() {
      this.showVideo = false;
    },
    // 开始使用
    onBegin() {






      // this.showVideo=true;
      let token = localStorage.getItem("token")

      if (token) {
        this.$router.push({ path: '/index/user/User' })

      } else {
        //  this.$router.push({path:'/login'})

        this.changelogInShow()

      }


    },

    hideLogInSho() {
      this.logInShow = false
      // this.loginAccount=""
      // this.verifyCode=""
    },


    //让登录显示
    changelogInShow() {

      this.loginAccount = ""
      this.verifyCode = ""
      this.logInShow = true;

    },
    //点击获取验证码
    getCode() {
      if (this.loginAccount == "") {
        this.$message({
          showClose: true,
          message: "手机号码为空",
          // type: "warning",
        });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        // this.colors = { borderColor: "#ff0000" };
        // this.modalNumShow = !this.modalNumShow;
        // this.modalNum = "请填写正确手机号";
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else {
        this.codeShow = false;
        const TIME_COUNT = 60;
        this.phoneArgument();
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeShow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count = this.count - 1;

            } else {
              this.codeShow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },

    //   验证码获取
    async phoneArgument() {
      const res = await this.$https.getPhoneVerificationCode({
        phone: this.loginAccount,
      });
      var cotionCode = res.errCode;
      if (cotionCode == null) {
        this.$message({
          showClose: true,
          message: "获取验证码成功！",
          // type: "success",
        });
      } else if (cotionCode == 401) {
        // this.colors = { borderColor: "#ff0000" };
        // this.modalNumShow = !this.modalNumShow;
        // this.modalNum = "手机号格式错误,请填写正确手机号！";
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning",
        });
        return false;
      }
    },

    //登录校验
    loginClick: utils.debounce(function () {

      // for test
      var _this = this;
      //  localStorage.setItem('token','eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIwYzhhODdkMy1mNjE4LTQxMmItYmE4OC0wOWExMjIyMyIsIm5pY2tOYW1lIjoi5r2Y56eL5aicIiwic2NvcGUiOlsidGVzdCJdLCJpZCI6IjBjOGE4N2QzLWY2MTgtNDEyYi1iYTg4LTA5YTEyMjIzIiwiZXhwIjoxNTczMTkyMzg5LCJhdXRob3JpdGllcyI6IlJPTEVfMTU2OTY1MzA5MTg4OSxST0xFX0FVVEhPUklUWV9NQU5BR0VfUEFHRSxST0xFX0FVVEhPUklUWV9NT0RVTEVfVklFVyxST0xFX0RFQUxfUk9MRSxST0xFX0RFQUxfTUVNQkVSLFJPTEVfMTU2OTY1MzA5MTg4OSxST0xFX01BTkFHRV9VU0VSX1BBR0UsUk9MRV9VU0VSX01PRFVMRV9WSUVXIiwianRpIjoiNDA4OGY4MDMtYmQ5Zi00ODM0LTgxNmItM2RmODA2YzA1ZTE2IiwiY2xpZW50X2lkIjoiY2xpZW50MSJ9.P-lJTTrTv5wjX52adSAynYCZxuPbCUIPTtMHtU8xWv8ZLmJTkM03wMgm_hmL7xZHRolM1RAXvLkAUL7GXDhP8Twt2bMScTi1b3DVd8d7Z5zjH_2k4IxKGGY1IyidTHaEn1FImM3XRrAqixFT-leAFWjoRSP1WvUgZ-qGs1Vio5D-vD-a5rPBfMshGBgYqSKtrMvBtpHZ_Aut_KfNDepdt-FNYJvhGoyoAfpe6T0J02reCdnGR9jxtGLZywEFzvJd8qf0yp7jsFZ7vVZQs_fMiH7p_AKd85suVAz_mAt5aqUPV16Z-cVud323J4ue03y-8TOQHkyNdVjHB5BW-qcGYw')
      if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        this.colors = { borderColor: "#ebeef3" };
        this.modalNumShow = !this.modalNumShow;
        this.modalNum = "请填写正确手机号";
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else if (this.loginAccount == "") {
        this.$message({
          showClose: true,
          message: "请填写手机号",
          // type: "warning",
        });
        return false;
      } else if (this.verifyCode == "") {
        this.$message({
          showClose: true,
          message: "请填写验证码",
          // type: "warning",
        });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        this.$message({
          showClose: true,
          message: "请填写手机号",
          // type: "warning",
        });
        return false;
      } else if (this.privacyPolicy == false) {
        this.$message({
          showClose: true,
          message: "请勾选用户协议和隐私协议",
          // type: "warning",
        });
        return false;
      } else {
        this.loginArgument();
      }
    }),


    // 获取oss临时Token
    getOSSToken() {
      this.$https.getOSSToken({
        sessionName: "yida"
      }).then((res) => {
        if (res.success == true) {
          var OssTokenObg = {}

          OssTokenObg.accessKeyId = res.data.accessKeyId
          OssTokenObg.accessKeySecret = res.data.accessKeySecret
          OssTokenObg.stsToken = res.data.securityToken


          localStorage.setItem("OssTokenObg", JSON.stringify(OssTokenObg))


        } else {
          console.error("获取临时ossToken失败");
        }

      })
    },

    //登录接口
    async loginArgument() {
      var _this = this;
      const res = await this.$https.phoneLogin({
        phone: this.loginAccount,
        code: this.verifyCode,
      });

      var code = res.errCode;
      var outCode = res.success;
      if (outCode == true) {
        if (code === null) {
          this.$message({
            showClose: true,
            message: "登录成功",
            // type: "success",
          });
          // if (res.data.access_token != null) {

          if (res.success == true) {
            // var gainTokoen = res.data.access_token; //获取token
            var gainTokoen = res.data.token; //获取token
            localStorage.setItem("token", gainTokoen);
            var loginAccount = this.loginAccount;
            localStorage.setItem("phone", loginAccount);
            var groupName = res.data.groupName;
            localStorage.setItem("groupName", groupName);

            localStorage.setItem("teamId", res.data.groupId)
            //存储默认【团队id的权限
            localStorage.setItem("teamLevel", res.data.level)

            var userName = res.data.userName;

            this.userName = userName
            localStorage.setItem("userName", userName);

            //存储头像
            var image = res.data.image;
            this.headPortrait = image
            localStorage.setItem("image", image)






            //存储默认【团队id的权限
            localStorage.setItem("teamLevel", res.data.level)

            //存储userSig
            localStorage.setItem("userSig", res.data.userSig)

            this.$tim.login({ userID: loginAccount, userSig: res.data.userSig });

            this.$store.commit("changeUserRank", res.data.level)


            this.getOSSToken()


            this.logInShow = false
            this.alreadyLogIn = true



          } else if (code === "410") {
            this.$message({
              showClose: true,
              message: res.errMessage,
              // type: "warning",
            });
          }
        } else if (outCode == false) {
        }
      } else if (outCode == false) {
        if (code === "400") {
          // this.colorCode = { borderColor: "#ff0000" };
          // this.modalCodeShow = true;
          // this.modalCode = "请输入验证码";
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });

          return false;
        } else if (code === "401") {
          this.colorCode = { borderColor: "#ff0000" };
          this.modalCodeShow = true;
          this.modalCode = "您输入的验证码不正确!";
          return false;
        } else if (code === "402") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "403") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "405") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "406") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "407") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "408") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "409") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "500") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        }
      }
    },



  }
};
</script>

<style scoped>
.box {
  background-color: #fff;
}

.top {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  /* opacity: 0.8; */
  z-index: 999999999;

  /* border-bottom: 1px solid #4788F9; */

    box-shadow: 0 2px 4px 0 hsla(0,0%,75%,.2);

}

.top .top_content {
  width: 75%;
  /* height: 60px; */
  height: 100%;
  margin: 0 auto;

  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top .top_content .logo {
  float: left;
  width: 100px;
  height: 60px;
  /* margin-top: 10px; */
}

.top .top_content .logo img {
  width: 100%;

}

.top .top_content .navigation {
  float: left;
  margin-left: 30px;
  width: 800px;
  /* margin-top: 10px; */

}


.navigation .el-menu-item:hover {
  color: #cfe1ff !important;
}

.navigation .el-submenu__title:hover {
  color: #cfe1ff !important;
}




/* 取消导航的底边框 */
.navigation>>>.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.navigation>>>.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none !important;
  font-weight: bold;
}

.navigation>>>.el-menu--horizontal>.el-menu-item {
  border-bottom: none !important;
  padding: 0 30px;
}



/*  用户部分*/

.top .top_content .userinfo {
  /* width: 140px; */
  height: 100%;
  margin: auto 10px;
  float: right;
  /* margin-top: 25px; */
  position: relative;
  display: flex;
  align-items: center;

}

.top .top_content .userinfo .headPortrait {
  width: 25px;
  height: 25px;
  float: left;

  margin-left: 20px;
  border-radius: 50%;
  border: 1px solid #1168FD;
  padding: 2px;
  margin-right: 10px;
  line-height: 25px;
  margin-top: 3px;

}

.top .top_content .userinfo .headPortrait img {
  width: 25px;
  height: 25px;
  /* margin:  auto auto; */
  border-radius: 50%;

}


.top .top_content .userinfo .userName {
  max-width: 100px;
  height: 50px;
  overflow: hidden;
  line-height: 55px;
  text-align: left;
  color: #666666;
  font-size: 15px;

}

.top .top_content .userinfo .secede {
  width: 150px;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 1px rgba(33, 35, 49, 0.1);
  text-align: center;
  line-height: 40px;
  position: absolute;
  border-radius: 15px;
  top: 60px;
  left: 20px;
  z-index: 999;
  cursor: pointer;

}

.top .top_content .userinfo .secede .secedeIcon {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url("../../assets/OfficialWebsite/secede.png");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 3px;

}




/* 登录注册部分 */
.top .top_content .loginOrRegister {
  width: 110px;
  height: 40px;
  margin: auto 10px;
  float: right;
  margin-top: 10px;
  line-height: 40px;
  margin-top: 25px;
  margin-right: 10px;
}

.top .top_content .loginOrRegister .enroll:hover {
  color: #cfe1ff;

}

.top .top_content .loginOrRegister .register:hover {
  color: #cfe1ff;

}


.top .top_content .loginOrRegister .enroll {
  width: 50px;
  height: 40px;
  text-align: center;
  float: left;
  line-height: 40px;
  cursor: pointer;
}

.top .top_content .loginOrRegister .register {
  width: 50px;
  height: 40px;
  text-align: center;
  float: right;
  line-height: 40px;
  cursor: pointer;

}


/* 右侧开始使用部分 */
.top .top_content .comeIntoUse {
  width: 100px;
  /* height: 60px; */
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
}

.top .top_content .comeIntoUse .start {
  width: 200px;
  height: 26px;
  /* margin-top: 30px; */
  border-radius: 20px;
  background-color: RGBA(195, 211, 240, 1);
  color: #1168FD;
  text-align: center;
  line-height: 26px;
  border: 1px solid #4788F9;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
}


/* 上部轮播 */
.slideshow .slideshowBackground {
  display: inline-block;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  z-index: 9;
}

.slideshow>>>.el-carousel__indicators--horizontal {
  bottom: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 999;

}


/* 中间功能介绍部份 */
.introduce {
  width: 100%;
  height: 750px;
  background-color: #ffffff;
  display: inline-block;
}

/* 功能标题 */
.introduce_title {
  width: 320px;
  height: 100px;
  margin: 0 auto;
  margin-top: 50px
}

.introduce_title_text {
  width: 320px;
  height: 40px;
  text-align: center;
  color: #333333;
  font-size: 36px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #333333;
  line-height: 88px;

}

.introduce_title_box {

  width: 317px;
  height: 19px;
  text-align: center;

  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B0CBE0;
  line-height: 88px;
}

/* 功能内容部份 */
.introduceContent {
  width: 80%;
  height: 600px;
  /* background: red; */
  margin: 0 auto;
}

.introduceContent .introduceContent_left {
  height: 300px;
  width: 700px;
  /* background: #000; */
  float: left;
}

.introduceContent .introduceContent_left .introduceContent_left_sign {
  width: 300px;
  height: 300px;
  background: url("../../assets/OfficialWebsite/introduce_sign.png");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  position: relative;
  float: left;

}

.introduceContent .introduceContent_left .introduceContent_left_sign img {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.introduceContent .introduceContent_left .word {
  width: 400px;
  height: 300px;
  float: right;

}

.introduceContent .introduceContent_left .word .introduceContent_left_title_text {

  width: 400px;
  height: 88px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
  margin-top: 35px;
  border-bottom: 2px solid #E8E8E8;

}

.introduceContent .introduceContent_left .word .introduceContent_left_title_title_box {

  width: 390px;
  height: 58px;
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 36px;
  margin-top: 30px;
}


/* 右侧 */


.introduceContent .introduceContent_right {
  height: 300px;
  width: 700px;
  /* background: #000; */
  float: right;
}

.introduceContent .introduceContent_right .introduceContent_right_sign {
  width: 300px;
  height: 300px;
  background: url("../../assets/OfficialWebsite/introduce_sign.png");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  position: relative;
  float: right;

}

.introduceContent .introduceContent_right .introduceContent_right_sign img {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.introduceContent .introduceContent_right .word {
  width: 400px;
  height: 300px;
  float: left;

}

.introduceContent .introduceContent_right .word .introduceContent_right_title_text {

  width: 400px;
  height: 88px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
  margin-top: 35px;
  border-bottom: 2px solid #E8E8E8;
  /* text-align: right; */
}

.introduceContent .introduceContent_right .word .introduceContent_right_title_title_box {

  width: 390px;
  height: 58px;
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 36px;
  margin-top: 30px;
}



.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}




/* 案例展示 */
.caseShow {
  width: 100%;
  display: inline-block;
  background: #ffffff;
  height: 600px;
}

.caseShow_slideshow {
  width: 1500px;
  margin: 0 auto;
  margin-top: 30px;
}

.caseShow_slideshow .medium {
  border-radius: 20px;
}


.caseShow_slideshow .bottomCarouselImg {
  display: inline-block;
  width: 100%;
  border-radius: 20px;

}

.caseShow_slideshow>>>.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  /* border: 1px solid #ffffff; */
  border-radius: 50%;
  /* opacity: 0.5; */
  background-color: rgba(214, 214, 214, 1);
}

.caseShow_slideshow>>>.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: RGBA(17, 104, 253, 1);
  border-radius: 50%;
  opacity: 1;

}



.bottom {
  width: 100%;
  height: 240px;
  background-color: RGBA(249, 249, 249, 1);
  padding-top: 50px;

}

.bottom .bottom_choice {
  width: 600px;
  height: 20px;
  /* background: red; */
  margin: 0 auto;
  margin-top: 30px;
}

.bottom .bottom_choice .bottom_choice_one {
  float: left;
  width: 25%;
  height: 24px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  position: relative;


}

.bottom_choice .bottom_choice_one::after {
  content: "";
  height: 20px;
  width: 1px;
  background: #000000;
  opacity: 0.1;
  position: absolute;
  left: 0;
  margin-top: 2px;
  /* top: 50%; */
  /* transform: translate(-50%,-50%); */
}

.bottom_choice .bottom_choice_one2::after {
  content: "";
  height: 0px;
  width: 0px;

}



.bottom .productInformation {
  width: 1600px;
  height: 100px;
  margin: 0 auto;
  margin-top: 20px;
  border-top: 2px solid RGBA(187, 187, 187, 1);
}

.bottom .company {
  margin: 0 auto;
  margin-top: 30px;
  width: 350px;
  height: 12px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 16px;
  text-align: left;


}

.copyrightPis {
  width: 1050px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.bottom .productInformation .caseNo {
  /* width: 300px; */
  height: 36px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 200;
  color: #666666;
  line-height: 23px;
  /* margin: 0 auto; */
  /* margin-top: 40px; */
}

.bottom .productInformation .caseNo img {
  height: 24px;
  vertical-align: top;
}

.bottom .productInformation .caseNo2 {
  /* margin-top: 10px; */
}






/* 蒙版 */
.masking {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

/* 视频 */

.teaching {
  width: 1000px;
  /* height: 500px;*/
  background: red;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border-radius: 20px;
}

/* 登录*/
.logIn {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: RGBA(103, 109, 120, 0.5);
}

.logIn .logInContent {
  width: 1000px;
  height: 500px;
  position: absolute;
  background-color: RGBA(255, 255, 255, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;

}

.logIn .logInContent .logInContentLeft {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  background: url(../../assets/Login/background.png);
  background-size: 100%;
  background-repeat: no-repeat;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.logIn .logInContent .logInContentRight {
  position: relative;
  float: right;
  width: 50%;
  height: 100%;
}

.logIn .logInContent .logInContentRight .sign {
  position: absolute;
  top: 14%;
  left: 50%;
  width: 150px;
  height: 100px;
  /* background-color: red; */

  background-image: url("../../assets/Login/icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transform: translate(-50%, -50%);
}

.logIn .logInContent .logInContentRight .loginCenter-right-conter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logIn .logInContent.login-num {
  margin-top: 50px;
  width: 370px;
  margin: 30px auto;

}

.login-num .num /deep/ .el-input__inner {
  height: 60px;
  border-radius: 10px;
}

.logIn .logInContent .login-verifyCode {
  margin-top: 30px;
  width: 370px;
  margin: 30px auto;
  border-radius: 20px;
}

.login-verifyCode /deep/ .el-input__inner {
  height: 60px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.login-verifyCode /deep/ .el-input-group__append {
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}



.login-num>>>.el-input__inner {
  height: 60px;
  border-radius: 10px;
}

.login-verifyCode>>>.el-input__inner {
  height: 60px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.login-verifyCode>>>.el-input-group__append {
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}




.logIn .logInContent .onlogin {
  position: relative;
  margin-top: 50px;
  width: 370px;
  height: 50px;
  background: #0560fd;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.2);
  border-radius: 10px;
  cursor: pointer;
}

.logIn .logInContent .onlogin .onlogin-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 57px;
  height: 27px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}

.WXGZH {
  width: 100px;
}



.smallMenu {
  width: 50px;
  /* height: 60px; */
  float: right;
  margin: 0;
  margin-left: 10px;
  display: none;

}

.smallMenuStart {
  height: 26px;
  margin-top: 30px;
  /* border-radius: 20px; */
  /* background-color: RGBA(195, 211, 240, 1); */
  /* color: #1168FD ; */
  /* text-align: center; */
  line-height: 30px;
  /* border: 2px solid #4788F9; */
  cursor: pointer;
  position: relative;
}

.smallMenuImg {
  width: 25px;
}

.smallMenuList {
  position: absolute;
  left: -25px;

}


@media only screen and (max-width: 1500px) {
  .smallMenu {
    display: block;
  }

  .navigation {
    display: none;
  }

}
</style>