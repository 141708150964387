<template>
    <div>
        <h1>服务条款</h1>
    
    </div>
</template>
<script>
export default {
    name:"serve",
    data(){
        return{
  
        }
    },
    methods:{
    }
}
</script>
<style scoped>

</style>