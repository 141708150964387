<template>
  <div>
    <!-- 上部轮播部分 -->
    <div class="slideshow">
      <el-carousel :interval="5000" height="39.0625vw">
        <el-carousel-item v-for="item in bannerDate" :key="item.id">
          <div class="bannerItem">


            <!-- <h3> -->
            <img :src="item.image" alt />

            <!-- </h3> -->



            <a href="#" @click="clickBanner(item.id)">

              <div class="useYida">


                <!-- 使用宜搭引擎 -->



              </div>
            </a>



          </div>

        </el-carousel-item>
        <!-- <img class="slideshowBackground" src="../../assets/OfficialWebsite/slideshowBackground.png" alt /> -->
      </el-carousel>
    </div>
    <!-- 学习社区 -->
    <div class="learningCommunity">
      <div class="introduce_title">
        <div class="introduce_title_text">学习社区</div>
        <div class="introduce_title_box">LEARNING COMMUNITY</div>
      </div>
      <!-- Learning community -->
      <div class="learningContent">
        <div class="learningContentOne">
          <!-- @mouseenter="hoverItem.city=true"  -->

          <div class="learningContentItem" @mouseenter="setCity()" @mouseleave="hoverItem.city = false"
            @click="learning(1)">
            <div v-show="!hoverItem.city">
              <div class="itemIcon">
                <img src="../../assets/OfficialWebsite/city.png" alt />
              </div>

              <div class="itemText">城市搭建</div>
            </div>

            <div v-show="hoverItem.city">
              <div class="itemIconHover">
                <img src="../../assets/OfficialWebsite/cityHover.png" alt />
              </div>

              <div class="itemTextHover">视频教学， {{ subjectNum }}个视频 • {{ sumPlayCount }}次播放</div>
            </div>
          </div>
          <!-- @mouseenter="hoverItem.shop=true"  -->
          <div class="learningContentItem" @mouseenter="setShop()" @mouseleave="hoverItem.shop = false"
            @click="learning(2)">
            <div v-show="!hoverItem.shop">
              <div class="itemIcon">
                <img src="../../assets/OfficialWebsite/shop.png" alt />
              </div>

              <div class="itemText">店铺搭建</div>
            </div>

            <div v-show="hoverItem.shop">
              <div class="itemIconHover">
                <img src="../../assets/OfficialWebsite/shopHover.png" alt />
              </div>

              <div class="itemTextHover">视频教学， {{ subjectNum }}个视频 • {{ sumPlayCount }}次播放</div>
            </div>
          </div>
          <!-- @mouseenter="hoverItem.animationTrack=true"  -->

          <div class="learningContentItem" @mouseenter="setAnimationTrack()"
            @mouseleave="hoverItem.animationTrack = false" @click="learning(3)">
            <div v-show="!hoverItem.animationTrack">
              <div class="itemIcon">
                <img src="../../assets/OfficialWebsite/animationTrack.png" alt />
              </div>

              <div class="itemText">动画轨迹</div>
            </div>
            <div v-show="hoverItem.animationTrack">
              <div class="itemIconHover">
                <img src="../../assets/OfficialWebsite/animationTrackHover.png" alt />
              </div>

              <div class="itemTextHover">视频教学， {{ subjectNum }}个视频 • {{ sumPlayCount }}次播放</div>
            </div>
          </div>
        </div>
        <!-- hoverItem.SceneGame=true -->
        <div class="learningContentOne">
          <div class="learningContentItem" @mouseenter="setSceneGame()" @mouseleave="hoverItem.SceneGame = false"
            @click="learning(4)">
            <div v-show="!hoverItem.SceneGame">
              <div class="itemIcon">
                <img src="../../assets/OfficialWebsite/SceneGame.png" alt />
              </div>

              <div class="itemText">场景游戏</div>
            </div>
            <div v-show="hoverItem.SceneGame">
              <div class="itemIconHover">
                <img src="../../assets/OfficialWebsite/SceneGameHover.png" alt />
              </div>

              <div class="itemTextHover">视频教学， {{ subjectNum }}个视频 • {{ sumPlayCount }}次播放</div>

            </div>
          </div>
          <!-- hoverItem.characterAnimation=true -->
          <div class="learningContentItem" @mouseenter="setCharacterAnimation()"
            @mouseleave="hoverItem.characterAnimation = false" @click="learning(5)">

            <div v-show="!hoverItem.characterAnimation">
              <div class="itemIcon">
                <img src="../../assets/OfficialWebsite/characterAnimation.png" alt />
              </div>

              <div class="itemText">人物动画</div>
            </div>

            <div v-show="hoverItem.characterAnimation">
              <div class="itemIconHover">
                <img src="../../assets/OfficialWebsite/characterAnimationHover.png" alt />
              </div>

              <div class="itemTextHover">视频教学， {{ subjectNum }}个视频 • {{ sumPlayCount }}次播放</div>

            </div>
          </div>
          <!-- hoverItem.DIYEditor=true -->
          <div class="learningContentItem" @mouseenter="setDIYEditor()" @mouseleave="hoverItem.DIYEditor = false"
            @click="learning(6)">
            <div v-show="!hoverItem.DIYEditor">
              <div class="itemIcon">
                <img src="../../assets/OfficialWebsite/DIYEditor.png" alt />
              </div>

              <div class="itemText">DIY编辑器</div>
            </div>
            <div v-show="hoverItem.DIYEditor">
              <div class="itemIconHover">
                <img src="../../assets/OfficialWebsite/DIYEditorHover.png" alt />
              </div>

              <div class="itemTextHover">视频教学， {{ subjectNum }}个视频 • {{ sumPlayCount }}次播放</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoverItem: {
        //城市
        city: false,
        //商城
        shop: false,
        //动画轨迹
        animationTrack: false,
        //场景游戏
        SceneGame: false,
        //城市搭建
        characterAnimation: false,
        //DIY编辑器
        DIYEditor: false,

      },
      bannerDate: [],
      //人数
      sumPlayCount: null,
      //视频数
      subjectNum: null,
    }
  },
  mounted() {
    this.getbannerDate(1)

  },
  methods: {

    learning(id) {
      // this.$router.push({path:'/OfficialWebsite/HomePage/LearningVideoList'})

      // this.$router.push({ path: '/OfficialWebsite/HomePage/LearningVideoList', query: { id: id }})

      let routeUrl = this.$router.resolve({
        path: "/OfficialWebsite/HomePage/LearningVideoList",
        query: { id: id }
      });
      window.open(routeUrl.href, '_blank');


    },

    // 开始使用
    onBegin() {






      // this.showVideo=true;
      let token = localStorage.getItem("token")

      if (token) {
        this.$router.push({ path: '/index/user/User' })

      } else {
        //  this.$router.push({path:'/login'})

        this.$parent.changelogInShow()

      }


    },



    async clickBanner(id) {


      this.onBegin()


      let data = {
        id: id
      }
      const res = await this.$https.clickBanner(data)
      if (res.success == true) {

      }
    },
    //获取轮播数据
    async getbannerDate(plateId) {
      let data = {
        plateId: plateId
      }
      const res = await this.$https.bannerDate(data)
      if (res.success == true) {
        this.bannerDate = res.data
      }
    },
    setCity() {
      this.hoverItem.city = true
      this.getWebsite(1, 1)
    },
    setShop() {
      this.hoverItem.shop = true
      this.getWebsite(1, 2)
    },
    setAnimationTrack() {
      this.hoverItem.animationTrack = true
      this.getWebsite(1, 3)

    },
    setSceneGame() {
      this.hoverItem.SceneGame = true
      this.getWebsite(1, 4)
    },

    setCharacterAnimation() {
      this.hoverItem.characterAnimation = true
      this.getWebsite(1, 5)
    },
    setDIYEditor() {
      this.hoverItem.DIYEditor = true
      this.getWebsite(1, 6)

    },


    //获取主题信息
    async getWebsite(plateId, subjectId) {
      let data = {
        plateId:
          plateId,
        subjectId: subjectId
      };
      const res = await this.$https.subjectInformation(data);
      if (res.success == true) {
        this.subjectNum = res.data.subjectNum
        this.sumPlayCount = res.data.sumPlayCount
      }

    }
  }
};
</script>

<style  scoped>
/* 上部轮播 */

.slideshow {
  margin-top: 80px;
}

.slideshow .slideshowBackground {
  display: inline-block;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  z-index: 9;

}

.slideshow>>>.el-carousel__indicators--horizontal {
  bottom: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 999;
}

.slideshow a img {
  /* width: 100%; */
  height: auto;
  object-fit: cover;
}

.slideshow>>>.el-carousel__indicator--horizontal {
  padding-left: 15px;
  padding-right: 15px;
}

.slideshow>>>.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border: 0px solid #ffffff !important;
  border-radius: 50% !important;
  /* opacity: 0.5; */
  background-color: rgba(214, 214, 214, 1) !important;
}

.slideshow>>>.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: RGBA(17, 104, 253, 1) !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}







/* 学习社区 */
.learningCommunity {
  width: 100%;
  height: 900px;
  background: #ffffff;
}

.learningCommunity .introduce_title {
  width: 320px;
  /* height: 100px; */
  margin: 0 auto;
  /* margin-top: 50px; */
}

.learningCommunity .introduce_title_text {
  width: 320px;
  /* height: 40px; */
  text-align: center;
  color: #333333;
  font-size: 36px;
  font-family: MicrosoftYaHei;
  font-weight: 600;
  color: #333333;
  line-height: 36px;
}

.learningCommunity .introduce_title_box {
  width: 317px;
  /* height: 19px; */
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b0cbe0;
  line-height: 30px;
}

.learningCommunity .learningContent {
  width: 70%;
  height: 680px;
  /* background-color: blueviolet; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 68px;
}

.learningContent .learningContentOne {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.learningContentItem {
  width: 400px;
  height: 300px;
  background-color: #fff;
  box-shadow: 0px 0px 9px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  cursor: pointer;
}

.learningContent .learningContentItem .itemIcon {
  height: 210px;
  width: 100%;
  background: #fff;
  line-height: 300px;
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.learningContent .learningContentItem .itemIconHover {
  height: 210px;
  width: 100%;
  background: rgba(94, 177, 254, 1);
  line-height: 300px;
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.learningContent .learningContentItem .itemIconHover img {
  width: 103px;
  /* height: 90px; */
}

.learningContent .learningContentItem .itemTextHover {
  width: 100%;
  /* height: 28px; */
  font-size: 22px;

  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1568fb;
  line-height: 88px;
  text-align: center;
}

.learningContent .learningContentItem .itemIcon img {
  width: 103px;
  /* height: 90px; */
}

.itemText {
  width: 100%;
  /* height: 28px; */
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
  text-align: center;
}

.bannerItem {
  position: relative;
}

.useYida {
  width: 260px;
  height: 100px;
  background-color: RGBA(195, 211, 240, 0);



  border-radius: 8px;
  position: absolute;
  left: 180px;
  top: 430px;


}
</style>