<template>
  <div class="box">
    <!-- <div class="BasicSettingTop">
      <div class="title">店铺归属：{{$parent.$refs.BasicSetting.vestIn}}</div>

      <div class="topContent">
        <div class="topContentTop">
          <div class="name">
            <div class="lable">店铺名称:</div>
            <div class="value">{{$parent.$refs.BasicSetting.shopName}}</div>
          </div>
          <div class="type">
            <div class="lable">店铺类型: </div>

            <div class="value">
            <span v-for="item in options" :key="item.value" > 
              
              
              <span  v-if='item.value== $parent.$refs.BasicSetting.shopType' >

                {{ item.label }}

              </span>  
            
            </span>
            </div>
          </div>
          <div class="location">
            <div class="lable">店铺地址:</div>
            <div class="value">
              {{$parent.$refs.BasicSetting.address}}
            </div>
          </div>
                  <div class="topContentBottom">
          <div class="lable">店铺介绍:</div>
          <div class="value">{{$parent.$refs.BasicSetting.introduce}}</div>
        </div>
        </div>

      </div>
    </div> -->
    <div class="BasicSettingBottom">
      <div class="title">店铺模型列表</div>

      <div class="modelList">
        <div class="modelItem" v-for=" item in modelDataList" :key="item.m_id" @contextmenu.prevent="show(item)">
          <!-- <img :src="commodity" alt="" /> -->
          <img src="../../../assets/PlugIn/cloudStore/icon_model.png" alt="" />

          <!-- <img :src="item.m_img|filtrationImg" alt="" /> -->



          
          <div class="particulars">
            <div class="modelName">{{ item.m_name }}</div>
            <div class="modelTime">2022.02.03.14:00</div>
          </div>

          <div class="expression">
            <img v-show="item.m_type===2" src="../../../assets/PlugIn/cloudStore/furnitureIcon.png" class="expressionImg" />
            <img v-show="item.m_type===1" src="../../../assets/PlugIn/cloudStore/commodity.png" class="expressionImg" />
            <img v-show="item.m_type===0" src="../../../assets/PlugIn/cloudStore/icon_model.png" class="expressionImg" />

          </div>
        </div>

      </div>
    </div>

    <div class="myMenu">
      <div v-show="menu">
        <el-menu id="menu" @select="handleSelect" text-color="#000" class="menu" ref="menu">
          <el-menu-item index="1" class="menuItem">
            <span slot="title"> 设置为【家具】 </span>
          </el-menu-item>

          <el-menu-item index="2" class="menuItem">
            <span slot="title"> 设置为【商品】 </span>
          </el-menu-item>
          <el-menu-item index="3" class="menuItem">
            <span slot="title"> 取消标记</span>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <el-dialog :visible="setFurnitureShow" width="25%" center :modal="false" title="设置为家具" append-to-body
      :before-close="setFurnitureClose" top="10vh">
      <el-form ref="form" :model="form" label-width="5.20833vw" label-position="top">
        <el-form-item label="商品外显名称：" >
          <el-input v-model="furnitureData.explicitName"></el-input>
        </el-form-item>
        <el-form-item  label="编辑家具参数：">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="grid-content bg-purple ">
                长：<el-input-number class="furnitureParameter" :controls="false" v-model="furnitureData.len"></el-input-number></div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple ">
                宽：<el-input-number class="furnitureParameter"  :controls="false" v-model="furnitureData.breadth"></el-input-number></div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple ">
                高：<el-input-number class="furnitureParameter"  :controls="false" v-model="furnitureData.altitude"></el-input-number></div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="编辑家具描述" label-width="6.51042vw" label-position="top">
          <el-input type="textarea" placeholder="请输入内容" v-model="furnitureData.describe"  maxlength="100" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="上传商品示意图" label-width="6.51042vw">
          <div class="upload" @click="setFurnitureImg()">添加示意图</div>
          <div class="uploadImg">
            <img class="furnitureImg" v-show="furnitureData.showImg" :src="furnitureData.showImg" alt="">
            <div class="change" v-show="furnitureData.showImg" @click="setFurnitureImg()">更换 </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="templateBtn" @click="setFurnitureData()">确认</div>
    </el-dialog>



    <el-dialog :visible="setCommodityShow" width="25%" center :modal="false" title="设置为商品" append-to-body
      :before-close="setCommodityClose" top="3vh">
      <el-form ref="form" :model="form" label-width="5.20833vw" label-position="top">
        <el-form-item label="商品外显名称：" label-width="6.51042vw">
          <el-input v-model="commodityData.showName"></el-input>
        </el-form-item>
        <el-form-item label="编辑官方指导价：" label-width="6.51042vw">
          <el-input-number :controls="false" v-model="commodityData.guidingPrice"></el-input-number>
        </el-form-item>
        <el-form-item label="编辑商品描述" label-width="6.51042vw" label-position="top">
          <el-input type="textarea" placeholder="请输入内容" v-model="commodityData.describe"  maxlength="100" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="上传商品外显示图" label-width="6.51042vw">
          <div class="upload" @click="setCommodityDataImg()">添加示意图</div>
          <div class="uploadImg">
            <img v-show="commodityData.showImg" :src="commodityData.showImg" alt="">
            <div v-show="commodityData.showImg" class="change" @click="setCommodityDataImg()">更换 </div>
          </div>
        </el-form-item>
        <el-form-item label="电商跳转url" label-width="6.51042vw">
          <el-input v-model="commodityData.goToUrl"></el-input>
        </el-form-item>


      </el-form>
      <div class="templateBtn" @click="setCommodityData()">确认</div>
    </el-dialog>

    <input type="file" value="选择图片" ref="file" @change="fileChange($event)" accept="image/*"
      style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />

      
    <input type="file" value="选择图片" ref="file2" @change="fileChange2($event)" accept="image/*"
      style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
  </div>
</template>
  
<script>
import icon from "../../../assets/PlugIn/cloudStore/basics.png";
import commodity from "../../../assets/PlugIn/cloudStore/commodity.png";
import { OssManager } from "../../../threejs/network/OssManager";

import { MainObjects } from "../../../threejs/common/MainObjects";

import { INetwork } from "../../../threejs/interface/INetwork";
import { ICloudSores } from "../CloudStores/cloudSoresThree/ICloudSores"


export default {
  data() {
    return {
      form: {
        name: "",
        textarea: "",
        length: "",
        width: "",
        high: "",
      },
      value: "",
      checked: "",
      menu: "",
      options: [
        {
          value: "0",
          label: "中岛店铺",
        },
        {
          value: "1",
          label: "边厅店铺",
        },
        {
          value: "2",
          label: "店中店",
        },
        {
          value: "3",
          label: "街店",
        },
      ],
      node: {},
      commodity: commodity,
      setFurnitureShow: false,
      setCommodityShow: false,
      furnitureData: {},
      commodityData: {},

    };
  },
  props: ["modelDataList"],
  mounted() {
    window.addEventListener("click", (e) => {
      if (this.menu == true) {
      }
      this.menu = false;
    });

    console.log(this.modelDataList,"modelDataList");
  },
  filters:{

    ///图标过滤器
    filtrationImg: function (value) {
      if (value != null && value != "" && value != undefined) {
        var realUrl = INetwork.getUrl(value);

        return realUrl;
      } else {
        return "";
      }
    },
  },

  methods: {
    setFurnitureImg() {
      this.$refs.file.click();
    },
    fileChange(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;
      const files = e.target.files;

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("furniture", this.node.id);
      function uploadFinish(list) {

        _this.furnitureData.showImg = list[0].url.replace(/(\?|#)[^'"]*/, '');
        ICloudSores.m_cloudSores.setFurnitureData(this.node.id, this.furnitureData)
        _this.$parent.getData()


      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url);
    },
    setCommodityDataImg() {
      this.$refs.file2.click();
    },
    fileChange2(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;
      const files = e.target.files;

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("commodity", this.node.id);
      function uploadFinish(list) {

        _this.commodityData.showImg = list[0].url.replace(/(\?|#)[^'"]*/, '');
        ICloudSores.m_cloudSores.setCommodityData(this.node.id, this.commodityData)
        _this.$parent.getData()


      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url);
    },

    
    setCommodityData() {
      this.setCommodityShow = false;
      ICloudSores.m_cloudSores.setCommodityData(this.node.m_id, this.commodityData);
      this.$parent.getData();
    },
    setFurnitureData() {
      this.setFurnitureShow = false;
      ICloudSores.m_cloudSores.setFurnitureData(this.node.m_id, this.furnitureData);
      this.$parent.getData();
    },
    setCommodityClose() {
      this.setCommodityShow = false;

    },
    setFurnitureClose() {
      this.setFurnitureShow = false;

    },
    handleSelect(key) {
      console.log(key, "key");
      if (key == 1) {
        this.setFurnitureShow = true;


        this.furnitureData = this.node.m_furnitureData;
      } else if (key == 2) {
        this.setCommodityShow = true;
        this.commodityData = this.node.m_commodityData;
      } else if (key = 3) {
        ICloudSores.m_cloudSores.clearMark(this.node.m_id);
      }




    },
    //右击
    show(item, type) {

      this.menu = true;
      //获取到菜单
      let menu = this.$refs.menu;
      let x = event.clientX;
      let y = event.clientY;

      // menu.$el.style.top = y + "px";
      menu.$el.style.left = x + "px";

      if (y > 630) {
        if (this.$store.state.chosenWarehouse.type != 0) {
          menu.$el.style.top = y - 80 + "px";
        } else {
          menu.$el.style.top = y - 100 + "px";
        }
      } else {
        menu.$el.style.top = y + "px";
      }
      this.node = item;
    },
  },
};
</script>
  
<style lang="less" scoped>
/deep/.el-form-item__label {
  color: #333!important;
}
.box {
  width: 100%;
  height: 100%;
  // background-color: antiquewhite;
  overflow: hidden;

  .BasicSettingTop {
    background-color: #fff;
    border-radius: 8px;
    width: 97%;
    height: 280px;
    margin: 0 auto;
    margin-top: 20px;
    // overflow: hidden;
    border-top: 1px solid #fff;

    .title {
      width: 96%;
      border-left: 3px solid #0560fd;
      margin: 0 auto;
      margin-top: 20px;
      height: 18px;
      padding-left: 10px;
      line-height: 18px;
      font-size: 16px;
      color: #333;
      font-family: PingFang SC;
      font-weight: bold;
    }

    .topContent {
      width: 95%;
      height: 220px;

      margin: 0 auto;
      margin-top: 15px;
      // background-color: #0560fd;
      // display: flex;
      // justify-content: space-between;

      .topContentTop {
        width: 100%;
        height: 100px;

        .name {
          width: 100%;
          // height: 50px;
          // background-color: #0560fd;
          // margin-right: 45px;
          // float: left;
          display: flex;
          margin-bottom: 10px;

          .lable {
            font-size: 15px;
            font-family: PingFang SC;

            color: #333;
            line-height: 30px;
            // margin-right: 15px;
            width: 80px;
          }

          .value {
            width: 350px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
            overflow: auto;
            font-size: 15px;

          }

          .value::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          /* 滚动条的滑块 */
          .value::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px #69a0fe;
            background: #69a0fe;
            border-radius: 3px;
          }
        }

        .type {
          // width: 300px;
          // height: 100px;
          // background-color: #0560fd;
          margin-right: 20px;
          // float: left;
          display: flex;
          margin-bottom: 10px;

          .lable {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: 30px;
            // margin-right: 15px;
            width: 80px;
          }

          .value {
            width: 200px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
            overflow: auto;
            font-size: 15px;

          }

          .value::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          /* 滚动条的滑块 */
          .value::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px #69a0fe;
            background: #69a0fe;
            border-radius: 3px;
          }
        }

        .location {
          // width: 600px;
          // height: 100px;
          // background-color: #0560fd;
          margin-right: 20px;
          // float: left;
          display: flex;
          margin-bottom: 10px;

          .lable {
            width: 80px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: 30px;
            // margin-right: 15px;
          }

          .value {
            // width: 700px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
            overflow: auto;
            font-size: 15px;

          }

          .value::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          /* 滚动条的滑块 */
          .value::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px #69a0fe;
            background: #69a0fe;
            border-radius: 3px;
          }
        }
        .topContentBottom {
        // width: 50%;
        // height: 100px;
        display: flex;
        margin-bottom: 10px;

        .lable {
          width: 80px;
          font-size: 15px;
          font-family: PingFang SC;

          color: #333;
          line-height: 30px;
          // margin-right: 15px;
        }

        .value {
          // width: 1000px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          overflow: auto;
          font-size: 15px;

        }

        .value::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        /* 滚动条的滑块 */
        .value::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 5px #69a0fe;
          background: #69a0fe;
          border-radius: 3px;
        }
      }

      }


    }
  }

  .BasicSettingBottom {
    background-color: #fff;
    border-radius: 8px;
    width: 97%;
    height: 90vh;
    margin: 0 auto;
    margin-top: 20px;
    overflow: hidden;

    .title {
      width: 96%;
      border-left: 3px solid #0560fd;
      margin: 0 auto;
      margin-top: 20px;
      height: 18px;
      padding-left: 10px;
      line-height: 18px;
      font-size: 16px;
      color: #333;
      font-family: PingFang SC;
      font-weight: bold;
    }

    .modelList::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* 滚动条的滑块 */
    .modelList::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 5px #69a0fe;
      background: #69a0fe;
      border-radius: 3px;
    }

    .modelList {
      width: 97%;
      height:80vh;
      margin: 0 auto;
      margin-top: 20px;
      // background-color: #0560fd;
      overflow-y: auto;

      .modelItem {
        width: 15%;
        height: 80px;

        float: left;
        display: flex;
        overflow: hidden;
        margin: 12px;
        background: #ffffff;
        // border: 1px solid #0560FD;
        box-shadow: 0px 0px 7px 0px rgba(72, 146, 244, 0.15);
        border-radius: 8px;
        position: relative;

        img {
          width: 40px;
          height: 40px;
          margin: 20px;
          border-radius: 8px;
        }

        .particulars {
          width: 50%;
          height: 60px;
          margin-top: 10px;

          .modelName {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 30px;
            width: 120px;
            height: 30px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .modelTime {
            height: 25px;

            width: 100%;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 25px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .expression {
          position: absolute;
          right: -2px;
          top: 0;

          /* border-right: 2px solid red;
          border-top: 2px solid red; */
          /* border-left: none; */
          /* border-bottom: none; */
          border: 30px solid transparent;

          border-top: 30px solid #f7f7f7;
          border-right: 30px solid #f7f7f7;

          .expressionImg {
            width: 20px;
            height: 20px;
            position: absolute;
            right: -42px;
            top: -42px;
            border-radius: 6px;

          }
        }
      }
    }
  }
}

.templateList::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* 滚动条的滑块 */
.templateList::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
  border-radius: 3px;
}

.myMenu /deep/ .menu {
  border-radius: 10px;
  padding: 0px 0;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
}

.myMenu /deep/.el-menu-item {
  height: 40px;
  line-height: 40px;
  position: relative;

  white-space: nowrap;
  list-style: none;
  text-align: left;
}

.myMent /deep/ .el-menu-item.is-active {
  color: #000;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;

}

.furnitureParameter{
    width: 100%;
    // float: left;
  }
.upload {
  width: 180px;
  height: 40px;
  background: #0560FD;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;

  margin-bottom: 10px;

}

.templateBtn {
  width: 180px;
  height: 40px;
  background: #0560FD;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
  cursor: pointer;
}

.uploadImg {

  width: 100px;
  height: 100px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }

  .change {
    width: 100%;
    text-align: right;
    color: #0560FD;
    cursor: pointer;
  }

}
.labelColor{
  color: #333;
}
</style>