<template>
  <div class="box">
    <!-- <div class="quexing">
        <img  src="../../assets/OfficialWebsite/quexing.png" alt="">
        <div class="text">
          此版块正在开发ing
        </div>

    </div> -->
    <div class="videoBox">
      <video
      ref="videoPlayer"
      controls
      :src="videoUrl"
      :muted="isMuted"

    ></video>
        <!-- <video src="../../assets/OfficialWebsite/sectorization/case.mp4"></video> -->
    </div>
    <div class="yanyi">
        <div class="btn" @click="goToyida" >
          眼艺官网
        </div>
    </div>
    
  </div>
</template>

<script>
  import videoUrl from "../../assets/OfficialWebsite/sectorization/case.mp4"

export default {

  data(){
    return{
      videoUrl:videoUrl,
      isMuted:true
    }
  },
  mounted(){
    
        // 获取 video 元素的引用
        const videoElement = this.$refs.videoPlayer;

        // 播放视频
        videoElement.play();
  },
  methods:{
    goToyida(){
      window.open('https://www.eyeartsworld.com/index.html', '_blank')


    }
  }
  

}
</script>

<style scoped>
  .box{
    width: 100%;
    /* height: 800px; */
    background-color: #fff;
  position: relative;
  }
  .quexing{
    width: 400px;
    /* height: 300px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  }
  .quexing
  img
  {
    width: 500px; 
    /* height: 400px; */
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */

  }

  .text{
    width: 450px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 88px;
    text-align: center;
  }

</style>

<style lang="less" scoped>
.box{
  overflow: hidden;
.videoBox{
  width: 100%;
  margin:  0 auto;

  height:850px;
  margin-top:60px;
  video{
    width: 100%;
    // height:900px;
  }


}

.yanyi{
  width: 100%;
  height: 100px;
  margin-top: 50px;
  overflow: hidden;
  .btn{
    margin: 0 auto;
    width: 200px;
    height: 40px;
    background: rgba(82, 140, 232, 1);
    border-radius: 20px;
    font-size: 24;

    color: #fff;
    font-weight: 400;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}
}


</style>