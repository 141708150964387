<template>
  <div class="VisualAngle">

    <div class="left">
      <div class="rendererContainer">

        <div class="content">

        </div>

      </div>
      <div class="bottom">
        <div class="title">
          <div class="titleText">
            图片栏

          </div>
        </div>

        <div class="bottomContent">

          <div class="imgItem" v-for="(item) in imgList" :key="item.id"
   
             draggable="true"
            @dragstart="handleDragStart($event, item)"
             @dragover.prevent="handleDragOver($event, item)"
            @dragenter="handleDragEnter($event, item)"
             @dragend="handleDragEnd($event, item)">

            <div class="uploadingBtn">
              <div class="uploadingBtnIcon">
                +
              </div>
              <div class="uploadingBttext">
                添加图片
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>

    <div class="right">
      <div class="rightTitle">
        <div class="icon">
          <img src="../../../assets/Property/icon.png" alt="">
        </div>
        <p>属性</p>
      </div>
      <div class="rightContent">

        <div class="tabTitle">
          <a :title="item.title" v-for="(item, index) in tabImgList" :key="index">
            <div class="ImgList" @click="propertyChange(index)" :class="{ active: currentType == index }">
              <img v-if="currentType==index" :src="item.selecImg" alt="">

              <img v-else :src="item.img" alt="">


              
            </div>
          </a>
        </div>
        <div class="box" :class="isActive ? 'anmDiv' : 'aaa'" v-show="currentType == 0">
          <div class="header">
            <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
            <p>模型属性</p>
          </div>
          <div class="adjust">
            <div class="loction">
              <div class="title">移动</div>
              <div class="x-axis axis">
                <p>X</p>
                <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                  type="number" @change="changeNum0($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>Y</p>
                <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                  type="number" @change="changeNum0($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="z-axis axis">
                <p>Z</p>
                <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999"
                  type="number" @change="changeNum0($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="reset" @click="resetChange()"><img src="../../../assets/Property/reset.png" alt=""> </div>
      
            </div>
            <div class="loction">
              <div class="title">旋转</div>
              <div class="x-axis axis">
                <p>X</p>
                <input v-model="rotateX" type="number" oninput="if(value>360)value=360;if(value<-360)value=-360"
                  @change="changeNum1($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>Y</p>
                <input v-model="rotateY" type="number" oninput="if(value>360)value=360;if(value<-360)value=-360"
                  @change="changeNum1($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="z-axis axis">
                <p>Z</p>
                <input v-model="rotateZ" type="number" oninput="if(value>360)value=360;if(value<-360)value=-360"
                  @change="changeNum1($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="reset" @click="rotateChange()"><img src="../../../assets/Property/reset.png" alt=""></div>

            </div>
            <div class="loction">
              <div class="title">缩放</div>
              <div class="x-axis axis">
                <p>X</p>
                <input :disabled="disabled" v-model="scaleX" :step="0.1"
                  oninput="if(value>999999999)value=999999999;if(value<-999999999)value=-999999999" type="number"
                  @change="changeNum2($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="y-axis axis">
                <p>Y</p>
                <input :disabled="disabled" v-model="scaleY" :step="0.1"
                  oninput="if(value>999999999)value=999999999;if(value<-999999999)value=-999999999" type="number"
                  @change="changeNum2($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="z-axis axis">
                <p>Z</p>
                <input :disabled="disabled" v-model="scaleZ" :step="0.1"
                  oninput="if(value>999999999)value=999999999;if(value<-999999999)value=-999999999" type="number"
                  @change="changeNum2($event)" @focus="Focus" @blur="Blur" />
              </div>
              <div class="reset" @click="scaleChange()"><img src="../../../assets/Property/reset.png" alt=""></div>

            </div>

            <div class="projection shadow">
              <div class="title">标记点</div>
              <div class="ipt_box">
                <input class="input" type="checkbox" @change="projectChange($event)" v-model="castShadow" />
              </div>
              <div class="reset" @click="copyChange2()"><img src="../../../assets/PlugIn/cloudStore/deleteIcon.png" alt=""> </div>
            </div>


            <div class="btn">
              延X轴方向自动适配
            </div>

            <div class="btn">
              延Y轴方向自动适配
            </div>
            <div class="btn">
              延Z轴方向自动适配
            </div>
          </div>
        </div>

        <div class="cameraBox" :class="cameraShow ? 'anmDiv' : 'aaa'" v-show="currentType == 1">
          <div class="header">
            <div @click="cameraShowChange"><i class="el-icon-arrow-down"></i> </div>
            <p>相机属性</p>
          </div>
          <div class="adjust">
            <div class="attribute">
              <div class="attributeName">FOV视角：</div>
              <div class="attributeValue">
                <el-slider @change="fovChange()" v-model="nowSelectData.m_FOV" show-input :min="0" :max="360"
                  :show-tooltip="false"></el-slider>
              </div>
            </div>
          </div>
        </div>

      </div>



    </div>

  </div>
</template>
  
<script>
import icon from "../../../assets/PlugIn/cloudStore/basics.png";
import commodity from "../../../assets/PlugIn/cloudStore/commodity.png";
import { OssManager } from "../../../threejs/network/OssManager";

import { MainObjects } from "../../../threejs/common/MainObjects";
import { ILoader, IAnimation, IScene, IRender, ITween, ISetting, ICameraControls } from "yi_creator_runtime";

import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import checkedName from "../../../assets/Property/checkedName.png";


import CameraAttribute from "../../../assets/PlugIn/cloudStore/CameraAttribute.png";

import character from "../../../assets/PlugIn/cloudStore/character.png";
import selectCharacter from "../../../assets/PlugIn/cloudStore/selectCharacter.png";


import selectCameraAttribute from "../../../assets/PlugIn/cloudStore/selectCameraAttribute.png";


// 


export default {
  data() {
    return {
      isActive: true,
      boxState: true,
      positionX: 0,
      positionY: 0,
      positionZ: 0,
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      scaleX: 0,
      scaleY: 0,
      scaleZ: 0,
      sizeX: 0,
      sizeY: 0,
      sizeZ: 0,
      distanceX: 0,
      distanceY: 0,
      distanceZ: 0,
      minusX: 0,
      minusY: 0,
      minusZ: 0,
      offsetX: 0,
      offsetY: 0,
      offsetZ: 0,
      disabled: true,
      selectState: true,

      tabImgList: [
        { img: character, title: '模型属性',selecImg:selectCharacter },
        { img: CameraAttribute, title: '属性设置',selecImg:selectCameraAttribute },

      ],

      currentType: 0,
      cameraShow: true,
      isCamera: true,
      castShadow: "",
      nowSelectData: {
        m_FOV: 0
      },

      imgList: [
        {
          id: "1",
          url: "",
          imgShow: false,
          color: "#3883a0"
        },
        {
          id: "2",
          url: "",
          imgShow: false,
          color: "#4883a0"
        },
        {
          id: "3",
          url: "",
          imgShow: false,
          color: "#5883a0"
        },
        {
          id: "4",
          url: "",
          imgShow: false,
          color: "#5883a0"
        },
        {
          id: "5",
          url: "",
          imgShow: false,
          color: "#7883a0"
        },
        {
          id: "6",
          url: "",
          imgShow: false,
          color: "#7883a0"
        },
        {
          id: "7",
          url: "",
          imgShow: false,
          color: "#8883a0"
        },
        {
          id: "8",
          url: "",
          imgShow: false,
          color: "#9883a0"
        },
      ],
      ending: null,
      dragging: null,
    };
  },
  props: [],
  mounted() {


  },

  methods: {

    handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd(e, item) {

      if (this.ending.id === this.dragging.id) {
        return;
      }
      let newItems = [...this.imgList];
      const src = newItems.indexOf(this.dragging);
      const dst = newItems.indexOf(this.ending);
      newItems.splice(src, 1, ...newItems.splice(dst, 1, newItems[src]));

      this.imgList = newItems;
      this.$nextTick(() => {
        this.dragging = null;
        this.ending = null;
      });
    },
    handleDragOver(e) {
      // 首先把div变成可以放置的元素，即重写dragenter/dragover
      // 在dragenter中针对放置目标来设置
      e.dataTransfer.dropEffect = "move";
    },
    handleDragEnter(e, item) {
      // 为需要移动的元素设置dragstart事件
      e.dataTransfer.effectAllowed = "move";
      this.ending = item;
    },



    fovChange() {

    },
    propertyChange(index) {
      this.currentType = index;
    },
    shrinkChange() {
      if (this.isActive) {
        this.isActive = false
      } else {
        this.isActive = true;
      }
    },
    cameraShowChange() {
      if (this.cameraShow) {
        this.cameraShow = false
      } else {
        this.cameraShow = true;
      }
    },

    Focus() {
      ISetting.isInput(true);
    },
    Blur() {
      ISetting.isInput(false);
    },
    onCopy() {
      this.$message({
        message: "复制成功"
      })
    },


  }
};
</script>
  
<style lang="less" scoped>
.VisualAngle {
  width: 100%;
  height: 100%;
  // background-color: antiquewhite;
  // overflow: hidden;
  position: relative;



  .right {
    background-color: #fff;
    width: 25%;
    height: 100%;
    float: right;

    .rightTitle {
      width: 90%;
      height: 25px;
      color: #000;
      font-weight: bold;
      font-size: 10px;
      margin: auto;
      margin-top: 10px;
      display: flex;
      align-items: center;

      .icon {
        width: 15px;
        height: 14px;
        margin-right: 10px;
        margin-left: 8px;

        img {
          width: 15px;
          height: 14px;
        }
      }
    }


    .rightContent {
      position: relative;
      height: 100%;

      .tabTitle {
        width: 40px;
        height: 95%;
        position: absolute;
        left: 20px;
        // top: 30px;
        background: rgba(247, 247, 247, 1);
        z-index: 999;

        .ImgList {
          width: 38px;
          height: 45px;
          margin: 2px auto;
          align-items: center;
          display: flex;
          justify-content: center;
          cursor: pointer;
          margin-left: 2px;

          img {
            width: 15px;
            height: 15px;
          }
        }

        .ImgList:hover {
          background: #cddfff;
        }
      }


      .attribute {
        width: 90%;
        height: 60px;

        margin: 0 auto;
        margin-top: 20px;

        display: flex;
        justify-content: space-between;

        .attributeName {
          width: 100px;

          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 60px;
          margin-right: 10px;
        }

        .attributeValue {
          width: 310px;
          margin-top: 12px;
        }

        .initialCamera {
          width: 310px;
          margin-top: 23px;
        }
      }

      .btn {
        width: 200px;
        height: 30px;
        line-height: 30px;
        background: #5493FF;
        border-radius: 18px;
        font-size: 8px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin: 10px auto;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .left {
    float: left;
    width: 75%;
    height: 100%;
    position: relative;

    .rendererContainer {
      background-color: aliceblue;
      width: 100%;
      height: 57%;
      float: left;
      position: relative;

      .content {
        width: 99%;
        height: 95%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(70, 70, 70, 1);
        border: 4px solid #D6D6D6;

      }
    }

    .bottom {
      width: 117%;
      height: 42%;
      // float: left;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 999;
      background-color: #fff;
      overflow: hidden;

      .title {
        width: 100%;
        height: 30px;
        background-color: #f7f7f7;
        position: relative;

        .titleText {
          height: 80%;
          width: 98%;
          border-left: 4px solid #0560FD;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-left: 10px;
          font-weight: 400;
          color: #000000;

        }
      }

      .bottomContent {
        background-color: #fff;
        width: 95%;
        height: 90%;
        margin: 0 auto;
        margin-top: 2px;
        display: flex;
        flex-wrap: wrap;
   

        .imgItem {
          width: 18%;
          height: 150px;
          margin: 10px;
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
          margin-bottom: 0px;
          margin-top: 10px;
          position: relative;
          transition: all linear 0.2s;


          .uploadingBtn {
            width: 100px;
            height: 100px;
            // background-color: #000;
            position: absolute;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .uploadingBtnIcon {
              color: #fff;
              background-color: rgba(84, 147, 255, 1);
              width: 70px;
              height: 70px;
              border-radius: 50%;
              font-size: 50PX;
              text-align: center;
              line-height: 70px;
              margin: 0 auto;
              cursor: pointer;
            }

            .uploadingBttext {
              width: 100%;
              font-weight: 400;
              color: #000000;
              line-height: 40px;
              text-align: center;
            }

          }
        }
      }

    }
  }


}
</style>


<style scoped>
.box {
  width: 80%;
  height: 380px;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 70px;
  font-size: 10px;
  background: #ffff;
  overflow: hidden;
  margin-bottom: 50px;
}

.cameraBox {
  width: 80%;
  height: 180px;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 70px;
  font-size: 10px;
  background: #ffff;
  overflow: hidden;
  margin-bottom: 50px;
}


.Name {
  width: 80%;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 8px;
  margin-left: 70px;
  display: flex;
  align-items: center;
  background: #fff;
  overflow: hidden;
}

.Name .title {
  width: 70px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}

.ipt_box {
  width: 81%;
  height: 28px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.Name input {
  width: 95%;
  height: 25px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0);
  color: #808080;
}

.scene {
  width: 80%;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 70px;
  display: flex;
  align-items: center;
  background: #fff;
  overflow: hidden;
}

.scene .title {
  width: 70px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}

.scene .id {
  font-size: 10px;
  color: #808080;
  margin-left: 9px;
}

.scene #copy {
  width: 50px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  background: #f8f8f8;
  /* box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1); */
  border-right: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scene .select {
  width: 100%;
  height: 28px;
  outline: none;
  color: #808080;
  border: none;
  border-radius: 4px;
  font-size: 10px;
}

.select /deep/ .el-input {
  width: 100% !important;
  height: 28px !important;
  outline: none !important;
  color: #808080;
  font-size: 10px;
}

.select /deep/ .el-input__inner {
  width: 100% !important;
  height: 28px !important;
  outline: none !important;
  color: #808080;
}

.select /deep/ .el-select__caret {
  height: 100% !important;
  line-height: 28px !important;
}

#copy img {
  width: 15px;
  height: 15px;
}

.ipt_box1 {
  width: 81%;
  height: 28px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #808080;
}

.type {
  width: 88%;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 70px;
  display: flex;
  align-items: center;
  background: #fff;
  overflow: hidden;
}

.type .title {
  width: 60px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.type .title span {
  margin-left: 3px;
}

.type .img {
  width: 25px;
  height: 25px;
  /* margin-left: 10px; */
  cursor: pointer;
}

.type .img1 {
  width: 14px;
  height: 14px;
  padding-left: 25px;
  cursor: pointer;
}

.iptBox {
  width: 74%;
  height: 28px;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.type input {
  width: 85%;
  height: 25px;
  border: none;
  color: #808080;
  outline: none;
  font-size: 10px;
  text-indent: 7px;
}

.anmDiv {
  transition: 1S;

}

.aaa {
  height: 40px;
  transition: 1S;
}

.header {
  display: flex;
  width: 90%;
  height: 40px;
  margin: auto;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 12px;
}

.header p {
  margin-left: 10px;
  overflow: hidden;
}

.header i {
  font-weight: bold;
  cursor: pointer;
}

.adjust {
  width: 100%;
  /* height: calc(100% - 30px); */
  background: #fff;
  margin: 5px auto;
  overflow: hidden;
}

.loction {
  width: 95%;
  margin: 20px auto;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.loction .title {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: bolder;
  overflow: hidden;
}

.axis {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #F7F7F7;
  border-radius: 5px;

}

.axis p {
  width: 22px;
  height: 18px;
  line-height: 20px;
  text-align: center;
  color: #929292;
  background-color: #fff;
  margin-left: 2px;
  border-radius: 4px;
  float: left;
  color: #000000;
  font-size: 12px;
}

.axis input {
  width: 90%;
  height: 18px;
  text-align: center;
  outline: none;
  border: none;
  background-color: #F7F7F7;
  border-radius: 4px;
  font-weight: bold;
  font-size: 8px;
}

.x-axis p {
  background-color: #F20004;
}

.y-axis p {
  background-color: #43BD00;
}

.z-axis p {
  background-color: #5180E9;
}

.reset {
  width: 30px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
}

.reset img {
  width: 14px;
  height: 12px;
}

.reset1 {
  width: 30px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  /* box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1); */
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.size::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.projection {
  display: flex;
  width: 100%;
  height: 20px;
  align-items: center;
  margin: 25px auto;
  font-size: 12px;
}

.projection .title {
  width: 70px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: bolder;
  overflow: hidden;
}

.loop .title {
  width: 100px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: bolder;
  overflow: hidden;
}

.projection .select {
  width: 245px;
  height: 30px;
  outline: none;
  color: #ccc;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ipt_box {
  width: 65px;
  height: 25px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  width: 16px;
  height: 16px;
  outline: none;
  background-color: rgba(125, 128, 133, 0.4);
  border: solid 1px #dddddd;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 0.8rem;
  padding: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: default;
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-transition: background-color ease 0.1s;
  transition: background-color ease 0.1s;
  outline: none;
  color: #808080;
}

.input:checked::after {
  content: '';
  top: 3px;
  left: 3px;
  position: absolute;
  background: transparent;
  border: #1673ff solid 2px;
  border-top: none;
  border-right: none;
  height: 4px;
  width: 6px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.input:checked {
  background: #1673ff
}
</style>